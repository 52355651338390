import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventRegistrant } from "../../../pages/Events/interfaces/event-registrant_interface";
import { EnableTicketWaitlistStatus, TicketType } from "../../../pages/Events/enum";
import { Avatar } from "@mui/material";
import MuiChip from "../../../common/FormComponents/MuiChip";
import { EventRegistrantNotify, EventRegistrantStatus } from "../../../pages/Events/enum/event-registrant.enum";
import { CONTENT } from "../../../scripts/i18n";
import { Form, Formik } from "formik";
import { FormControlComponent, FormLabelComponent, SelectComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { updateEventRegistrant } from "../../../scripts/apis/eventRegistrants";
import toast from "react-hot-toast";
import DeletePopup from "../../../common/DeletePopup";

import './styles.scss';

interface RegistrantUpdateComponentProps {
    registrantData: EventRegistrant;
    onClose: () => void;
    setRegistrants: React.Dispatch<React.SetStateAction<EventRegistrant[]>>;
    setRefreshCounts: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegistrantUpdateComponent = (props: RegistrantUpdateComponentProps): React.JSX.Element => { 

    const { registrantData, onClose, setRegistrants, setRefreshCounts } = props;
    const name = registrantData?.firstName + ' ' + (registrantData?.lastName || '');

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

    const confirmationText = `${registrantData?.firstName} has registered using a paid ticket. Changing the status to Confirmed will override the default payment status.\nDo you want to proceed?`;
    const formatted = confirmationText.split('\n').map((item, i): React.JSX.Element => {
        return <React.Fragment key={i}>{item}<br /></React.Fragment>;
    });
    
    const statusOptions = () => {
        const baseOptions = [
            {
                name: 'In Review',
                id: 1,
                optionChipColor: 'grey'
            },
            {
                name: 'Rejected',
                id: 3,
                optionChipColor: 'red'
            },
            {
                name: 'Confirmed',
                id: 6,
                optionChipColor: 'blue'
            }
        ];

        if (registrantData?.ticket && registrantData?.ticket?.type === TicketType.PAID) {
            baseOptions.splice(1, 0, {
                name: 'Approved',
                id: 2,
                optionChipColor: 'green'
            });
        }

        if (registrantData?.ticket && registrantData?.ticket?.enableWaitlistStatus === EnableTicketWaitlistStatus.ENABLE)
        {
            baseOptions.splice(3, 0, {
                name: 'Waitlist',
                id: 4,
                optionChipColor: 'yellow'
            });
        }

        return baseOptions;
    };

    const chipColor = () => {
        switch (registrantData.status) { 
            case EventRegistrantStatus.INVITE:
                return 'grey';
            case EventRegistrantStatus.APPROVED:
                return 'green';
            case EventRegistrantStatus.REJECTED:
                return 'red';
            case EventRegistrantStatus.DELETED:
                return 'red';
            case EventRegistrantStatus.CONFIRMED:
                return 'blue';
            case EventRegistrantStatus.WAITLIST:
                return 'yellow';
            default:
                return 'grey';
        }
    };

    const handleUpdateStatus = async (values) => { 
        setShowSpinner(true);
        try 
        {
            let data = {
                status: values.status,
                notifyGuest: values.notify,
            };

            if (values.notes !== '') {
                data.notes = values.notes;
            }

            const statusUpdated = await updateEventRegistrant(registrantData?.eventId, registrantData?.id, data);
            if (statusUpdated) 
            {
                toast.success('Registrant status updated successfully');

                if (window.location.search?.includes('status'))
                {
                    setRegistrants((prevRegistrants) => { 
                        const updatedRegistrants = prevRegistrants.filter((registrant) => registrant.id != registrantData.id);
                        return updatedRegistrants;
                    });
                    setRefreshCounts(true);
                }
                else
                {
                    setRefreshCounts(true);
                    setRegistrants((prevRegistrants) => { 
                        const updatedRegistrants = prevRegistrants.map((registrant) => { 
                            if (registrant.id == registrantData.id) 
                            {
                                return {
                                    ...registrant,
                                    status: values.status,
                                };
                            }
                            return registrant;
                        });
                        return updatedRegistrants;
                    });
                }
                onClose();
            } 
        }
        catch (error) 
        {
            console.log('Error updating registrant status', error);
            toast.error((error as Error)?.message || 'Error updating registrant status');
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    return (
        <div id="registrantUpdateComponent">
            <div className="registrant-update-component">

                <div className="registrants-details-container">
                    <div className="header-container">
                        {registrantData?.clearbitData && registrantData?.clearbitData?.companyLogo ? <Avatar src={registrantData?.clearbitData.companyLogo} className="registrant-logo" /> : <Avatar className="registrant-logo">{name.charAt(0).toUpperCase()}</Avatar>}
                        <FontAwesomeIcon icon={['fal', 'xmark']} onClick={onClose} className="close-btn" />
                    </div>

                    <div className="registrant-basic-info">
                        <div>
                            <h3 className="registrant-name">{name}</h3>
                            <h3 className="registrant-email">{registrantData?.email}</h3>
                        </div>
                        <MuiChip label={CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[registrantData?.status]} chipColor={chipColor()} />
                    </div>
                </div>

                <Formik
                    initialValues={{
                        status: registrantData?.status,
                        notes: '',
                        notify: EventRegistrantNotify.NO,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                        handleUpdateStatus(values);
                    }}
                >
                    {({ handleSubmit, handleChange, values, setFieldValue }): React.ReactElement => {
                        return (
                            <Form onSubmit={handleSubmit} noValidate>

                                {/* status */}
                                <div className="registrant-status-dropdown">
                                    <FormLabelComponent label="Change Status to:" />
                                    <SelectComponent 
                                        options={statusOptions().filter((option) => option.id !== registrantData?.status)}
                                        value={values.status != registrantData?.status ? values.status : ''}
                                        onChange={(event) => {
                                            if (event.target.value === EventRegistrantStatus.CONFIRMED && registrantData?.ticket?.type === TicketType.PAID) 
                                            { 
                                                setShowConfirmationPopup(true);
                                            }
                                            else
                                            {
                                                setFieldValue('status', event.target.value);
                                            }
                                        }}
                                        // showNone="Choose new status"
                                        defaultPlaceholder="Choose new status"
                                        optionLabels={CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS}
                                        showOptionChip
                                    />
                                </div>

                                {/* notify guest */}
                                <div className="notify-guest-checkbox">
                                    <input
                                        type="checkbox"
                                        name="notify"
                                        checked={values.notify === EventRegistrantNotify.YES || values.status === EventRegistrantStatus.APPROVED}
                                        readOnly={values.status === EventRegistrantStatus.APPROVED}
                                        onChange={(event) => {
                                            setFieldValue('notify', event.target.checked ? EventRegistrantNotify.YES : EventRegistrantNotify.NO);
                                        }}
                                    />
                                    <FormLabelComponent label="Notify Guest" noBottomMargin />
                                </div>

                                {/* Notes */}
                                {values?.notify === EventRegistrantNotify.YES && <div className="notes-textarea">
                                    <FormLabelComponent label="Note" />
                                    <FormControlComponent 
                                        as="textarea"
                                        name="notes"
                                        value={values.notes}
                                        onChange={handleChange}
                                        placeholder="Write a brief note about the guest..."
                                        type="textarea"
                                        rows={4}
                                    />
                                    <p className="note-helptext">{'Any message you specified in the registration email will always be included.'}</p>
                                </div>}

                                {/* submit button */}
                                <div className="submission-container">
                                    <CustomButton 
                                        name="Cancel"
                                        btnType="secondary"
                                        onClick={onClose}
                                    />
                                    <CustomButton 
                                        name="Update Status"
                                        btnType="primary"
                                        type="submit"
                                        loading={showSpinner}
                                        disabled={showSpinner || registrantData?.status == values.status}
                                    />
                                </div>

                                {
                                    showConfirmationPopup && (
                                        <DeletePopup 
                                            show={showConfirmationPopup}
                                            cancelClick={() => setShowConfirmationPopup(false)}
                                            acceptClick={() => {
                                                setFieldValue('status', EventRegistrantStatus.CONFIRMED);
                                                setShowConfirmationPopup(false);
                                            }}
                                            modalTitle="Confirmation"
                                            modalContent={formatted}
                                            acceptBtn="Proceed"
                                            rejectBtn="Cancel"
                                        />
                                    )
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default RegistrantUpdateComponent;