import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import { useDispatch } from 'react-redux';
import { decompressImage } from '../../common/ImageProcessing';
import { UserContext } from '../UserContext';


export interface general {
    general: {
        value: IPersonalPageReduxValues;
    }
}

export interface IPersonalPageReduxValues {
    firstName: string,
    lastName: string,
    email: string,
    image: string,
    role: string | number,
    // additionalInfo: any,
}

export const PersonalPageContext = createContext(null);

const PersonalPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{
    const dispatch = useDispatch();

    const {
        userDetails
    } = useContext(UserContext);

    const generalData = useSelector((state: general): IPersonalPageReduxValues => 
    {
        return state.general.value;
    });

    // const userObject = {
    //     firstName: '',
    //     lastName: '',
    //     additionalInfo: {
    //         image: null
    //     },
    //     profileUpdated: false,
    //     email: '',
    //     role: '',
    // };

    // const orgObject = {
    //     name: '',
    //     link: '',
    //     additionalInfo: {
    //         image: null
    //     },
    //     imageUpdated: false
    // };

    const [spinner, setSpinner] = useState(false);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const [profile, setProfile] = useState<string | null>('');
    const [isEditing, setIsEditing] = useState(true);

    eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
        heading: CONTENT.SETTINGS_PAGE.HEADING,
        closedChartsButton: false,
    });

    // const fetchProfileData = async (): Promise<void> => 
    // {

    //     try 
    //     {
    //         const userData = await userMe();
    //         // const org = await orgMe();

    //         if (userData) 
    //         {
    //             setUserInfo(userData);
    //             // setUserRole(userData.role);
    //             setUserObj(userObj);
    //             dispatch(updateGeneralInfo({
    //                 firstName: userData?.firstName,
    //                 lastName: userData?.lastName,
    //                 image: userData?.additionalInfo.image ? userData.additionalInfo.image : null
    //                 // additionalInfo: userData.additionalInfo,
    //             }));
    //             const userDetails = {
    //             };
    //             userDetails['firstName'] = userData.firstName;
    //             userDetails['lastName'] = userData.lastName;
    //             userDetails['additionalInfo'] = userData.additionalInfo && userData.additionalInfo.image ? userData.additionalInfo : new Object();
    //             userDetails['email'] = userData.email;
    //             setUser(userDetails);
    //             if (userData.additionalInfo && userData.additionalInfo.image) 
    //             {
    //                 const x = await decompressImage(userData.additionalInfo.image, 1500, 1500);
    //                 setProfile(x);
    //             }
    //             else 
    //             {
    //                 setProfile(null);
    //             }
    //             setProfileImage(userData.additionalInfo && userData.additionalInfo.image ? userData.additionalInfo.image : null);
    //         }
    //         // if (org) {
    //         //     setOrganisation(org);
    //         //     dispatch(updateOrgInfo({
    //         //         name: org.name,
    //         //         link: org.link,
    //         //         additionalInfo: org.additionalInfo,
    //         //     }));
    //         // }
    //     }
    //     catch (error) 
    //     {
    //         console.log(error);
    //     }

    //     setRefresh(false);
    // };

    const fetchData = async () => 
    {
        try 
        {
            if (userDetails && userDetails?.additionalInfo && userDetails.additionalInfo.image) 
            {
                const x = await decompressImage(userDetails.additionalInfo.image, 1500, 1500);
                setProfile(x);
            }
            else 
            {
                setProfile(null);
            }
            setProfileImage(userDetails && userDetails.additionalInfo && userDetails.additionalInfo.image ? userDetails.additionalInfo.image : null);
        } 
        catch (error) 
        {
            
        }
    }
    
    useEffect((): void => 
    {
        // fetchProfileData();
        fetchData();
    }, [userDetails]);

    // useEffect((): void => 
    // {
    //     if (refresh) 
    //     {
            // fetchProfileData();
    //     }
    // }, [refresh]);

    return (
        <PersonalPageContext.Provider 
            value={{
                setProfileImage, 
                setProfile, 
                generalData, 
                setSpinner, 
                profileImage, 
                profile, 
                spinner, 
                isEditing, 
                setIsEditing 
            }}
        >
            {children}
        </PersonalPageContext.Provider>
    );
};

export default PersonalPageProvider;