import React, { useContext } from "react";
import CreateEventTargetList from "./Components/CreateEventTargetList";
import { EventTargetListContext } from "../../../../contexts/EventTargetList/EventTargetListContext";
import TargetListByPastEventsPage from "./Pages/TargetListByPastEventsPage";
import { EventTargetListSourceType } from "../../enum/event-target-list.enum";
import TargetListByAudiencePage from "./Pages/TargetListByAudiencePage";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { useSelector } from "react-redux";
import { IEventTargetListDispatch, IEventTargetListReduxData } from "../../interfaces/event-target-list-interface";

const CreateEventTargetListPage : React.FC = (): JSX.Element =>
{
    const {eventId} = useContext(EventTargetListContext);
    const eventTargetListReduxData = useSelector(
        (state: IEventTargetListDispatch): IEventTargetListReduxData => {
            return state.eventTargetList.value;
        }
    );

    const { showCreate, setShowCreate } = useContext(EventTargetListContext);

    const navigate = useNavigate();
    const handleDrawerClose = (event: any): void =>
    {
        navigate(`/events/${eventId}/target-list`);
    }

    const source = eventTargetListReduxData?.source[0];
    const listName = eventTargetListReduxData?.name;

    const renderSourceJsx = (sourceType: EventTargetListSourceType | undefined) => {
        switch (sourceType) 
        {
            case EventTargetListSourceType.PAST_EVENT:
                return <TargetListByPastEventsPage />;
            case EventTargetListSourceType.APOLLO:
                return <div> Apollo Component</div>;
            case EventTargetListSourceType.HUBSPOT:
                return <div> HubSpot Component</div>;
            case EventTargetListSourceType.AUDIENCE:
                return <TargetListByAudiencePage />;
            default:
                return <CreateEventTargetList />;
        }
    };

    return (
        <>
            {showCreate ? (
                <CreateEventTargetList />
            ) : (
                <div id="createTargetListPageWrapper">
                    <div className="header-container">
                        <CustomButton
                            name={"Exit"}
                            btnType={"secondary"}
                            onClick={handleDrawerClose}
                        />
                        {/* In fututre if we add save button use the below button */}
                        {/* <CustomButton name={ 'Save' } btnType={ 'secondary' } style={ { border: 'unset' } } onClick={ handleDrawerClose } />  */}
                        <div className={`header-text ${listName ? "header-text-with-title" : ""}`}>
                            {listName ? listName : "List Name"}{" "}
                        </div>
                    </div>
                    {renderSourceJsx(source)}
                </div>
            )}
        </>
    );
};

export default CreateEventTargetListPage;
