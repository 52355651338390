import { Typography } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';

import './styles.scss';

const PopupDialog: React.FC<{ show: boolean; modalTitle: string; modalContent: string | React.JSX.Element; cancelClick: () => void; }> = (props): React.JSX.Element  => 
{
    const { modalTitle, modalContent, show, cancelClick } = props;

    return (
        <div id="popupDialog">
            <Modal style={{ padding: 'unset' }} show={show} onHide={cancelClick} centered>
                <Modal.Header>
                    <Modal.Title>
                        <Typography className="title">{modalTitle}</Typography>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {typeof(modalContent) === 'string' ? <Typography className="content">{modalContent}</Typography> : modalContent}
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default PopupDialog;
