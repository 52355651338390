import { useState } from "react";
import { SelectComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { EventPhotoSharingFolder } from "../../../pages/Events/interfaces/event-photo-sharing_interface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './styles.scss';

interface IPhotoSharingFolderDropdown {
    folders: EventPhotoSharingFolder[];
    handleFolderChange: (value: string | number) => void;
    initialSelectedFolder?: string | number;
    handleCreateFolder?: () => void;
}

const FolderDropdown: React.FC<IPhotoSharingFolderDropdown> = (props): React.JSX.Element =>
{

    const { folders, handleFolderChange, initialSelectedFolder, handleCreateFolder } = props;

    const [selectedFolder, setSelectedFolder] = useState<string | number>(initialSelectedFolder || '');

    const folderOptionLabels = folders.reduce((acc: { [key: string]: string }, folder) => { 
        acc[folder?.id] = folder.name;
        return acc;
    }, {});

    return (
        <div id="photoSharingFolderDropdown">
            <div className={folders?.length < 5 ? "photo-sharing-folder-dropdown-addFolder" : "photo-sharing-folder-dropdown"}>
                <SelectComponent 
                    value={selectedFolder}
                    options={folders}
                    onChange={(e) => 
                    {
                        handleFolderChange(e.target.value)
                        setSelectedFolder(e.target.value)
                    }}
                    customValueRender={(value: string | number) => { 
                        return (
                            <div className="folder-dropdown-value-container">
                                <FontAwesomeIcon icon={['fal', 'folder-open']} />
                                <h3 className="selected-value">{folderOptionLabels[value]}</h3>
                            </div>
                        )
                    }}
                />
                {
                    folders?.length < 5 &&
                    <div className="add-folder-btn"><FontAwesomeIcon icon={['fal', 'plus']} onClick={handleCreateFolder} /></div>
                }
            </div>
        </div>
    )
};

export default FolderDropdown;