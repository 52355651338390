import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteICP, getAllICP, icpCount, updateICP } from '../../scripts/apis/icp';
import { useTablePagination } from '../TablePaginationContext';
import { TECH, TECH_CATEGORIES } from '../../pages/Settings/ICP/icp-dataset';
import _ from 'lodash';
import { ICP } from '../../pages/Settings/interface/icp_interface';
import toast from 'react-hot-toast';

interface ICPPageContextInterface 
{
    rows: ICP[];
    isEmpty: boolean;
    icpDataCount: number;
    icp: ICP[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setRows: React.Dispatch<React.SetStateAction<ICP[]>>;
    deleteICPFromTable: (icp: ICP) => void;
    refresh: boolean;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    editIcpFromTable: (icpData: any) => void;
    selectedIcp: ICP | null;
    showDeletePopup: boolean;
    setSelectedIcp: React.Dispatch<React.SetStateAction<ICP | null>>;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const ICPPageContext = createContext<ICPPageContextInterface>({
    rows: [],
    isEmpty: false,
    icpDataCount: 0,
    icp: [],
    setRefresh: () => {},
    setRows: () => {},
    deleteICPFromTable: () => {},
    refresh: false,
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    editIcpFromTable: () => {},
    selectedIcp: null,
    showDeletePopup: false,
    setSelectedIcp: () => {},
    setShowDeletePopup: () => {},
    setCurrentUrl: () => {}
});

const ICPPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }): React.JSX.Element => 
{
    const [rows, setRows] = useState<ICP[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [icpDataCount, setICPDataCount] = useState<number>(0);
    const [icp, setICP] = useState<ICP[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedIcp, setSelectedIcp] = useState<ICP | null>(null);
    const [currentUrl, setCurrentUrl] = useState<string>('');

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
        }
    }, [currentPage, pageSize, currentUrl]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const count = await icpCount();
            setIsEmpty(count == 0);
            setShowSpinner(true);
            if (count) 
            {
                try 
                {
                    const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                    const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                    const icpData = await getAllICP(pageSize, currentPage - 1, encodedParams);
                    if (icpData) 
                    {
                        setRows([...icpData]);
                        setICPDataCount(count);
                        setICP([...icpData]);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchPaginationData = async (): Promise<void> => 
    {
        if (icpDataCount) 
        {
            try 
            {
                const params: [string, any][] = [];
                const query = new URLSearchParams(currentUrl.split('?')[1]);
                query?.forEach((value, key) => {
                    params.push([key, value]);
                });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const icpData = await getAllICP(pageSize, currentPage - 1, encodedParams);
                setShowSpinner(true);
                if (icpData) 
                {
                    setRows([...icpData]);
                    setICP([...icpData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }

            }
            catch (error) 
            {
                console.log(error);
                setShowSpinner(true);
            }
        }
    };

    const deleteICPFromTable = async (icp: ICP): Promise<void> => 
    {
        try 
        {
            const icpDeleted = await deleteICP(icp.id);
            if (icpDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedIcp(null);
                toast.success('ICP Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }           
    };

    const editIcpFromTable = async (icpData): Promise<void> => 
    {
        const icpIndustry = typeof icpData?.subIndustry !== 'string' ? icpData.subIndustry ? icpData.subIndustry.toString() : [] : icpData.subIndustry;
        const icpEmployeeRange = typeof icpData?.employeesRange !== 'string' ? icpData.employeesRange ? icpData.employeesRange.toString() : [] : icpData.employeesRange;
        const icpArr = typeof icpData?.estimatedAnnualRevenue !== 'string' ? icpData.estimatedAnnualRevenue ? icpData.estimatedAnnualRevenue.toString() : [] : icpData.estimatedAnnualRevenue;
        const icpCountry = typeof icpData?.country !== 'string' ? icpData.country.toString() : icpData.country;
        const techCategory = typeof icpData?.techCategories !== 'string' ? icpData?.techCategories?.toString() : icpData?.techCategories;
        const tech = typeof icpData?.tech !== 'string' ? icpData?.tech?.toString() : icpData?.tech;


        const icpDetails = {
            name: icpData?.name,
            description: icpData.description ? icpData.description : null,
            subIndustry: icpData.subIndustry ? icpIndustry : null,
            employeesRange: icpData.employeesRange ? icpEmployeeRange : null,
            estimatedAnnualRevenue: icpData.estimatedAnnualRevenue ? icpArr : null,
            country: icpData.country ? icpCountry : null,
            techCategories: icpData?.techCategories ? techCategory : null,
            tech: icpData?.tech ? techCategory ? tech : null : null,
        };

        if (checkifTechisValid(icpData?.tech?.toString(), icpData?.techCategories)) 
        {
            try 
            {
                const icpUpdated = await updateICP(icpData.id, icpDetails);
                if (icpUpdated) 
                {
                    setRefresh(true);

                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error)?.message);
            }
        }
        else 
        {
            toast.error('Select Valid Tech for the Tech Categories selected');
            return;
        }

    };

    const checkifTechisValid = (tech, tech_categories): boolean => 
    {
        const matchingTech = [];
        if (tech_categories) 
        {
            const techCategoriesPositions = [];
            if (typeof tech_categories === 'string') 
            {
                const techCategories = tech_categories.split(',');
                techCategories.forEach((element): void => 
                {
                    const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                    techCategoriesPositions.push(techCategoriesPosition);
                });

                if (techCategoriesPositions.length > 0) 
                {
                    techCategoriesPositions.forEach((item): void => 
                    {
                        if (TECH[item].length > 0) 
                        {
                            TECH[item].map((tech): void => 
                            {
                                matchingTech.push(tech);
                            });
                        }

                    });
                }
            }
            else 
            {
                if(tech_categories.length > 0)
                {
                    tech_categories.forEach((element): void => 
                    {
                        const techCategoriesPosition = TECH_CATEGORIES.indexOf(element);
                        techCategoriesPositions.push(techCategoriesPosition);
                    });
    
                    if (techCategoriesPositions.length > 0) 
                    {
                        techCategoriesPositions.forEach((item): void => 
                        {
                            if (TECH[item].length > 0) 
                            {
                                TECH[item].map((tech): void => 
                                {
                                    matchingTech.push(tech);
                                });
                            }
    
                        });
                    }
                }
                else
                {
                    return true;
                }
            }

        }
        if (tech) 
        {
            const x = tech.split(',');
            if (x.length > 0) 
            {
                return x.every((item): boolean => 
                {
                    return matchingTech.includes(item);
                });
            }
            else 
            {
                return true;
            }
        }
        else 
        {
            return true;
        }
    };

    return (
        <ICPPageContext.Provider 
            value={{
                rows, 
                isEmpty, 
                icpDataCount, 
                icp, 
                setRefresh, 
                setRows, 
                deleteICPFromTable, 
                refresh, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                editIcpFromTable,
                selectedIcp,
                setSelectedIcp,
                setShowDeletePopup,
                showDeletePopup,
                setCurrentUrl
            }}
        >
            {children}
        </ICPPageContext.Provider>
    );
};

export default ICPPageProvider;