import React, { useContext, useEffect, useState } from "react";
import { generateAudienceColumnsForTanstackTable } from "../../../Audience/audienceColumn.helper";
import { Audience } from "../../../Audience/interfaces";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import {
    getEventTargetAudiencesByListId,
    getEventTargetListById,
    updateEventTargetList,
} from "../../../../scripts/apis/eventTargetList";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import { EventTargetList } from "../../interfaces/event-target-list-interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";

const EventTargetListAudiencePage = (props) => 
  {
    const eventId = props.eventId;
    const listId = props.listId;

    const {
        userDetails
    } = useContext(UserContext);

    const navigate = useNavigate();
    const [eventRegistrant, setEventRegistrant] = useState<Audience[]>([]);

    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];

    const [allColumns, setAllColumns] = useState(audienceColumns);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [audienceIds, setAudienceIds] = useState<string[]>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [targetListAudienceCount, setTargetListAudienceCount] = useState(1);
    const [targetList, setTargetList] = useState<EventTargetList>();
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const filterColumns = [
        {
            header: 'First Name',
            id: 'firstName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
      ];

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const handleDeleteClick = async () => {
        const updatedEventRegistrant = eventRegistrant.filter(
            (registrant) => !selectedRowIds.includes(registrant.id)
        );
        const updatedAudienceIds = audienceIds.filter((id) => !selectedRowIds.includes(id));
        setEventRegistrant(updatedEventRegistrant);
        setAudienceIds(updatedAudienceIds);
  
        if (targetList?.audienceIds) {
            targetList.audienceIds = targetList.audienceIds.filter(
                (id) => !selectedRowIds.includes(id)
            );
        }
    
        if (targetList?.targetListAudienceIds) {
            targetList.targetListAudienceIds = targetList.targetListAudienceIds.filter(
                (id) => !selectedRowIds.includes(id)
            );
        }
        if(targetList)
        {
            const updateTargetList = {
                audienceIds: targetList.audienceIds,
                targetListAudienceIds: targetList.targetListAudienceIds,
            }; 
            await updateEventTargetList(eventId, listId, updateTargetList);        
        }
        setSelectedRowIds([]);
    };

    const handleCancelClick = () => {
        setSelectedRowIds([]);
    };

    const handleBackClick = () => {
        navigate(`/events/${eventId}/target-list`);
    };

    const fetchRegistrantData = async () =>
    {
        try {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query?.forEach((value, key) => {
                params.push([key, value]);
            });
            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

            const allRegistrants = await getEventTargetAudiencesByListId( eventId, listId, pageSize || 25, currentPage - 1, encodedParams,);
            const ids = allRegistrants.map((audience) => audience.id);
            setAudienceIds(ids);
            if (allRegistrants) {
                setIsEmpty(allRegistrants?.length === 0);
                setShowSpinner(false);
            }
            setEventRegistrant(allRegistrants);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchTargetList = async () => 
    {
        try {
            const targetList = await getEventTargetListById(eventId, listId);
            const count = targetList.audienceIds.length + targetList.targetListAudienceIds.length;
            setTargetListAudienceCount(count);
            setTargetList(targetList);
            console.log(count);
        } catch (error) {}
    };

    useEffect(() =>
    {
        fetchRegistrantData();
        fetchTargetList();
    }, []);

    useEffect((): void => 
    {
        if (currentPage && pageSize) {
            fetchRegistrantData();
        }
    }, [pageSize, currentPage, targetListAudienceCount, currentUrl]);

    return (
        <div id={userDetails?.viewType === UserViewType.MINIMAL ? "eventTargetListAudienceComponentMinimal" : "eventTargetListAudienceComponent"}>
            <div id="inside-event-module-name-section">
                <div onClick={handleBackClick} className="back-arrow">
                    <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                    <div className="target-list-text">Target List</div>
                </div>
                {" / "}
                {targetList?.name}
                <MuiChip label={ "Static" } chipColor={ "grey" } />
            </div>
            {isEmpty ? (
                <TableEmptyComponent infoText={"No Target List Audience Exists"} />
            ) : (
                <div className="h-100 w-100 table-top-container">
                    {selectedRowIds.length > 0 ? (
                        <div className="button-row">
                            <CustomButton
                                btnType="danger"
                                name="Delete"
                                onClick={handleDeleteClick}
                            />
                            <CustomButton
                                btnType="secondary"
                                name="Cancel"
                                onClick={handleCancelClick}
                            />
                            <div className="table-header-text">
                                {" "}
                                {selectedRowIds.length} rows selected{" "}
                            </div>
                        </div>
                    ) : (

                        <TanstackToolbarWithServerFilter
                          columns={audienceColumns}
                          setColumns={setAllColumns}
                          setCurrentUrl={setCurrentUrl}
                          tabs={[{ tabName: 'All', count: targetListAudienceCount, navigation: () => {}, selectedTab: true }]}
                          handleApplyFilters={handleApplyFilters}
                          filterColumns={filterColumns}
                        //   uncomment this button when functions of the button got added
                        //   rightCornerButton={<CustomButton name="+ Add Targets" btnType="secondary" style={{"fontWeight": "600"}} onClick={() => console.log('Export clicked')} />}
                        />
                    )}
                    {!isEmpty && (
                        <TanstackTable
                            checkboxSelection={true}
                            data={eventRegistrant}
                            initialColumns={allColumns}
                            rowCount={targetListAudienceCount}
                            showSpinner={showSpinner}
                            selectedRows={selectedRowIds}
                            setSelectedRows={setSelectedRowIds}
                            pageSize={pageSize}
                            currentPage={currentPage - 1}
                            updateCurrentPage={updateCurrentPage as any}
                            updatePageSize={updatePageSize}
                            rightPinnedColumns={["actions"]}
                            height={"calc(100vh - 177px)"}
                            fullWidth
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default EventTargetListAudiencePage;
