import React, { useEffect, useState } from 'react'
import { CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../../../../common/FormComponents/ReusableFormComponents'
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateEventTargetList } from '../../../../../scripts/apis/eventTargetList';
import { EventTargetList, IEditEventTargetListProps, UpdateEventTargetList } from '../../../interfaces/event-target-list-interface';
import { Stack } from '@mui/material';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { CONTENT } from '../../../../../scripts/i18n';

const EditEventTargetList: React.FC<IEditEventTargetListProps> = (props): JSX.Element => 
{
    const eventId = props['data-eventId'];
    const targetList: EventTargetList = props['targetListdata']
    const targetListId = targetList.id;
    const [spinner, setSpinner] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        description: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: targetList.name ? targetList.name : '',
            description: targetList.description ? targetList.description : '',

        },
        onSubmit: async (): Promise<void> =>
        {
            setSpinner(true);
            const targetListDetails: UpdateEventTargetList = {
                name: formik.values?.name,
                description: formik.values?.description,
            }

            if (targetListId)
            {
                const targetListUpdated = await updateEventTargetList(eventId, targetListId, targetListDetails);
                if (targetListUpdated) 
                {
                    props.setRefresh(true);
                    handleDrawerClose();
                }
            }

            setSpinner(false);
        }
    })

    const handleDrawerClose = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);
    }

    useEffect((): void => 
    {
    }, []);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value;
        formik.setFieldValue('name', value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value;
        formik.setFieldValue('description', value);
    };

    return (
        <div id='editTargetList'>
            <CloseIconComponent onClick={ handleDrawerClose } />
            <Form noValidate autoComplete="off" onSubmit={ (values: React.FormEvent<HTMLFormElement>): void => 
            {
                return formik.handleSubmit(values);
            } }>

                {/* Target List Name */ }
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label={ 'List Name' } required={ true } />
                    <FormControlComponent
                        value={ formik.values.name }
                        placeholder={ 'Enter a Target List name' }
                        type={ 'text' }
                        onChange={ handleNameChange }
                    />
                </div>

                {/* Target List Description */ }
                <div className="sidebar-container-spacing">
                    <FormLabelComponent label={ 'List Description' } />
                    <FormControlComponent
                        as="textarea"
                        value={ formik.values.description }
                        placeholder={ 'Enter a Target List Description' }
                        type={ 'text' }
                        rows={ 4 }
                        onChange={ handleDescriptionChange }
                    />
                </div>

                <div className="submit-btn-container">
                    <Stack direction={ 'row' } spacing={ 2 } display={ 'flex' } justifyContent={ 'flex-start' }>
                        <CustomButton onClick={ handleDrawerClose } name={ CONTENT.SIDE_DRAWER.CLOSE_BTN } btnType='secondary' type='button' />
                        <CustomButton onClick={ formik.handleSubmit } type="submit" name={ 'Save' } loading={ spinner } btnType='primary' />
                    </Stack>
                </div>

            </Form>
        </div>
    )
}

export default EditEventTargetList