import React, { useState, useContext, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormControlCardComponent, FormControlComponent, FormLabelComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@mui/material';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import { EventTargetListContext } from '../../../../../contexts/EventTargetList/EventTargetListContext';
import { EventTargetListSourceType, EventTargetListType } from '../../../enum/event-target-list.enum';
import { useDispatch, useSelector } from 'react-redux';
import { addTargetList } from '../../../../../redux/events/eventTargetList/targetList';
import DataImport from '../../../../../common/DataImport';
import { creatTargetListByEventId } from '../../../../../scripts/apis/eventTargetList';
import { EventTargetList } from '../../../interfaces/event-target-list-interface';

const sourceOptions = [
    { id: 1, icon: <FontAwesomeIcon icon={ ['fal', 'pen-to-square'] } />, header: "Build your own", helperText: "Build using audience & past events" },
    { id: 2, icon: <FontAwesomeIcon icon={ ['fal', 'layer-group'] } />, header: "Import", helperText: "Add contacts by uploading a csv" },
    { id: 4, icon: <Avatar src={ `https://www.google.com/s2/favicons?sz=64&domain_url=www.apollo.io` } style={ { width: 16, height: 16 } } />, header: "Apollo.io", helperText: "Coming Soon" },
    { id: 5, icon: <Avatar src={ `https://www.google.com/s2/favicons?sz=64&domain_url=www.hubspot.com` } style={ { width: 16, height: 16 } } />, header: "Hubspot", helperText: "Coming Soon" }
];

const buildYourOwnOptions = [
    { id: 6, icon: <FontAwesomeIcon icon={ ['fal', 'user-group'] } />, header: "Build using Audience", helperText: "Add from audience module" },
    { id: 3, icon: <FontAwesomeIcon icon={ ['fal', 'calendar'] } />, header: "Build using Past Events", helperText: "Add registrants from previous event" }
];

const dynamicColumns = [];

const CreateEventTargetList: React.FC = (): JSX.Element => 
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedSource, setSelectedSource] = useState<number | null>(null);
    const [isBuildYourOwn, setIsBuildYourOwn] = useState<boolean>(false);
    const { eventId, showCreate, setShowCreate } = useContext(EventTargetListContext);
    const [targetListId, setTargetListId] = useState<string>('');

    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });


    const handleDrawerClose = (event: any): void => 
    {
        navigate(-1);
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').max(100, 'Target List Name length exceeded'),
        description: Yup.string(),
        source: Yup.number().required('Source is required').test('isModeSelected', function (value)
        {
            const { createError } = this;
            if (value === 1) 
            {
                return createError({ message: 'Select a mode for Build Your Own' });
            }
            else if (value === 4 || value === 5) 
            {
                return createError({ message: 'Please choose some other Source, It is Coming Soon!' });
            }
            return true;
        }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            description: '',
            source: ''
        },
        onSubmit: async (): Promise<void> =>
        {
            createEventTargetList();
        }
    });

    const createEventTargetList = () =>
    {
        setShowCreate(false);
        dispatch(addTargetList({
            name: formik.values.name,
            description: formik.values.description,
            source: [Number(formik.values.source)],
            type: EventTargetListType.STATIC,
            eventId: eventId,
        }))
    }

    const saveEventTargetTargetList = async () =>
    {
        const eventTargetListObj: EventTargetList = {
            name: formik.values.name,
            description: formik.values.description,
            source: [Number(formik.values.source)],
            type: EventTargetListType.STATIC,
            eventId: eventId,
            audienceIds: []
        };

        dispatch(addTargetList
            ({
                name: formik.values.name,
                description: formik.values.description,
                source: [Number(formik.values.source)],
                type: EventTargetListType.STATIC,
                eventId: eventId,
            }));

        try
        {
            const newTargetList = await creatTargetListByEventId(eventId, eventTargetListObj, csrfTokenData);
            if (newTargetList.id) 
            {
                setTargetListId(newTargetList.id.toString());
                return newTargetList.id.toString(); 
            }
        } catch (error)
        {
            console.error(error);
        }
        return null;
    };


    const handleSave = async () =>
    {
        const id = await saveEventTargetTargetList();
        if (id)
        {
            setTargetListId(id);
        }
    };

    const handleListNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value;
        formik.setFieldValue('name', value);
    };

    const handleListDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
        const value = event.target.value;
        formik.setFieldValue('description', value);
    };

    const handleSourceSelect = (id: number) =>
    {
        setSelectedSource(id);
        formik.setFieldValue('source', id);
    };

    return (
        <div id="createTargetListWrapper">
            <div
                className={`header-container ${
                    selectedSource === EventTargetListSourceType.IMPORT_CSV ? "import-csv" : ""
                }`}
            >
                <CustomButton name={"Exit"} btnType={"secondary"} onClick={handleDrawerClose} />
                {/* <CustomButton name={ 'Save' } btnType={ 'secondary' } style={ { border: 'unset' } } onClick={ handleDrawerClose } /> */}
                <div
                    className={`header-text ${formik.values.name ? "header-text-with-title" : ""}`}
                >
                    {formik.values.name ? formik.values.name : "List Name"}
                </div>
                {selectedSource === EventTargetListSourceType.IMPORT_CSV && formik.values.name ? (
                    <>
                        <CustomButton
                            type="button"
                            name={"Save"}
                            btnType={"secondary"}
                            onClick={handleSave}
                        />

                        {!targetListId && (
                            <CustomButton
                                type="button"
                                name={"Next"}
                                btnType={"primary"}
                                name="Next"
                                disabled={true}
                            />
                        )}

                        {targetListId && (
                            <DataImport
                                licenseKey={import.meta.env.VITE_TARGET_LIST_SHEET_ID}
                                eventId={eventId}
                                dynamicColumns={dynamicColumns}
                                targetListId={targetListId}
                                btnType="primary"
                                name="Next"
                                startIcon=""
                            />
                        )}
                    </>
                    ) : (
                        <CustomButton
                            type="submit"
                            name={"Next"}
                            btnType={"primary"}
                            onClick={formik.handleSubmit}
                        />
                    )}
            </div>
            <div className="createTargetListContainer">
                <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div>
                        {/* List Name */}
                        <div className="sidebar-container-spacing">
                            <FormLabelComponent label={"List Name"} required={true} />
                            <FormControlComponent
                                value={formik.values.name}
                                placeholder={"Enter your list name"}
                                type={"text"}
                                onChange={handleListNameChange}
                                isInvalid={formik.errors.name && formik.touched.name}
                            />
                            <div className="title-length-error-cont">
                                {formik.errors.name && formik.touched.name ? (
                                    <p className="error-msg">{formik.errors.name}</p>
                                ) : (
                                    <div />
                                )}
                            </div>
                        </div>

                        {/* List Description */}
                        <div className="sidebar-container-spacing">
                            <FormLabelComponent label={"List Description"} />
                            <FormControlComponent
                                as="textarea"
                                value={formik.values.description}
                                placeholder={"Enter your list description"}
                                type={"text"}
                                onChange={handleListDescriptionChange}
                                rows={4}
                                isInvalid={formik.errors.description && formik.touched.description}
                            />
                            {formik.errors.description && formik.touched.description ? (
                                <p className="error-msg">{formik.errors.description}</p>
                            ) : (
                                <div />
                            )}
                        </div>

                        {/* Source */}
                        <div className="sidebar-container-spacing">
                            <FormLabelComponent label={"Select Source"} required={true} />
                            <div className="card-container">
                                <Row>
                                    {sourceOptions.map((option) => (
                                        <Col xs={12} md={6} key={option.id} className="mb-3">
                                            <div
                                                className={`fade-card ${
                                                    selectedSource === option.id
                                                        ? "active"
                                                        : selectedSource !== null
                                                        ? "fade"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    if (option.id == 1) {
                                                        setIsBuildYourOwn(true);
                                                    } else {
                                                        setIsBuildYourOwn(false);
                                                    }
                                                    handleSourceSelect(option.id);
                                                }}
                                            >
                                                <FormControlCardComponent
                                                    icon={option.icon}
                                                    header={option.header}
                                                    helperText={option.helperText}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                    {formik.errors.source && formik.touched.source ? (
                                        <p className="error-msg">{formik.errors.source}</p>
                                    ) : (
                                        <div />
                                    )}
                                </Row>

                                <Row>
                                    {isBuildYourOwn && (
                                        <FormLabelComponent label={"Select Mode"} required={true} />
                                    )}

                                    {isBuildYourOwn &&
                                        buildYourOwnOptions.map((option) => (
                                            <Col xs={12} md={6} key={option.id} className="mb-3">
                                                <div
                                                    className={`fade-card ${
                                                        selectedSource === option.id ||
                                                        selectedSource === 1
                                                            ? "active"
                                                            : selectedSource !== null
                                                            ? "fade"
                                                            : ""
                                                    }`}
                                                    onClick={() => handleSourceSelect(option.id)}
                                                >
                                                    <FormControlCardComponent
                                                        icon={option.icon}
                                                        header={option.header}
                                                        helperText={option.helperText}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            {/* <div className="switch-footer">
                {selectedSource === EventTargetListSourceType.IMPORT_CSV && formik.values.name ? (
                    <>
                        <CustomButton
                            type="button"
                            name={"Save"}
                            btnType={"secondary"}
                            onClick={handleSave}
                        />

                        {!targetListId && (
                            <CustomButton
                                type="button"
                                name={"Next"}
                                btnType={"primary"}
                                name="Next"
                                disabled={true}
                            />
                        )}

                        {targetListId && (
                            <DataImport
                                licenseKey={import.meta.env.VITE_TARGET_LIST_SHEET_ID}
                                eventId={eventId}
                                dynamicColumns={dynamicColumns}
                                targetListId={targetListId}
                                btnType="primary"
                                name="Next"
                                startIcon=""
                            />
                        )}
                    </>
                ) : (
                    <CustomButton
                        type="submit"
                        name={"Next"}
                        btnType={"primary"}
                        onClick={formik.handleSubmit}
                    />
                )}
            </div> */}
        </div>
    );
}

export default CreateEventTargetList;
