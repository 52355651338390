import { Avatar, Box, Tooltip } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import MuiChip from "../../common/FormComponents/MuiChip";
import { Audience } from "./interfaces";
import _ from "lodash";
import { buildInitialsForPicture } from "../../scripts/helpers";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { UserViewType } from "../Settings/enum/users.enum";

const data = [
    {
      "field": "companyLogo",
      "headerName": "Logo",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "firstName",
      "headerName": "First Name",
      "hide": false,
      "disableColumnMenu": true
    },
    {
        "field": "lastName",
        "headerName": "Last Name",
        "hide": false,
        "disableColumnMenu": true
      },
    {
      "field": "email",
      "headerName": "Email",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "icp",
      "headerName": "ICP",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "buyerPersona",
      "headerName": "Buyer Persona",
      "hide": false,
      "disableColumnMenu": true
    },
    {
      "field": "givenName",
      "headerName": "Given Name",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "familyName",
      "headerName": "Family Name",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "location",
      "headerName": "Location",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "linkedinHandle",
      "headerName": "Linkedin",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "phone",
      "headerName": "Phone",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "geo.city",
      "headerName": "City",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "geo.state",
      "headerName": "State",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "geo.country",
      "headerName": "Country",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "employment.title",
      "headerName": "title",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "employment.role",
      "headerName": "role",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "employment.subRole",
      "headerName": "subRole",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "employment.seniority",
      "headerName": "Seniority",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "category.subIndustry",
      "headerName": "Sub Industry",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "company",
      "headerName": "Company",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "companyDomain",
      "headerName": "Company Domain",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "companyGeo.country",
      "headerName": "Company Location",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "metrics.employees",
      "headerName": "Employees",
      "hide": true,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "metrics.employeesRange",
      "headerName": "Employees Range",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "metrics.raised",
      "headerName": "Raised",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "metrics.annualRevenue",
      "headerName": "Annual Revenue",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "metrics.estimatedAnnualRevenue",
      "headerName": "Estimated Annual Revenue",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "companyTechCategories",
      "headerName": "Tech Categories",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    },
    {
      "field": "companyTech",
      "headerName": "Tech",
      "hide": false,
      "disableColumnMenu": true,
      "clearbit": true,
    }
];

export const generateAudienceColumnsForTanstackTable = () => 
{
    const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

    const columnHelper = createColumnHelper<Audience>();

    const columns: any[] = [];

    const { 
      userDetails
    } = useContext(UserContext);

    data.forEach((item): void => {
        if (item?.field === 'companyLogo') {
            columns.push(columnHelper.accessor('companyLogo', {
                cell: (row) => {
                    const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                    const clearbitLName = row?.row?.original?.clearbitData?.familyName;

                    const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : buildInitialsForPicture(row?.row?.original?.email, row?.row?.original?.firstName, row?.row?.original?.lastName || undefined);
                    return <Box className="avatar-container">
                        {
                            <Avatar className="logo-avatar-img" src={row?.getValue() as string}>
                                {!row?.getValue() ? name : ''}
                            </Avatar>
                        }
                    </Box>;
                },
                header: _.startCase(item.headerName),
                size: 100,
                enableSorting: false,
                id: 'companyLogo'
            }));
        }
        else if (item?.field === 'firstName') { 
            columns.push(columnHelper.accessor('firstName', {
                cell: (row) => {
                    return <Tooltip placement='top' title={row?.getValue()}><Box maxWidth={200} className="bolded-cellContent">{`${row?.row?.original?.firstName}`}</Box></Tooltip>;
                },
                header: _.startCase(item.headerName),
                size: 200,
                enableSorting: false,
                id: 'firstName'
            }));
        }
        else if (item?.field === 'lastName') {
            columns.push(columnHelper.accessor('lastName', {
                cell: (row) => {
                    return <Tooltip placement='top' title={row?.getValue()}><Box maxWidth={200} className="cellContent">{row?.getValue()}</Box></Tooltip>;
                },
                header: _.startCase(item.headerName),
                size: 200,
                enableSorting: false,
                id: 'lastName'
            }));
        }
        else if (item?.field === 'email') {
            columns.push(columnHelper.accessor('email', {
                cell: (row) => {
                    return <Tooltip placement='top' title={row?.getValue()}><Box className="cellContent">{row?.getValue()}</Box></Tooltip>;
                },
                header: _.startCase(item.headerName),
                enableSorting: false,
                id: 'email'
            }));
        } 
        else if (item.field === 'icp' || item.field === 'buyerPersona') {
            columns.push(columnHelper.accessor(item?.field || '', {
                cell: (row: any) => {
                const icpChipColor = item.field === 'icp' ? 'blue' : 'pink';

                return (
                    <Box>
                        <Tooltip placement='top' title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                            <Box>
                            {row?.getValue()?.length > 0 ? (
                                row?.getValue()?.length > 1 ? (
                                <Box className="table-chip-cell">
                                    <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                    <p className="table-chip-count">
                                    +{row?.getValue()?.length - 1}
                                    </p>
                                </Box>
                                ) : (
                                <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                )
                            ) : null}
                            </Box>
                        </Tooltip>
                    </Box>
                );
                },
                header: _.startCase(item.headerName),
                size: 200,
                enableSorting: false,
                id: item?.field === 'icp' ? 'icp' : 'buyerPersona',
            }));
        }
        else if (item?.clearbit && userDetails?.viewType === UserViewType.NORMAL) {
            columns.push(
                columnHelper.accessor((row) => {
                const audienceRow = row as Audience;
                return audienceRow?.clearbitData && getNestedValue(audienceRow?.clearbitData, item?.field);
                },
                {
                    id: `clearbitData.${item?.field}`,
                    cell: (row) => {
                    return <Tooltip placement='top' title={row?.getValue()}><Box className="cellContent">{row?.getValue()}</Box></Tooltip>;
                    },
                    header: () => (
                    <Tooltip arrow placement="bottom" title="Enriched by Clearbit">
                        <div className="clearbit-column-header">
                        <span className="header-name">{_.startCase(item.headerName)}</span>
                        <Box component="img" src={'https://www.google.com/s2/favicons?sz=64&domain_url=https://clearbit.com/'} className="clearbit-logo" />
                        </div>
                    </Tooltip>
                    ),
                    meta: {
                    headerName: _.startCase(item.headerName)
                    },
                    size: 200,
                    enableResizing: true,
                    maxSize: 300,
                    sortingFn: 'alphanumericCaseSensitive',
                })
            );
        }
    });

    const filteredColumns = columns.filter((column): boolean => {
        return !excludedFields.includes(column.field);
    });

    return filteredColumns;
};

const getNestedValue = (obj: Record<string, any>, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};