/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { Autocomplete, Box, Button, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, SelectComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { Form } from 'react-bootstrap';
import { CONTENT } from '../../../scripts/i18n';
import _ from 'lodash';
import './styles.scss';
import { addCustomField, updateCustomField } from '../../../scripts/apis/customField';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreateCustomField = (props):React.JSX.Element => 
{

    const { customFieldData } = props;

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const fieldTypeOptions = [
        { id: 1, name: 'Single Line' },
        { id: 2, name: 'Multi-line text' },
        { id: 3, name: 'Checkboxes' },
        { id: 4, name: 'Dropdown' },
        { id: 5, name: 'Date' },
        { id: 6, name: 'Number' },
        { id: 9, name: 'Radio' },
      ];

    // const [customFieldInfo, setCustomFieldInfo] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [fieldLocation, setFieldLocation] = useState(customFieldData?.fieldLocations ? customFieldData.fieldLocations : []);
    const [fieldOptions, setFieldOptions] = useState(customFieldData?.fieldOptions ? customFieldData.fieldOptions : []);
    const [gdprOptions, setGdprOptions] = useState(customFieldData?.fieldGDPR ? customFieldData.fieldGDPR : []);

    const validationSchema = Yup.object().shape({
        // fieldName: Yup.string().required('Name is required'),
        fieldLocations: Yup.array().required('Field Location is required'),
        type: Yup.string().required('Type of field is required'),
    });

    const filterEmptyValues = (obj):any => 
    {
        if (typeof obj !== 'object' || obj === null) return obj;

        if (Array.isArray(obj)) 
        {
            return obj.filter((value):any => 
            {
                return filterEmptyValues(value); 
            });
        }

        return Object.fromEntries(
            Object.entries(obj).map(([key, value]):any => 
            {
                if (typeof value === 'object' && value !== null) 
                {
                    const filteredValue = filterEmptyValues(value);
                    if (filteredValue !== null && filteredValue !== undefined) 
                    {
                        return [key, filteredValue];
                    }
                }
                else if (value !== '' && value !== null && value !== undefined) 
                {
                    return [key, value];
                }
                return undefined;
            }).filter(Boolean)
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fieldName: customFieldData?.fieldName ? customFieldData.fieldName : '',
            fieldLocations: customFieldData?.fieldLocations ? customFieldData.fieldLocations : '',
            type: customFieldData?.type ? customFieldData.type : '',
            label: customFieldData?.label ? customFieldData?.label?.replace(/<[^>]*>/g, '') : '',
            placeholder: customFieldData?.placeholder ? customFieldData.placeholder : '',
            helpText: customFieldData?.helpText ? customFieldData.helpText : '',
            fieldMandatory: customFieldData?.fieldMandatory ? customFieldData.fieldMandatory : 2,
            fieldDisabled: null,
            fieldGDPR: '',
            fieldOptions: customFieldData?.fieldOptions ? customFieldData.fieldOptions : [],
            fieldOptionsSubType: null,
            fieldNumeric: {
                defaultValue: customFieldData?.fieldNumeric?.defaultValue ? customFieldData.fieldNumeric.defaultValue : '',
                min: customFieldData?.fieldNumeric?.min ? customFieldData.fieldNumeric.min : '',
                max: customFieldData?.fieldNumeric?.max ? customFieldData.fieldNumeric.max : ''
            },
            heading: '',
            paragraph: '',
            default: '',
        },
        validationSchema,

        onSubmit: async (values):Promise<void> => 
        {
            setSpinner(true);
            setSpinner(true);
            let filteredValues = filterEmptyValues(values);

            filteredValues.fieldName = values.label;

            if (fieldOptions.length > 0) 
            {
                const options = fieldOptions.filter((item):boolean => 
                {
                    return item.value !== ''; 
                }).map((item):string | number => 
                {
                    return item; 
                });
                filteredValues = {
                    ...filteredValues, 'fieldOptions': options, 
                };
            }

            if (gdprOptions.length > 0) 
            {
                const options = gdprOptions.filter((item):boolean => 
                {
                    return item.paragraph !== '' || item.fieldOptions.name !== ''; 
                }).map((item):string | number => 
                {
                    return item; 
                });
                filteredValues = {
                    ...filteredValues, 'fieldGDPR': options 
                };
            }

            if (filteredValues) 
            {
                if (customFieldData) 
                {
                    try 
                    {
                        const updateCustomFieldData = await updateCustomField(customFieldData.id, filteredValues);
                        if (updateCustomFieldData) 
                        {
                            setSpinner(false);
                            handleDrawerClose();
                            props.setRefresh(true);
                        }

                    }
                    catch (error) 
                    {
                        setSpinner(false);
                        console.log(error);
                    }
                }
                else 
                {
                    try 
                    {
                        const addCustomFieldData = await addCustomField(filteredValues, csrfTokenData);
                        if (addCustomFieldData) 
                        {
                            setSpinner(false);
                            handleDrawerClose();
                            props.setRefresh(true);
                        }

                    }
                    catch (error) 
                    {
                        setSpinner(false);
                        console.log(error);
                    }
                }

            }

        }
    });

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    useEffect(():void => 
    {

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        // if (customFieldData) 
        // {
        //     setCustomFieldInfo(customFieldData);
        // }
    }, []);

    const handleFieldType = (event):void => 
    {
        formik.setFieldValue('type', event.target.value);
    };

    const handleFieldLocationChange = (event, newValue):void => 
    {

        const selectedLocations = newValue.length > 0 ? newValue.map((elem):string | number => 
        {
            return _.find(Object.keys(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS).map((item):string | number => 
            {
                return item; 
            }), (item):boolean => 
            {
                return elem === CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[item]; 
            }); 
        }) : [];

        setFieldLocation([...selectedLocations]);

        formik.setFieldValue('fieldLocations', selectedLocations);
    };

    const handleOptionsAdd = ():void => 
    {
        setFieldOptions([...fieldOptions, {
            name: '', value: '' 
        }]);
    };

    const handleOptionsDelete = (value):void => 
    {
        if (fieldOptions.includes(value)) 
        {
            const updatedOptions = fieldOptions.filter((item):boolean => 
            {
                return item !== value; 
            }).map((updatedItem):{name:string, value:string} => 
            {
                return updatedItem; 
            });
            setFieldOptions(updatedOptions);
        }
    };

    const handleGdprOptionsDelete = (index):void => 
    {
        const updatedOptions = gdprOptions.filter((item, itemIndex):boolean => 
        {
            return itemIndex !== index; 
        }).map((updatedItem):{name:string, value:string} => 
        {
            return updatedItem; 
        });
        setGdprOptions(updatedOptions);

    };

    return (
        <Box id="createCustomField">
            <CloseIconComponent onClick={handleDrawerClose} />
            <Form noValidate autoComplete="off" onSubmit={(values):void => 
            {
                return formik.handleSubmit(values);
            }}>
                {/* <Box className="container-spacing">
                    <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="form-label">{'Name'}</Typography><Typography className="required-icon">{'*'}</Typography></Box></Form.Label>
                    <FormControlComponent
                        type={'text'}
                        value={formik.values.fieldName}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('fieldName', event.target.value);
                        }}
                        placeholder="Name"
                    />
                    {formik.errors.fieldName && formik.touched.fieldName ? <Typography className="error-msg">{formik.errors.fieldName}</Typography> : null}
                </Box> */}

                <Box className="container-spacing">
                    <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="form-label">{'Field Location'}</Typography><Typography className="required-icon">{'*'}</Typography></Box></Form.Label>
                    <AutocompletewithTags
                        defaultValue={fieldLocation.length > 0 ? fieldLocation.map((item):string | number => 
                        {
                            return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[item];
                        }) : []}
                        value={fieldLocation.length > 0 ? fieldLocation.map((item):string | number => 
                        {
                            return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[item];
                        }) : []}
                        placeholder="Select Field Location"
                        onChange={handleFieldLocationChange}
                        className="autocomplete-select"
                        onBlur={formik.handleBlur('fieldLocation')}
                        onRemoveClick={(index) => {
                            const updatedLocations = [...fieldLocation];
                            updatedLocations.splice(index, 1);
                            setFieldLocation(updatedLocations);
                            formik.setFieldValue('fieldLocations', updatedLocations);
                        }}
                        options={Object.keys(CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS)
                            .filter((item) => item !== '5')
                            .map((item):string|number => 
                            {
                                return CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_LOCATIONS[item];
                            })
                        }
                    />
                    {formik.errors.fieldLocations && formik.touched.fieldLocations ? <Typography className="error-msg">{formik.errors.fieldLocations}</Typography> : null}
                </Box>

                <Box className="container-spacing">
                    <Form.Label><Box display={'flex'} flexDirection={'row'}><Typography className="form-label">{'Field Type'}</Typography><Typography className="required-icon">{'*'}</Typography></Box></Form.Label>
                    <SelectComponent
                        value={formik.values.type}
                        defaultPlaceholder="Select Field Type"
                        optionLabels={CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE}
                        onChange={handleFieldType}
                        options={fieldTypeOptions}
                    />
                        
                    {formik.errors.type && formik.touched.type ? <Typography className="error-msg">{formik.errors?.type}</Typography> : null}
                </Box>

                {formik.values.type &&
                    <Box className="type-input-block">
                        <Box className={'edit-input-component'}>
                            {(formik.values.type == '11' || formik.values.type == '12' || formik.values.type == '13') ? null :
                                <Box className="component-type-mandatory-container">
                                    <Typography className="component-category">{CONTENT.SETTINGS_PAGE.CUSTOM_FIELD.CUSTOM_FIELD_TYPE[formik.values.type]}</Typography>
                                    {/* <FormControlLabel
                                        checked={formik.values?.fieldMandatory ? formik.values?.fieldMandatory === 1 : false}
                                        control={<CustomSwitch sx={{ m: 1 }} name="required" onChange={(event) => {
                                            const value = event.target.checked ? 1 : 2;
                                            formik.setFieldValue('fieldMandatory', value);
                                        }} />}
                                        label={<Typography className="required-label">
                                            Required
                                        </Typography>}
                                    /> */}
                                </Box>
                            }

                            {(formik.values.type == '7' || formik.values.type == '8' || formik.values.type == '11' || formik.values.type == '12' || formik.values.type == '13') ? null :
                                <>
                                    <Box paddingBottom={'24px'}>
                                        <Form.Label className="component-info-label">Label</Form.Label>
                                        <FormControlComponent
                                            type={'text'}
                                            value={formik.values.label}
                                            onChange={(event):void => 
                                            {
                                                formik.setFieldValue('label', event.target.value);
                                            }}
                                            placeholder="Label"
                                        />

                                    </Box>
                                    {/* <Box paddingBottom={'24px'}>
                                        <Form.Label className="component-info-label">Description</Form.Label>
                                        <FormControlComponent
                                            type={'text'}
                                            value={formik.values.helpText}
                                            onChange={(event):void => 
                                            {
                                                formik.setFieldValue('helpText', event.target.value);
                                            }}
                                            placeholder="Label"
                                        />
                                    </Box> */}
                                    {/* <Box paddingBottom={'24px'}>
                                        <Form.Label className="component-info-label">Placeholder Text</Form.Label>
                                        <FormControlComponent
                                            type={'text'}
                                            value={formik.values.placeholder}
                                            onChange={(event):void => 
                                            {
                                                formik.setFieldValue('placeholder', event.target.value);
                                            }}
                                            placeholder="Placeholder"
                                        />
                                    </Box> */}
                                </>
                            }

                            {formik.values.type == '6' && (
                                <>
                                    <Box paddingBottom={'24px'}>
                                        <Form.Label className="component-info-label">Default Value</Form.Label>
                                        <FormControlComponent
                                            type={'number'}
                                            value={formik.values.fieldNumeric.defaultValue}
                                            onChange={(event):void => 
                                            {
                                                const updatedValues = {
                                                    defaultValue: event.target.value.toString(),
                                                    min: formik.values.fieldNumeric.min,
                                                    max: formik.values.fieldNumeric.max
                                                };
                                                formik.setFieldValue('fieldNumeric', updatedValues);
                                            }}
                                            placeholder="Default Value"
                                        />
                                    </Box>
                                    <Box paddingBottom={'24px'} className='min-max-block'>
                                        <Box className="min-value">
                                            <Form.Label className="component-info-label">Min Value</Form.Label>
                                            <FormControlComponent
                                                type={'number'}
                                                value={formik.values.fieldNumeric.min}
                                                onChange={(event):void => 
                                                {
                                                    const updatedValues = {
                                                        defaultValue: formik.values.fieldNumeric.defaultValue,
                                                        min: event.target.value,
                                                        max: formik.values.fieldNumeric.max
                                                    };
                                                    formik.setFieldValue('fieldNumeric', updatedValues);
                                                }}
                                                placeholder="Min Value"
                                            />
                                        </Box>
                                        <Box className="max-value">
                                            <Form.Label className="component-info-label">Max Value</Form.Label>
                                            <FormControlComponent
                                                type={'number'}
                                                value={formik.values.fieldNumeric.max}
                                                onChange={(event):void => 
                                                {
                                                    const updatedValues = {
                                                        defaultValue: formik.values.fieldNumeric.defaultValue,
                                                        min: formik.values.fieldNumeric.min,
                                                        max: event.target.value
                                                    };
                                                    formik.setFieldValue('fieldNumeric', updatedValues);
                                                }}
                                                placeholder="Max Value"
                                            />
                                        </Box>

                                    </Box>
                                </>

                            )}

                            {(formik.values.type == '3' || formik.values.type == '4' || formik.values.type == '9') &&
                                <>
                                    <Box className={'options-block'} paddingBottom={'24px'}>
                                        <Form.Label className="component-info-label option-label">Add Options for your field</Form.Label>
                                        {(fieldOptions && fieldOptions.length > 0) &&
                                            fieldOptions.map((item, index):React.JSX.Element => 
                                            {
                                                return (
                                                    <Box key={index} className='option-box'>
                                                        <FormControlComponent
                                                            type={'text'}
                                                            value={item.value}
                                                            onChange={(event):void => 
                                                            {
                                                                const options = [...fieldOptions];
                                                                options[index].name = event.target.value;
                                                                options[index].value = event.target.value;
                                                                setFieldOptions(options);
                                                            }}
                                                            placeholder="Option"
                                                        />

                                                        <Button onClick={():void => 
                                                        {
                                                            handleOptionsDelete(item); 
                                                        }} className="delete-option">Delete</Button>
                                                    </Box>
                                                );
                                            })
                                        }
                                        <Button onClick={handleOptionsAdd} className="add-option-btn">+ Add Option</Button>

                                    </Box>
                                </>
                            }
                            {formik.values.type == '7' &&
                                <Box paddingBottom={'24px'}>
                                    <Form.Label className="component-info-label">{'Add Your Header Text Here'}</Form.Label>
                                    <FormControlComponent
                                        type={'text'}
                                        value={formik.values.heading}
                                        onChange={(event):void => 
                                        {
                                            formik.setFieldValue('heading', event.target.value);
                                        }}
                                        placeholder="Heading"
                                    />

                                </Box>
                            }
                            {formik.values.type == '8' &&
                                <Box paddingBottom={'24px'}>
                                    <Form.Label className="component-info-label">{'Add Your Paragraph Here'}</Form.Label>
                                    <FormControlComponent
                                        type={'text'}
                                        value={formik.values.paragraph}
                                        onChange={(event):void => 
                                        {
                                            formik.setFieldValue('paragraph', event.target.value);
                                        }}
                                        placeholder="Paragraph"
                                    />
                                </Box>
                            }

                            {(formik.values.type == '11' || formik.values.type == '12' || formik.values.type == '13') &&
                                <>
                                    <Box className={'options-block'} paddingBottom={'24px'}>
                                        {(gdprOptions && gdprOptions.length > 0) &&
                                            gdprOptions.map((item, index):React.JSX.Element => 
                                            {
                                                return (
                                                    <Box key={index} className='gdpr-option-box'>
                                                        {/* <Box className="component-type-mandatory-container">
                                                            <Typography className="component-category">{'want to make this check required ?'}</Typography>
                                                            <FormControlLabel
                                                                checked={item?.['fieldMandatory'] ? item['fieldMandatory'] === '1' : false}
                                                                control={<CustomSwitch sx={{ m: 1 }} name="required" onChange={(event) => {
                                                                    const value = event.target.checked ? 1 : 2;
                                                                    let options = [...gdprOptions];
                                                                    options[index]['fieldMandatory'] = value;
                                                                    setGdprOptions(options);
                                                                }} />}
                                                                label={null}
                                                            />
                                                        </Box> */}
                                                        {/* <Form.Label className="component-info-label">GDPR Content</Form.Label> */}
                                                        <FormControlComponent
                                                            type={'text'}
                                                            value={item.paragraph}
                                                            onChange={(event):void => 
                                                            {
                                                                const options = [...gdprOptions];
                                                                options[index].paragraph = event.target.value;
                                                                setGdprOptions(options);
                                                            }}
                                                            placeholder="Paragraph"
                                                        />
                                                        {/* <Form.Label className="component-info-label">GDPR Option</Form.Label> */}
                                                        {/* <FormControlComponent
                                                            type={'text'}
                                                            value={item.fieldOptions.value}
                                                            onChange={(event) => {
                                                                let options = [...gdprOptions];
                                                                options[index].fieldOptions.name = event.target.value;
                                                                options[index].fieldOptions.value = event.target.value;
                                                                setGdprOptions(options);
                                                            }}
                                                            placeholder="Option"
                                                        /> */}

                                                        <Button onClick={():void => 
                                                        {
                                                            handleGdprOptionsDelete(index); 
                                                        }} className="delete-option">Delete Option</Button>
                                                    </Box>
                                                );
                                            })
                                        }
                                        <Button onClick={():void => 
                                        {
                                            setGdprOptions([...gdprOptions, {
                                                paragraph: '', fieldOptions: {
                                                    name: '', value: '' 
                                                }, ['fieldMandatory']: 2 
                                            }]); 
                                        }} className="add-option-btn">+ Add GDPR Option</Button>

                                    </Box>
                                </>
                            }

                        </Box>


                    </Box>

                }

                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                        <CustomButton type="submit" loading={spinner} name={customFieldData ? 'Update Custom Field' : 'Create Custom Field'} btnType={'primary'} />
                    </Stack>
                </Box>

            </Form >
        </Box >
    );
};

export default CreateCustomField;
