import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { EventRegistrant } from "../../../interfaces/event-registrant_interface";
import { Avatar, Tooltip } from "@mui/material";
import MuiChip from "../../../../../common/FormComponents/MuiChip";
import _ from "lodash";
import { buildInitialsForPicture, getNestedValueFromObject, getSimilarKey, omitDuplicatesAndRemovePunctuations } from "../../../../../scripts/helpers";
import { CONTENT } from "../../../../../scripts/i18n";
import { IEventReduxValues } from "../../../../../components/Events/interfaces/create-events_interface";
import { EventRegistrantAttendedStatus } from "../../../enum/event-registrant.enum";
import { EventGraphObject, EventTicket } from "../../../interfaces";
import { EnableWaitlistStatus, TicketType } from "../../../enum";
import { useEffect, useMemo, useState } from "react";
import { TableActions } from "../../../../../common/TableActions";
import { EventLeadCapture } from "../../../interfaces/event-lead-capture_interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";

export const registrantInitialColumnData = [
    {
        "field": "companyLogo",
        "headerName": "Logo",
    },
    {
        "field": "firstName",
        "headerName": "First Name",
    },
    {
        "field": "lastName",
        "headerName": "Last Name",
    },
    {
        "field": "email",
        "headerName": "Email",
    },
    {
        "field": "status",
        "headerName": "Status",
    },
    {
        "field": "attendeeStatus",
        "headerName": "Attendee Status",
    },
    {
        "field": "paymentStatus",
        "headerName": "Paid Status",
    },
    {
        "field": "ticket",
        "headerName": "Ticket",
    },
    {
        "field": "shareLink",
        "headerName": "Share Link",
    },
    {
        "field": "icp",
        "headerName": "ICP",
    },
    {
        "field": "buyerPersona",
        "headerName": "Buyer Persona",
    },
    {
        "field": "givenName",
        "headerName": "Given Name",
        "clearbit": true,
    },
    {
        "field": "familyName",
        "headerName": "Family Name",
        "clearbit": true,
    },
    {
        "field": "location",
        "headerName": "Location",
        "clearbit": true,
    },
    {
        "field": "linkedinHandle",
        "headerName": "Linkedin",
        "clearbit": true,
    },
    {
        "field": "phone",
        "headerName": "Phone",
        "clearbit": true,
    },
    {
        "field": "geo.city",
        "headerName": "City",
        "clearbit": true,
    },
    {
        "field": "geo.state",
        "headerName": "State",
        "clearbit": true,
    },
    {
        "field": "geo.country",
        "headerName": "Country",
        "clearbit": true,
    },
    {
        "field": "employment.title",
        "headerName": "title",
        "clearbit": true,
    },
    {
        "field": "employment.role",
        "headerName": "role",
        "clearbit": true,
    },
    {
        "field": "employment.subRole",
        "headerName": "subRole",
        "clearbit": true,
    },
    {
        "field": "employment.seniority",
        "headerName": "Seniority",
        "clearbit": true,
    },
    {
        "field": "category.subIndustry",
        "headerName": "Sub Industry",
        "clearbit": true,
    },
    {
        "field": "company",
        "headerName": "Company",
        "clearbit": true,
    },
    {
        "field": "companyDomain",
        "headerName": "Company Domain",
        "clearbit": true,
    },
    {
        "field": "companyGeo.country",
        "headerName": "Company Location",
        "clearbit": true,
    },
    {
        "field": "metrics.employees",
        "headerName": "Employees",
        "clearbit": true,
    },
    {
        "field": "metrics.employeesRange",
        "headerName": "Employees Range",
        "clearbit": true,
    },
    {
        "field": "metrics.raised",
        "headerName": "Raised",
        "clearbit": true,
    },
    {
        "field": "metrics.annualRevenue",
        "headerName": "Annual Revenue",
        "clearbit": true,
    },
    {
        "field": "metrics.estimatedAnnualRevenue",
        "headerName": "Estimated Annual Revenue",
        "clearbit": true,
    },
    {
        "field": "companyTechCategories",
        "headerName": "Tech Categories",
        "clearbit": true,
    },
    {
        "field": "companyTech",
        "headerName": "Tech",
        "clearbit": true,
    },
    {
        "field": "actions",
        "headerName": ""
    }
];

interface IRegistrantProperty {
    field: string;
    headerName: string;
    clearbit?: boolean;
    additionalData?: boolean;
    leadData?: boolean;
}

export const useRegistrantColumnHelper = (data?: IRegistrantProperty[], eventData?: IEventReduxValues, eventTickets?: EventTicket[], registrants?: EventRegistrant[], onEditClick?: (row: EventRegistrant) => void, onDeleteClick?: (row: EventRegistrant) => void, leads?: EventLeadCapture[]) => {

    const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

    const columnHelper = createColumnHelper<EventRegistrant>();
    // const columns: any[] = [];
    const [modifiedData, setModifiedData] = useState<IRegistrantProperty[] | undefined>(data);

    useEffect(() => {
        // Column manipulation here

        // Check if the current date is same or after the event start date and remove the attendee status column
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
    
        const eventStartDate = new Date(Number(eventData?.eventStartDateTime) * 1000);
        eventStartDate.setHours(0, 0, 0, 0);
    
        const isCurrentDateSameorAfterEventStartDate = currentDate.getTime() >= eventStartDate.getTime();
        if (!isCurrentDateSameorAfterEventStartDate && data)
        {
            data = data.filter(item => item.field !== 'attendeeStatus');
        }
    
        // Check if the paid ticket is present and remove the paid
        const isPaidTicketPresent = eventTickets?.some(ticket => ticket?.type === TicketType.PAID);
        if (!isPaidTicketPresent && data)
        {
            data = data.filter(item => item.field !== 'paymentStatus');
        }
        // Check if the ticket is present and remove the ticket column
        const isTicketsPresent = registrants?.some(registrant => registrant?.ticket);
        if (!isTicketsPresent && data)
        {
            data = data.filter(item => item.field !== 'ticket');
        }

        // Check if the share link is present and remove the share link column
        const isShareLinkPresent = registrants?.some(registrant => registrant?.utm);
        if (!isShareLinkPresent && data)
        {
            data = data.filter(item => item.field !== 'shareLink');
        }
        const additionalData = registrants?.map(obj => _.omit(obj?.additionalData, 'gdprData'));
        if (additionalData && data) {
            const additionalDataFields = _.uniq(_.flatMap(additionalData, obj => Object.keys(obj || {})));
            const additionalDataColumns = omitDuplicatesAndRemovePunctuations(additionalDataFields)?.map(field => ({
                field,
                headerName: _.startCase(field),
                additionalData: true
            }));
            const updatedData = [...data];
            updatedData.splice(8, 0, ...additionalDataColumns);
            setModifiedData(updatedData);
        }

        const leadData = leads && leads?.length > 0 && leads.some(obj => 'leadData' in obj) || leads && leads?.length > 0 && leads.some(obj => 'registrantData' in obj);
        if (leadData && data)
        {
            const leadDataFields = _.uniq(_.flatMap(leads, obj => Object.keys(obj?.leadData || obj?.registrantData || {})));
            const leadDataColumns = omitDuplicatesAndRemovePunctuations(leadDataFields)?.map(field => ({
                field,
                headerName: _.startCase(field),
                leadData: true
            }));
            const updatedData = [...data];
            updatedData.splice(5, 0, ...leadDataColumns);
            setModifiedData(updatedData);
        }
    }, [registrants, leads]);

    const columns = useMemo(() => {
        const cols: any[] = [];

        modifiedData?.forEach((item: IRegistrantProperty) => { 
            if (item.field === 'companyLogo') {
                cols.push(columnHelper.accessor('companyLogo' as any, {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        const clearbitFName = row?.row?.original?.clearbitData?.givenName;
                        const clearbitLName = row?.row?.original?.clearbitData?.familyName;
                        const name = clearbitFName && clearbitLName ? buildInitialsForPicture('', clearbitFName, clearbitLName) : row?.row?.original?.firstName ? buildInitialsForPicture('', row?.row?.original?.firstName, '') : buildInitialsForPicture('', row?.row?.original?.firstName, '');
    
                        return (
                            <Avatar className="logo-avatar-img" src={row?.row?.original?.clearbitData && row?.row?.original?.clearbitData?.companyLogo ? row?.row?.original?.clearbitData?.companyLogo : ''}>
                                {!row?.row?.original?.clearbitData?.companyLogo ? name : ''}
                            </Avatar>
                        );
                    },
                    minSize: 122,
                    size: 122,
                    header: _.startCase(item.headerName),
                    enableSorting: false,
                    id: 'companyLogo',
                }));
            }
            else if (item.field === 'firstName') {
                cols.push(columnHelper.accessor('firstName', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
    
    
                        return (
                            <Tooltip disableInteractive placement='top' title={<p>{row?.row?.original?.firstName}</p>}><div className="bolded-cellContent">{row?.row?.original?.firstName}</div></Tooltip>
                        );
                    },
                    size: 200,
                    header: _.startCase(item.headerName),
                    id: 'firstName',
                    meta: {
                        type: 'string',
                    }
                }));
            }
            else if (item.field === 'lastName') {
                cols.push(columnHelper.accessor('lastName', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        return (
                            <Tooltip disableInteractive placement='top' title={<p>{row?.row?.original?.lastName || row?.row?.original?.additionalData?.lastName || ''}</p>}><div className="cellContent">{row?.row?.original?.lastName || row?.row?.original?.additionalData?.lastName || ''}</div></Tooltip>
                        );
                    },
                    size: 200,
                    header: _.startCase(item.headerName),
                    id: 'lastName',
                    meta: {
                        type: 'string',
                    }
                }));
            }
            else if (item.field === 'email') {
                cols.push(columnHelper.accessor('email', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        return (
                            <Tooltip placement='top' title={<p>{row?.row?.original?.email}</p>}>
                                <div className="d-flex align-items-center gap-2">
                                    <FontAwesomeIcon icon={['fal', 'copy']} onClick={(event) => 
                                    {
                                        event.stopPropagation();
                                        navigator.clipboard.writeText(row?.row?.original?.email);
                                        toast.success('Email copied to clipboard');
                                    }} />
                                    <div className="cellContent">{row?.row?.original?.email}</div>
                                </div>
                            </Tooltip>
                        );
                    },
                    header: _.startCase(item.headerName),
                    id: 'email',
                    meta: {
                        type: 'string',
                    }
                }));
            }
            else if (item.field === 'status') {
                cols.push(columnHelper.accessor('status', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        const registrantClass = {
                            1: 'grey',
                            2: 'green',
                            3: 'red',
                            4: 'yellow',
                            5: 'deleted',
                            6: 'blue'
                        };
                
                        const status = row.row.original?.status;
                        const registrantStatusLabel = CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[status as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS];
                        const registrantChipColor = registrantClass[status as keyof typeof registrantClass];
                
                        return (
                            <MuiChip circleIcon label={registrantStatusLabel} chipColor={registrantChipColor as 'green' | 'grey' | 'red' | 'yellow' | 'blue' | 'null'} />
                        );
                    },
                    header: _.startCase(item.headerName),
                    id: 'status',
                    meta: {
                        type: 'dropdown',
                        filterOptions: Object.keys(CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS).map((key) => { 
                            return { name: CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS[key as unknown as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.STATUS], value: Number(key) };
                        })
                    }
                }));
            }
            else if (item.field === 'attendeeStatus') { 
                cols.push(columnHelper.accessor('attendeeStatus', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        const attendeeStatus = row.row.original?.attendeeStatus;
                        const attendeeStatusLabel = CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS[attendeeStatus as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS];
                        const attendeeChipColor = attendeeStatus === EventRegistrantAttendedStatus.YES ? 'green' : 'red';
                        return (
                            <MuiChip circleIcon label={attendeeStatusLabel} chipColor={attendeeChipColor as 'green' | 'red'} />
                        );
                    },
                    header: _.startCase(item.headerName),
                    id: 'attendeeStatus',
                    meta: {
                        type: 'dropdown',
                        filterOptions: Object.keys(CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS).map((key) => { 
                            return { name: CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS[key as unknown as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS], value: Number(key) };
                        })
                    }
                }));
            }
            else if (item.field === 'paymentStatus') {
                cols.push(columnHelper.accessor('paymentStatus' as any, {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        const paymentStatusClass = {
                            1: 'grey',
                            2: 'blue',
                            3: 'orange',
                            4: 'red',
                            5: 'yellow'
                        };
    
                        const paidStatus = row.row.original?.paymentStatus;
                        const paidStatusLabel = CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS[paidStatus as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS];
                        const paymentChipColor = paymentStatusClass[Number(paidStatus) as keyof typeof paymentStatusClass];
    
                        return (
                            <MuiChip circleIcon label={paidStatusLabel} chipColor={paymentChipColor as 'grey' | 'red' | 'yellow' | 'blue' | 'orange'} />
                        );
                    },
                    header: _.startCase(item.headerName),
                    id: 'paymentStatus',
                    meta: {
                        type: 'dropdown',
                        filterOptions: Object.keys(CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS).map((key) => { 
                            return { name: CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS[key as unknown as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS], value: Number(key) };
                        })
                    }
                }));
            }
            else if (item.field === 'ticket') {
                cols.push(columnHelper.accessor('ticket', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
    
                        const ticket = row.row.original?.ticket;
                        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
    
                        return ticket && <MuiChip circleIcon label={ticket?.name} chipColor={chipColors[Number(ticket?.id) % chipColors.length] as any} />
                    },
                    header: _.startCase(item.headerName),
                    id: 'ticket',
                }));
            }
            else if (item.field === 'shareLink') { 
                cols.push(columnHelper.accessor('shareLink' as 'utm', {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        
                        const shareLink = row.row.original?.utm;
                        const chipColors = ['red', 'green', 'blue', 'yellow', 'grey', 'pink', 'violet', 'orange'];
                        return (
                            shareLink && <MuiChip circleIcon label={shareLink?.name} chipColor={chipColors[Number(shareLink?.id) % chipColors.length] as 'red' | 'green' | 'blue' | 'yellow' | 'grey' | 'pink' | 'violet' | 'orange'} />
                        );
                    },
                    header: _.startCase(item.headerName),
                    id: 'shareLink',
                }));
            }
            else if (item.field === 'icp' || item.field === 'buyerPersona') {
                cols.push(columnHelper.accessor(item?.field || '', {
                    cell: (row: any) => {
                        const icpChipColor = item.field === 'icp' ? 'blue' : 'pink';
    
                        return (
                            <div>
                                <Tooltip disableInteractive placement='top' title={row?.getValue()?.length > 0 && row?.getValue()?.map((value: string) => value).join(', ')}>
                                    <div>
                                        {row?.getValue()?.length > 0 ? (
                                            row?.getValue()?.length > 1 ? (
                                                <div className="table-chip-cell">
                                                    <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                                    <p className="table-chip-count">
                                                        +{row?.getValue()?.length - 1}
                                                    </p>
                                                </div>
                                            ) : (
                                                <MuiChip circleIcon chipColor={icpChipColor} label={row?.getValue()[0]} />
                                            )
                                        ) : null}
                                    </div>
                                </Tooltip>
                                {/* )} */}
                            </div>
                        );
                    },
                    header: _.startCase(item.headerName),
                    size: 200,
                    enableSorting: false,
                    id: item?.field === 'icp' ? 'icp' : 'buyerPersona',
                }));
            }
            else if (item?.additionalData) {
                cols.push(
                columnHelper.accessor(`additionalData.${item.field}`, {
                    id: `additionalData.${item?.field}`,
                    cell: (row) => {
                        let value = '';
                        const similarKey = getSimilarKey(row?.row?.original?.additionalData, item?.field);
                        if (similarKey) {
                            value += row?.row?.original?.additionalData[similarKey];
                        }
                        return (
                            <Tooltip disableInteractive placement='top' title={<p>{value}</p>}>
                                <div className="cellContent">{value}</div>
                            </Tooltip>
                        );
                    },
                    header: _.startCase(item.headerName),
                    size: 200,
                    enableResizing: true,
                    maxSize: 300,
                    sortingFn: 'alphanumericCaseSensitive',
                    meta: {
                        headerName: _.startCase(item.headerName)
                    },
                })
                );
            }
            else if (item?.clearbit) {
                cols.push(
                    columnHelper.accessor((row) => {
                        return row?.clearbitData && getNestedValueFromObject(row?.clearbitData, item?.field);
                    },
                        {
                            id: `clearbitData.${item?.field}`,
                            cell: (row) => {
                                return <Tooltip disableInteractive placement='top' title={<p>{row?.getValue()}</p>}><div className="cellContent">{row?.getValue()}</div></Tooltip>;
                            },
                            header: () => (
                                <Tooltip title="Enriched by Clearbit">
                                    <div className="clearbit-column-header">
                                        <span className="header-name">{_.startCase(item.headerName)}</span>
                                        <img src={'https://www.google.com/s2/favicons?sz=64&domain_url=https://clearbit.com/'} className="clearbit-logo" />
                                    </div>
                                </Tooltip>
                        ),
                            size: 200,
                            enableResizing: true,
                            maxSize: 300,
                            sortingFn: 'alphanumericCaseSensitive',
                            meta: {
                                headerName: _.startCase(item.headerName)
                            },
                        })
                );
            }
            else if (item?.field === 'actions') { 
                cols.push(columnHelper.accessor('actions' as any, {
                    cell: (row: CellContext<EventRegistrant, any>) => {
                        return (
                            <TableActions 
                            actions={[
                                {
                                    title: 'Edit', onClick: () => {
                                        if (onEditClick)
                                        {
                                            onEditClick(row.row.original as EventRegistrant);
                                        }
                                    }
                                },
                                {
                                    title: 'Delete', onClick: () => {
                                        if (onDeleteClick)
                                        {
                                            onDeleteClick(row.row.original as EventRegistrant);
                                        }
                                    }
                                }
                            ]}
                            />
                        );
                    },
                    size: 40,
                    header: "",
                    id: 'actions',
                }));
            }
            else if (item?.leadData) {
                cols.push(
                    columnHelper.accessor((row: EventLeadCapture) => {
                        return row?.leadData && getNestedValueFromObject(row?.leadData, item?.field);
                    },
                        {
                            id: `leadData.${item?.field}`,
                            cell: (row) => {
                                return <Tooltip disableInteractive placement='top' title={<p>{row?.getValue()}</p>}><div className="cellContent">{row?.getValue()}</div></Tooltip>;
                            },
                            header: () => (
                                <Tooltip title="Lead Data">
                                    <div className="clearbit-column-header">
                                        <span className="header-name">{_.startCase(item.headerName)}</span>
                                    </div>
                                </Tooltip>
                        ),
                            size: 200,
                            enableResizing: true,
                            maxSize: 300,
                            sortingFn: 'alphanumericCaseSensitive',
                            meta: {
                                headerName: _.startCase(item.headerName)
                            },
                        })
                );
            }
        });

        return cols.filter((column): boolean => {
            return !excludedFields.includes(column.field);
        });
    }, [modifiedData]);
    
    return columns;
};

export const registrantFilterColumnOptions = (tickets?: EventTicket[], registrantStatusCount?: EventGraphObject, eventData?: IEventReduxValues) => {

    const statusOptions = () => {
        const baseOptions = [
            {
                name: 'In Review',
                value: 1
            },
            {
                name: 'Rejected',
                value: 3
            },
            {
                name: 'Deleted',
                value: 5
            },
            {
                name: 'Confirmed',
                value: 6
            }
        ];

        if ((tickets && tickets.length > 0 && tickets.some((ticket) => ticket.type === TicketType.PAID)) || (registrantStatusCount && Object.keys(registrantStatusCount).includes('APPROVED'))) {
            baseOptions.splice(1, 0, {
                name: 'Approved',
                value: 2
            });
        }

        if (tickets && tickets?.length && tickets?.some((ticket) => ticket.enableWaitlistStatus === EnableWaitlistStatus.ENABLE))
        {
            baseOptions.splice(3, 0, {
                name: 'Waitlist',
                value: 4
            });
        }

        return baseOptions;
    };
    
    const filterColumns = [
        {
            header: 'First Name',
            id: 'firstName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Status',
            id: 'status',
            meta: {
                type: 'dropdown',
                filterOptions: statusOptions()
            }
        },
        {
            header: 'Attendee Status',
            id: 'attendeeStatus',
            meta: {
                type: 'dropdown',
                filterOptions: Object.keys(CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS).map((key) => { 
                    return { name: CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS[key as unknown as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.ATTENDEE_STATUS], value: Number(key) };
                })
            }
        },
        {
            header: 'Payment Status',
            id: 'paymentStatus',
            meta: {
                type: 'dropdown',
                filterOptions: Object.keys(CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS).map((key) => { 
                    return { name: CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS[key as unknown as keyof typeof CONTENT.EVENTS_MODULE.REGISTRANTS.REGISTRANT_PAYMENT_STATUS], value: Number(key) };
                })
            }
        }
    ];

    if (!(tickets && tickets?.length > 0 && tickets?.some((ticket) => ticket.type === TicketType.PAID))) {
        _.remove(filterColumns, (column) => column.header === 'Payment Status');
    }

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const eventStartDate = new Date(Number(eventData?.eventStartDateTime) * 1000);
    eventStartDate.setHours(0, 0, 0, 0);

    const isCurrentDateSameorAfterEventStartDate = currentDate.getTime() >= eventStartDate.getTime();
    if (!isCurrentDateSameorAfterEventStartDate) {
        _.remove(filterColumns, (column) => column.header === 'Attendee Status');
    }

    return filterColumns;
};
