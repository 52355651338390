import React, { useContext, useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import './styles.scss';
import { LocalStorage } from '../scripts/LocalStorage';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import SideBarNavigation from '../common/SideBarNavigation';
import TopAppBar from '../common/TopAppbar';
import SideDrawer from '../common/SideDrawer';
import PopUp from '../common/PopUp';
import CustomDialog from '../common/CustomDialog';
import { useDispatch } from 'react-redux';
import { getCsrfToken } from '../scripts/helpers';
import { csrfTokenValue } from '../redux/authToken';
import DemoAppBar from '../common/DemoAppBar';
import { Toaster } from 'react-hot-toast';
import { CONTENT } from '../scripts/i18n';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { clarity } from 'react-microsoft-clarity';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import { UserContext } from '../contexts/UserContext';
import { UserViewType } from '../pages/Settings/enum/users.enum';

// import Alerts from '../common/Alerts';
// import SideBarNavigation from '../common/SideBarNavigation';

const ProtectedRoute = (props):React.JSX.Element => 
{

    const dispatch = useDispatch();

    const {
        userDetails
    } = useContext(UserContext)

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const currentpath = useLocation().pathname;

    const sideBarShow = props['side-bar'];
    const collapsedSideBarShow = props['collapsed-side-bar'];
    const topAppBarShow = props['top-app-bar'];

    const user = LocalStorage.get('@UserMe');
    // JSON.stringify(user))
    const location = useLocation();

    const isEventsModule = currentpath?.includes('/events') && !currentpath?.endsWith('/events');

    if(import.meta.env.VITE_NODE_ENV !== 'local' && user && user.orgId)
    {
        const claritySet = LocalStorage.get('@Organization');
        if(!claritySet)
        {
            clarity.init(import.meta.env.VITE_CLARITY_ID);
            clarity.identify(user.id, { userProperty: user.email, organization: user.orgId });
            clarity.setTag('organization', user.orgId);
            LocalStorage.set('@Organization', user.orgId);
        }
    }


    const fetchCsrfTokenData = async ():Promise<void> => 
    {
        const csrf = await getCsrfToken();
        if (csrf) 
        {
            dispatch(csrfTokenValue({
                csrfToken: csrf.csrfToken 
            }));
        }
    };

    // const userBasedClarity = ():void =>
    // {
    //     try 
    //     {
    //         if (clarity.hasStarted() && user) 
    //         {
    //             clarity.identify(user.id, { userProperty: user.email });
    //         }
    //     } 
    //     catch (error) 
    //     {
            
    //     }

    // };

    useEffect(():void => 
    {
        fetchCsrfTokenData();
        // userBasedClarity();
    }, []);

    const tabChangeEvent = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.TAB_CHANGE, true);
    };    

    const mainAppSidebarProps = {
        mainContent: [
            {
                title: 'Dashboard',
                selected: Object.values(CONTENT.SIDE_NAV_BAR.DASHBOARD.PAGES).includes(currentpath),
                navigate: 'dashboard',
                onClick: tabChangeEvent,
                icon: 'clapperboard',
            },
            {
                title: 'Events',
                selected: currentpath?.includes('/events') && !currentpath?.includes('/analytics/events'),
                navigate: userDetails && userDetails?.viewType === UserViewType.MINIMAL ? 'events?eventPhase=2' : 'events',
                onClick: tabChangeEvent,
                icon: 'calendar',
            },
            {
                title: 'Audience',
                selected: currentpath === '/audience',
                navigate: 'audience',
                onClick: tabChangeEvent,
                icon: 'user-group',
            },
            {
                title: 'Speakers',
                selected: currentpath === '/speakers',
                navigate: 'speakers',
                onClick: tabChangeEvent,
                icon: 'microphone',
            },
            ...(orgBillingInfo?.billingModules?.sponsor ? [{
                title: 'Sponsors',
                selected: currentpath === '/sponsors',
                navigate: 'sponsors',
                onClick: tabChangeEvent,
                icon: 'dollar-sign',
            }] : []),
            ...(orgBillingInfo?.billingModules?.vendor ? [{
                title: 'Vendors',
                selected: currentpath === '/vendors',
                navigate: 'vendors',
                onClick: tabChangeEvent,
                icon: 'hexagon',
            }] : []),
            {
                title: 'Registrations',
                selected: currentpath?.endsWith('/registrations') && !currentpath?.includes('events'),
                navigate: 'registrations',
                onClick: tabChangeEvent,
                icon: 'file',
            },
            {
                title: 'Budget',
                selected: Object.values(CONTENT.SIDE_NAV_BAR.BUDGET.PAGES).includes(currentpath),
                navigate: 'budget',
                onClick: tabChangeEvent,
                icon: 'chart-pie-simple',
            },
            {
                title: 'Analytics',
                selected: Object.values(CONTENT.SIDE_NAV_BAR.ANALYTICS_PAGE.PAGES).includes(currentpath),
                navigate: 'analytics/audience',
                onClick: tabChangeEvent,
                icon: 'chart-simple-horizontal',
            },
        ]
    }

    let component;

    if (sideBarShow || collapsedSideBarShow) 
    {
        component = <React.Fragment>
            {(!isEventsModule || userDetails?.viewType === UserViewType.NORMAL) && <Box>
                <SideBarNavigation sidebarcontent={mainAppSidebarProps} collapsedSideBar={collapsedSideBarShow} />
            </Box>}
            <Box sx={{
                width: `${collapsedSideBarShow ? '100%' : 'calc(100% - 240px)'}`, marginLeft: `${collapsedSideBarShow && (!isEventsModule || userDetails?.viewType === UserViewType.NORMAL) ? '65px !important' : '0px !important'}`, display: 'flex', flexDirection: 'column !important' 
            }}>
                {topAppBarShow !== false ? <TopAppBar /> : null}
                <Box sx={{
                    width: '100%',
                    height: '100%'
                }}><Outlet /></Box>
            </Box>
        </React.Fragment>;
    }
    else 
    {
        component = <Box sx={{
            width: '100%', backgroundColor: '#FFFFFF' 
        }}>
            <Box className='w-100 h-100' sx={{
                display: 'flex', flexDirection: 'column' 
            }}>
                {topAppBarShow !== false ? <TopAppBar /> : null}
                <Box sx={{
                    width: '100%',
                    height: '100%'
                }}><Outlet /></Box>
            </Box>
        </Box>;
    }

    const components =
        <Box id="appLayout">
            <div className="content-div">
                {import.meta.env.VITE_NODE_ENV === 'demo' && <DemoAppBar />}
                <Box display={'flex'} height={'100%'} minHeight={'100%'}>{component}</Box>
                <SideDrawer />
                <CustomDialog />
                <PopUp />
                <Toaster />
            </div>
        </Box>;

    return (
        user ? (
            components
            // import.meta.env.VITE_NODE_ENV === 'local' ? components : import.meta.env.VITE_NODE_ENV !== 'production' ? <CmdKConfigurer token={APP_CONSTANTS.CMDK_TOKEN}>{components}</CmdKConfigurer> : components
        ) : <Navigate to="/login" state={{
            from: location
        }} replace />
    );
};

export default ProtectedRoute;
