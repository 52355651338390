/* eslint-disable import/named */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import { decompressImage } from '../../common/ImageProcessing';
import { updateOrgInfo } from '../../redux/organization/Organization';
import _ from 'lodash';
import { UserContext } from '../UserContext';
import { Organization } from '../../interfaces/settings/organization_interface';

export interface organization {
    organization: {
        value: IOrganizationReduxValue;
    }
}

export interface IOrganizationReduxValue {
    id: string;
    name: string;
    link: string;
}

interface IOrganisationPageContext { 
    orgImage: string | null;
    orgImageFile: any;
    setOrgImageFile: React.Dispatch<React.SetStateAction<any>>;
    orgLinkSpinner: boolean;
    setOrgLinkSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    orgLinkAvailable: boolean;
    isOrgLinkAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    spinner: boolean;
    setSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    coverImgFile: any;
    setCoverImgFile: React.Dispatch<React.SetStateAction<any>>;
}

export const OrganisationPageContext = createContext<IOrganisationPageContext>({
    orgImage: '',
    orgImageFile: null,
    setOrgImageFile: () => {},
    orgLinkSpinner: false,
    setOrgLinkSpinner: () => {},
    orgLinkAvailable: false,
    isOrgLinkAvailable: () => {},
    spinner: false,
    setSpinner: () => {},
    isEditing: false,
    setIsEditing: () => {},
    coverImgFile: null,
    setCoverImgFile: () => {},
});

const OrganisationPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{

    const {
        orgDetails
    } = useContext(UserContext);

    const orgObject = {
        name: '',
        link: '',
        additionalInfo: {
            image: null
        },
    };

    const dispatch = useDispatch();

    const [orgImageFile, setOrgImageFile] = useState(null);
    const [orgImage, setOrgImage] = useState('');
    const [orgLinkSpinner, setOrgLinkSpinner] = useState(false);
    const [orgLinkAvailable, isOrgLinkAvailable] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [isEditing, setIsEditing] = useState(true);
    const [coverImgFile, setCoverImgFile] = useState(null);

    eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
        heading: CONTENT.SETTINGS_PAGE.HEADING,
    });

    const fetchOrgData = async (): Promise<void> => 
    {
        // const org = await orgMe();
        // setOrg(org);
        dispatch(updateOrgInfo({
            name: orgDetails?.name,
            link: orgDetails?.link,
            additionalInfo: orgDetails?.additionalInfo,
            gstin: orgDetails?.gstin,
            address: orgDetails?.address,
        }));
        // setValidateLink((orgDetails.link.split('qlorganisation')[0] === '') || (orgDetails.link.split('scorganisation')[0] === '') ? '' : orgDetails.link);
        // const organisationDetails = {

        // };
        // organisationDetails['name'] = orgDetails.name;
        // organisationDetails['link'] = orgDetails.link;
        // organisationDetails['additionalInfo'] = orgDetails.additionalInfo ? orgDetails.additionalInfo : new Object();
        if (orgDetails?.additionalInfo && orgDetails.additionalInfo?.image) 
        {
            const x = await decompressImage(orgDetails.additionalInfo?.image, 1500, 1500);
            setOrgImage(x);
        }
        else 
        {
            setOrgImage(null);
        }
        setOrgImageFile(orgDetails && orgDetails?.additionalInfo && orgDetails?.additionalInfo.image ? orgDetails.additionalInfo.image : null);
        // if (orgDetails?.social) 
        // {
        //     const socialInputs = Object.entries(orgDetails?.social)
        //         ?.filter(([, value]): boolean => 
        //         {
        //             return value !== null;
        //         })
        //         ?.map(([name, value]): {name: string; value: string} => 
        //         {
        //             return {
        //                 name,
        //                 value
        //             };
        //         });

        //     if (socialInputs) 
        //     {
        //         const filteredOptions = _.filter(initialSocialOptions, function (elem): boolean 
        //         {
        //             const stringedArr = _.map(socialInputs, 'name');
        //             return !(stringedArr.includes(elem.value));
        //         });
        //         const socialsSortOrder = ['website', 'linkedIn', 'twitter'];
        //         const sortedOptionsArr = _.sortBy(filteredOptions, (obj): number => 
        //         {
        //             return _.indexOf(socialsSortOrder, obj.value);
        //         });
        //         setSocialOptionsObj(sortedOptionsArr);
        //         setSocialsInputFields(_.sortBy(socialInputs, (obj): number => 
        //         {
        //             return _.indexOf(socialsSortOrder, obj.name);
        //         }));
        //     }
        // }

    };

    useEffect((): void => 
    {
        fetchOrgData();
    }, [orgDetails]);

    // useEffect((): void => 
    // {
    //     if (refresh) 
    //     {
    //         fetchOrgData();
    //     }
    // }, [refresh]);

    return (
        <OrganisationPageContext.Provider 
            value={{
                orgImage,
                orgImageFile, 
                setOrgImageFile, 
                orgLinkSpinner, 
                setOrgLinkSpinner, 
                orgLinkAvailable, 
                isOrgLinkAvailable, 
                spinner, 
                setSpinner, 
                isEditing, 
                setIsEditing, 
                coverImgFile,
                setCoverImgFile
            }}
        >
            {children}
        </OrganisationPageContext.Provider>
    );

};

export default OrganisationPageProvider;
