import PersonalPage from './UserSettings/PersonalPage';
import MembersPage from './Members/MembersPage';
import './styles.scss';
import MembersPageProvider from '../../contexts/Settings/MembersPageContext';
import PersonalPageProvider, { general } from '../../contexts/Settings/PersonalPageContext';
import ICPPageProvider from '../../contexts/Settings/ICPPageContext';
import ICPPage from './ICP/ICPPage';
import BuyerPersonaPage from './BuyerPersona/BuyerPersonaPage';
import BuyerPersonaPageProvider from '../../contexts/Settings/BuyerPersonaPageContext';
import IntegrationsTabPage from './Integrations/IntegrationsTabPage';
import CustomFieldPageProvider from '../../contexts/Settings/CustomFieldContext';
import CustomFieldPage from './CustomField/CustomeFieldPage';
import SideBarTabsNavigation from '../../common/SideBarTabsNavigation/SideBarTabsNavigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserOrgRoles } from './enum/users.enum';
import { LocalStorage } from '../../scripts/LocalStorage';
import OrganisationPageProvider from '../../contexts/Settings/OrganisationPageContext';
import OrganizationPage from './Organization/OrganizationPage';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import HeaderBar from '../../common/Headerbar';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

const SettingsPage = (pageContext) => 
{

    const navigate = useNavigate();

    const currentPath = useLocation().pathname;

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const generalData = useSelector((state: general) => 
    {
        return state.general.value;
    });

    const userMeData = LocalStorage.get('@UserMe');

    const tab = pageContext.subTab;

    const {
        userDetails
    } = useContext(UserContext);

    const isProfileComponent = currentPath === '/settings/general' ;
    const isMembersComponent = currentPath === '/settings/users';
    const isIntegrationsComponent = currentPath.includes('/settings/integrations');
    const isIcpComponent = currentPath === '/settings/icp';
    const isBuyerPersonaComponent = currentPath === '/settings/buyer-persona';
    const isCustomFieldComponent = currentPath === '/settings/custom-field';
    const isOrganizationComponent = currentPath === '/settings/organization';

    // const tabData = [
    //     {
    //         title: 'Profile',
    //         to: '/settings/general',
    //         data: <PersonalPageProvider><PersonalPage /></PersonalPageProvider>,
    //         selected: tab === 'general',
    //     },
    //     {
    //         title: 'Members',
    //         to: '/settings/users',
    //         data: <MembersPageProvider><MembersPage /></MembersPageProvider>,
    //         // count: userDataCount,
    //         selected: tab === 'users',
    //     },
    //     {
    //         title: 'Integrations',
    //         to: '/settings/integrations',
    //         data: <IntegrationsTabPage subTab={pageContext.subTab} />,
    //         selected: tab === 'integrations',
    //     },
    //     {
    //         title: 'ICP',
    //         to: '/settings/icp',
    //         data: <ICPPageProvider><ICPPage /></ICPPageProvider>,
    //         selected: tab === 'icp',
    //     },
    //     {
    //         title: 'Buyer Persona',
    //         to: '/settings/buyer-persona',
    //         data: <BuyerPersonaPageProvider><BuyerPersonaPage /></BuyerPersonaPageProvider>,
    //         selected: tab === 'buyer-persona',
    //     },
    //     {
    //         title: 'Custom Fields',
    //         to: '/settings/custom-field',
    //         data: <CustomFieldPageProvider><CustomFieldPage/></CustomFieldPageProvider>,
    //         selected: tab === 'custom-field',
    //     },
    //     // {
    //     //     value: '3',
    //     //     title: 'Teams',
    //     //     to: '/settings/teams',
    //     //     icon: <GroupOutlined />,
    //     //     data: <TeamsPage />,
    //     //     count: teamDataCount,
    //     //     selected: tab === 'teams',
    //     // },
    //     // {
    //     //     value: '4',
    //     //     title: 'Organization',
    //     //     to: '/settings/organization',
    //     //     icon: <Diversity3Outlined />,
    //     //     data: <OrganizationPage />,
    //     //     selected: tab === 'organization',
    //     // },
    //     // {
    //     //     value: '5',
    //     //     title: 'Billing',
    //     //     to: '/billing',
    //     //     icon: <NotesOutlined />,
    //     //     data: <BillingPage />,
    //     //     selected: tab === 'billing',
    //     // }
    // ];

    // if (generalData) 
    // {
    //     const orgObj = {
    //         title: 'Organization',
    //         to: '/settings/organization',
    //         data: <OrganisationPageProvider><OrganizationPage /></OrganisationPageProvider>,
    //         selected: tab === 'organization',
    //     };

    //     if (generalData?.role && generalData?.role === 3) 
    //     {
    //         tabData.splice(1, 0, orgObj);
    //     }
    // }

    const settingsNavigationBlocks = [
        {
            title: 'Profile',
            navigation: ():void => 
            {
                return navigate('/settings/general'); 
            },
            selected: currentPath === '/settings/general'
        },
        {
            title: 'Members',
            navigation: ():void => 
            {
                return navigate('/settings/users'); 
            },
            selected: currentPath === '/settings/users'
        },
        ...(orgBillingInfo?.billingModules?.integrations ? [{
            title: 'Integrations',
            navigation: ():void => 
            {
                return  navigate('/settings/integrations'); 
            },
            selected: currentPath.includes('/settings/integrations')
        }] : []),
        {
            title: 'ICP',
            navigation: ():void => 
            {
                navigate('/settings/icp'); 
            },
            selected: currentPath === '/settings/icp'
        },
        {
            title: 'Buyer Persona',
            navigation: ():void => 
            {
                return navigate('/settings/buyer-persona'); 
            },
            selected: currentPath === '/settings/buyer-persona'
        },
        {
            title: 'Custom Fields',
            navigation: ():void => 
            {
                return navigate('/settings/custom-field'); 
            },
            selected: currentPath === '/settings/custom-field'
        },
    ];

    if(userDetails && userDetails?.role === UserOrgRoles.SUPERADMIN && userMeData?.role === UserOrgRoles.SUPERADMIN)
    {
        settingsNavigationBlocks?.splice(1, 0, 
        {
            title: 'Organization',
            navigation: ():void => 
            {
                return navigate('/settings/organization'); 
            },
            selected: currentPath === '/settings/organization'
        })
    }

    return (
        <>
            <HeaderBar 
                title='Settings'
            />
            <div id="settingsPage">
                <SideBarTabsNavigation
                    navigationBlocks={settingsNavigationBlocks}
                />

                {isProfileComponent && <PersonalPageProvider><PersonalPage /></PersonalPageProvider>}
                {isMembersComponent && <MembersPageProvider><MembersPage /></MembersPageProvider>}
                {isIntegrationsComponent &&  <IntegrationsTabPage subTab={tab} />}
                {isIcpComponent && <ICPPageProvider><ICPPage /></ICPPageProvider>}
                {isBuyerPersonaComponent && <BuyerPersonaPageProvider><BuyerPersonaPage /></BuyerPersonaPageProvider>}
                {isCustomFieldComponent && <CustomFieldPageProvider><CustomFieldPage/></CustomFieldPageProvider>}
                {isOrganizationComponent && <OrganisationPageProvider><OrganizationPage /></OrganisationPageProvider>}
                {/* <NavigationTabs borderBottom={'1px solid #F1F1F1'} tabData={tabData} /> */}
               
            </div>
        </>
    );
};

export default SettingsPage;
