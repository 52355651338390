import { Chip, Stack } from '@mui/material';
import React from 'react';
import { IMuiChipProps } from './IReusableFormComponents';

const MuiChip = (props: IMuiChipProps): React.JSX.Element => 
{  
    const { label, chipColor, rounded, circleIcon } = props;

    const chipClasses = {
        blue: 'chip-blue',
        green: 'chip-green',
        grey: 'chip-grey',
        yellow: 'chip-yellow',
        red: 'chip-red',
        pink: 'chip-pink',
        violet: 'chip-violet',
        null: 'chip-null',
        orange: 'chip-orange'
    };

    const chipLabel = (
        <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <DotIcon color={chipColor} />
            <span>{label}</span>
        </Stack>
    )

    return (
        <div id="muiChipComponent">
            <Chip style={{ borderRadius: rounded ? '9999px' : '' }} label={circleIcon && label !== '-' ? chipLabel : label} className={`chip-component ${circleIcon ? 'icon-chip' : chipClasses[chipColor]}`} />
        </div>
    );
};

export default MuiChip;

export const DotIcon = ({ color }: { color:  'blue' | 'green' | 'grey' | 'yellow' | 'red' | 'pink' | 'violet' | 'orange' }) => 
{
    const colorMap = {
        blue: '#2E90FA',
        green: '#17B26A',
        grey: '#98A2B3',
        yellow: '#F79009',
        red: '#F04438',
        pink: '#C11574',
        violet: '#5925DC',
        orange: '#B93815',
    };

    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="4" fill={colorMap[color]} />
        </svg>
    );
}