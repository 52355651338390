import React, { useContext } from 'react';
import BudgetAnalytics from './BudgetAnalytics';
import { Box } from '@mui/material';
import './styles.scss';
import EventAnalytics from './EventsAnalytics';
import RoiAnalytics from './RoiAnalytics';
import AudienceAnalyticsMain from './AudienceAnalytics/AudienceAnalyticsMain';
import { useLocation, useNavigate } from 'react-router-dom';
import FormTabs from '../../common/FormTabs';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import HeaderBar from '../../common/Headerbar';


const AnalyticsPage = (props: { page: string }): React.JSX.Element => 
{

    const currentpath = useLocation().pathname;
    const navigate = useNavigate();
    const { userDetails } = useContext(UserContext);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    const analyticsTabObj = [
        {
            tabName: 'Audience',
            selectedTab: currentpath === '/analytics/audience',
            navigation: (): void => 
            {
                return navigate('/analytics/audience');
            }
        },
        {
            tabName: 'RoI',
            selectedTab: currentpath === '/analytics/roi',
            navigation: (): void => 
            {
                return navigate('/analytics/roi');
            }
        },
        {
            tabName: 'Budget',
            selectedTab: currentpath === '/analytics/budget',
            navigation: (): void => 
            {
                return navigate('/analytics/budget');
            }
        },
        {
            tabName: 'Events',
            selectedTab: currentpath === '/analytics/events',
            navigation: (): void => 
            {
                return navigate('/analytics/events');
            }
        },
    ];

    const analyticsView = (normalView = true): React.JSX.Element =>
    {
        return (
            <Box id='AnalyticsMainCont'>
                {normalView ? <div className="analytics-header-container"><HeaderBar title='Analytics'/></div> : null}
                { normalView && <div className="analytics-tab-container">
                    <FormTabs tabs={analyticsTabObj} />
                </div> }
                <Box className='analytics-graph-cont'>
                    {props.page === 'Audience' && <AudienceAnalyticsMain />}
                    {props.page === 'Budget' && <BudgetAnalytics />}
                    {props.page === 'Events' && <EventAnalytics />}
                    {props.page === 'roi' && <RoiAnalytics />}
                </Box>
            </Box>
        );
    }

    const minimalAnalyticsView = (): React.JSX.Element =>
    {
        return (
            <div id="analyticsMinimaView">
                <MinimalViewComponent
                    title={'Analytics'}
                    description={'View your analytics here'}
                    tabData={analyticsTabObj}
                    showCharts={false}
                    component={analyticsView(false)}
                    componentType="list"
                />
            </div>
        )
    }

    return ( minimalView ? minimalAnalyticsView() : analyticsView() );
        // <Box id='AnalyticsMainCont'>
        //     <div className="analytics-tab-container">
        //         <FormTabs tabs={analyticsTabObj} />
        //     </div>
        //     <Box className='analytics-graph-cont'>
        //         {props.page === 'Audience' && <AudienceAnalyticsMain />}
        //         {props.page === 'Budget' && <BudgetAnalytics />}
        //         {props.page === 'Events' && <EventAnalytics />}
        //         {props.page === 'roi' && <RoiAnalytics />}
        //     </Box>
        // </Box>
};

export default AnalyticsPage;