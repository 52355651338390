import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteSponsor, getAllSponsors, getSponsorGraphData, sponsorsCount, updateSponsor } from '../scripts/apis/sponsors';
import { useTablePagination } from './TablePaginationContext';
import _ from 'lodash';
import { ISponsor } from '../pages/Sponsors/interfaces';
import toast from 'react-hot-toast';
import { getAllSponsorTiers, getEventSponsorGraphData, getEventSponsors, getEventSponsorsCount, removeEventSponsor, sponsorTierCount } from '../scripts/apis/eventSponsors';
import { useLocation } from 'react-router-dom';
import { EventSponsorTier } from '../pages/Events/interfaces';
import { UserContext } from './UserContext';

interface SponsorsPageContextProps { 
    isEmpty: boolean;
    sponsorDataCount: number;
    sponsors: ISponsor[];
    sponsorGroupData?: EventSponsorTier[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    deleteSponsorsFromTable: (sponsorData: ISponsor) => Promise<void>;
    refresh: boolean;
    pageSize: number;
    updateCurrentPage: (currentPage: number) => void;
    updatePageSize: (pageSize: number) => void;
    showSpinner: boolean;
    currentPage: number;
    isChartReady: {
        sponsorsIcpGraphReady?: boolean;
        sponsorsFundingAmountGraphReady?: boolean;
        sponsorsRevenueGraphReady?: boolean;
    };
    sponsorsIcpData: any;
    sponsorFundingData: any;
    sponsorRevenueData: any;
    trendData: any;
    editSponsorFromTable: any;
    rows: ISponsor[];
    setRows: React.Dispatch<React.SetStateAction<ISponsor[]>>;
    showDeletePopup: boolean;
    selectedSponsor: ISponsor | null;
    setSelectedSponsor: React.Dispatch<React.SetStateAction<ISponsor | null>>;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    cardView?: boolean;
    isTableView?: boolean;
    orgLink: string;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const SponsorsPageContext = createContext<SponsorsPageContextProps>({
    isEmpty: true,
    sponsorDataCount: 0,
    sponsors: [],
    setRefresh: () => {},
    deleteSponsorsFromTable: async () => {},
    refresh: false,
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    currentPage: 0,
    isChartReady: {
        sponsorsIcpGraphReady: false,
        sponsorsFundingAmountGraphReady: false,
        sponsorsRevenueGraphReady: false,
    },
    sponsorsIcpData: [],
    sponsorFundingData: [],
    sponsorRevenueData: [],
    trendData: null,
    editSponsorFromTable: async () => {},
    rows: [],
    setRows: () => {},
    showDeletePopup: false,
    selectedSponsor: null,
    setSelectedSponsor: () => {},
    setShowDeletePopup: () => {},
    cardView: false,
    isTableView: false,
    orgLink: '',
    setCurrentUrl: () => {}

});

const SponsorsPageProvider: React.FC<{ children: React.ReactNode; eventId?: string | number; cardView?: boolean }> = ({ children, eventId, cardView }):React.JSX.Element => 
{

    const {
        orgDetails
    } = useContext(UserContext);

    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [sponsorDataCount, setSponsorDataCount] = useState(0);
    const [sponsors, setSponsors] = useState<ISponsor[]>([]);
    const [rows, setRows] = useState<ISponsor[]>([]);
    const [sponsorGroupData, setSponsorGroupData] = useState<EventSponsorTier[]>([]);
    const [refresh, setRefresh] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [isChartReady, setChartReady] = useState<{
        sponsorsIcpGraphReady?: boolean,
        sponsorsFundingAmountGraphReady?: boolean,
        sponsorsRevenueGraphReady?: boolean
    }>({
        sponsorsIcpGraphReady: false,
        sponsorsFundingAmountGraphReady: false,
        sponsorsRevenueGraphReady: false
    });
    const [sponsorsIcpData, setSponsorsIcpData] = useState<{ name: string; percentage: string | number; value: string | number; }[]>([]);
    const [sponsorFundingData, setSponsorFundingData] = useState<{ name: string; percentage: string | number; value: string | number; }[]>([]);
    const [sponsorRevenueData, setSponsorRevenueData] = useState<{ name: string; percentage: string | number; value: string | number; }[]>([]);
    const [trendData, setTrendData] = useState<{[key: string]: string | number} | number | null>(null);
    const [selectedSponsor, setSelectedSponsor] = useState<ISponsor | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    // const [orgLink, setOrgLink] = useState<string>(orgDetails?.link as string);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const currentpath = useLocation().pathname;
    const isTableView = ((currentpath?.includes('sponsors') && eventId && !cardView)) as boolean;

    const fetchData = async ():Promise<void> => 
    {
        try 
        {
            const count = await sponsorsCount();
          
            setShowSpinner(true);
            if (count) 
            {
                setIsEmpty(count == 0);
                try 
                {
                    const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                    const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                    const sponsorsData = await getAllSponsors(pageSize, currentPage - 1, encodedParams);
                    if (sponsorsData) 
                    {
                        setSponsorDataCount(count);
                        setSponsors([...sponsorsData]);
                        setRows([...sponsorsData]);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally 
        {
            setShowSpinner(false);
        }
    };

    const fetchPaginationData = async ():Promise<void> => 
    {
        setShowSpinner(true);
        if (sponsorDataCount) 
        {
            try 
            {
                const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const sponsorsData = await getAllSponsors(pageSize, currentPage - 1, encodedParams);
                if (sponsorsData) 
                {
                    if (sponsorsData) 
                    {
                        // setRows([...sponsorsData]);
                        setSponsors([...sponsorsData]);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }

            }
            catch (error) 
            {
                console.log(error);
                setShowSpinner(true);
            }
            finally 
            {
                setShowSpinner(false);
            }
        }
    };

    const fetchSponsorsGraphData = async ():Promise<void> => 
    {
        try 
        {
            const graphData = await getSponsorGraphData();
           
            if (graphData) 
            {
                if (graphData.icp) 
                {
                    const icpsum = _.sumBy(_.values(graphData.icp));
                    const icpData = Object.entries(graphData.icp)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name,
                                percentage: Math.round((Number(value) / icpsum) * 100),
                                value: String(value),
                            }; 
                        });
                    setSponsorsIcpData(icpData);
                }
                if (graphData.funding) 
                {
                    const fundingSum = _.sumBy(_.values(graphData.funding));
                    const fundingData = Object.entries(graphData.funding)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / fundingSum) * 100),
                                value: String(value)
                            }; 
                        });
                    setSponsorFundingData(fundingData);
                }
                if (graphData.arr) 
                {
                    const revenueSum = _.sumBy(_.values(graphData.arr));
                    const revenueData = Object.entries(graphData.arr)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: name.toUpperCase(),
                                percentage: Math.round((Number(value) / revenueSum) * 100),
                                value: String(value)
                            }; 
                        });

                    setSponsorRevenueData(revenueData);
                }

                if (import.meta.env.VITE_NODE_ENV === 'demo' && graphData?.trend) 
                {
                    setTrendData(graphData?.trend);
                }

                setChartReady({
                    ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
                });
            }
            else 
            {
                setChartReady({
                    ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
            setChartReady({
                ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
            });
        }
    };

    // Event sponsor api calls
    const fetchEventSponsorData = async ():Promise<void> => 
    {
        try 
        {
            const count = await getEventSponsorsCount(eventId as string);
            setShowSpinner(true);
            if (count) 
            {
                setSponsorDataCount(count);
                setIsEmpty(count == 0);
                try 
                {
                    if(isTableView)
                    {
                        const params: [string, any][] = [];
                        const query = new URLSearchParams(currentUrl.split('?')[1]);
                        query?.forEach((value, key) => {
                            params.push([key, value]);
                        });

                        const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                        const eventSponsors = await getEventSponsors(pageSize, currentPage - 1, eventId as string, undefined, undefined, encodedParams);
                        const tierCount = await sponsorTierCount(eventId as string);
                        const tierData = await getAllSponsorTiers(tierCount, 0, eventId as string);
                        if (eventSponsors) 
                        {
                            setRows([...eventSponsors]);
                            setSponsors([...eventSponsors]);
                            setSponsorGroupData([...tierData]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                    else
                    {
                        const eventSponsors = await getEventSponsors(8, 0, eventId as string);
                        const tierCount = await sponsorTierCount(eventId as string);
                        const tierData = await getAllSponsorTiers(tierCount, 0, eventId as string);
                        if (eventSponsors) 
                        {
                            setRows([...eventSponsors]);
                            setSponsors([...eventSponsors]);
                            setSponsorGroupData([...tierData]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchEventSponsorPaginationData = async ():Promise<void> => 
    {
        if (sponsorDataCount) 
        {
            setShowSpinner(true);
            try 
            {
                const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const eventSponsors = await getEventSponsors(pageSize, currentPage - 1, eventId as string, undefined, undefined, encodedParams);
                const tierCount = await sponsorTierCount(eventId as string);
                const tierData = await getAllSponsorTiers(tierCount, 0, eventId as string);
                if (eventSponsors) 
                {
                    setRows([...eventSponsors]);
                    setSponsors([...eventSponsors]);
                    setSponsorGroupData([...tierData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
            finally 
            {
                setShowSpinner(false);
            }
        }
    };

    const fetchEventSponsorsGraphData = async ():Promise<void> => 
    {
        try 
        {
            const graphData = await getEventSponsorGraphData(eventId as string);
            if (graphData) 
            {
                if (graphData.icp) 
                {
                    const icpsum = _.sumBy(_.values(graphData.icp));
                    const icpData = Object.entries(graphData.icp)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            value: string | number;
                            percentage: string;
                        } => 
                        {
                            return {
                                name,
                                value,
                                percentage: ((Number(value) / icpsum) * 100).toFixed(1),
                            }; 
                        });
                    setSponsorsIcpData(icpData);
                }
                if (graphData.funding) 
                {
                    const fundingSum = _.sumBy(_.values(graphData.funding));
                    const fundingData = Object.entries(graphData.funding)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            value: string | number;
                            percentage: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                value,
                                percentage: ((Number(value) / fundingSum) * 100).toFixed(1),
                            }; 
                        });
                    setSponsorFundingData(fundingData);
                }
                if (graphData.arr) 
                {
                    const revenueSum = _.sumBy(_.values(graphData.arr));
                    const revenueData = Object.entries(graphData.arr)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            value: string | number;
                            percentage: string;
                        } => 
                        {
                            return {
                                name: name.toUpperCase(),
                                value,
                                percentage: ((Number(value) / revenueSum) * 100).toFixed(1),
                            }; 
                        });

                    setSponsorRevenueData(revenueData);
                }

                if (import.meta.env.VITE_NODE_ENV === 'demo' && graphData?.trend) 
                {
                    setTrendData(graphData?.trend);
                }

                setChartReady({
                    ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
                });
            }
            else 
            {
                setChartReady({
                    ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
            setChartReady({
                ...isChartReady, sponsorsIcpGraphReady: true, sponsorsFundingAmountGraphReady: true, sponsorsRevenueGraphReady: true 
            });
        }
    };

    // const fetchOrgData = async (): Promise<void> =>
    // {
    //     const org = LocalStorage.get('@Org');
    //     if(org)
    //     {
    //         setOrgLink(org?.link);
    //     }
    //     else
    //     {
    //         try 
    //         {
    //             const orgData = await orgMe();
    //             if(orgData)
    //             {
    //                 setOrgLink(orgData?.link);
    //             }
    //         } 
    //         catch (error) 
    //         {
    //             console.log(error);
    //         }
    //     }
    // };

    const editSponsorFromTable = async (sponsorData):Promise<void> => 
    {
        const sponsorDetails = {
            name: sponsorData.name ? sponsorData.name : '',
            pocName: sponsorData.pocName ? sponsorData?.pocName : '',
            pocEmail: sponsorData.pocEmail ? sponsorData?.pocEmail : '',
            websiteLink: sponsorData.websiteLink ? sponsorData.websiteLink : '',
            social: {
                linkedIn: sponsorData?.social?.linkedIn ? sponsorData.social.linkedIn : '',
                twitter: sponsorData?.social?.twitter ? sponsorData.social.twitter : '',
            }
        };

        try 
        {
            const sponsorUpdated = await updateSponsor(sponsorData.id, sponsorDetails);
            if (sponsorUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const deleteSponsorsFromTable = async (sponsorData: ISponsor): Promise<void> => 
    {
        if (eventId)
        {
            try 
            {
                const sponsorDeleted = await removeEventSponsor(eventId as string, sponsorData?.id as string);
                if (sponsorDeleted) 
                {
                    toast.success(`Sponsor ${sponsorData.name} deleted successfully`);
                    setShowDeletePopup(false);
                    setSelectedSponsor(null);
                    setRefresh(true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error(`${error?.message}`);
            }
        }
        else
        {
            try 
            {
                const sponsorDeleted = await deleteSponsor(sponsorData?.id);
                if (sponsorDeleted) 
                {
                    toast.success(`Sponsor ${sponsorData.name} deleted successfully`);
                    setSelectedSponsor(null);
                    setShowDeletePopup(false);
                    setRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error(`${error?.message}`);
            }           
        }
    };

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            setShowSpinner(true);
            if (eventId)
            {
                fetchEventSponsorData();
                fetchEventSponsorsGraphData();
            }
            else
            {
                fetchData();
                fetchSponsorsGraphData();
            }
            
        }
        else if (currentPage > 1 && pageSize) 
        {
            setShowSpinner(true);
            if (eventId)
            {
                fetchEventSponsorPaginationData();
            }
            else
            {
                fetchPaginationData();
            }
        }

        // fetchOrgData();
    }, [currentPage, pageSize, currentUrl]);

    useEffect(():void => 
    {
        if (refresh) 
        {
            if (eventId)
            {
                fetchEventSponsorData();
                fetchEventSponsorsGraphData();
            }
            else
            {
                fetchData();
                fetchSponsorsGraphData();
            }
        }
    }, [refresh]);

    // useEffect(():void =>
    // {
    //     if(sponsors)
    //     {
    //         fetchSponsorsGraphData();
    //     }
    // },[sponsors]);

    // const UpdatedDataWithDecompressedImage = async (data) => {
    //     if (data) {
    //         const updatedData = await Promise.all(data.map(async (element) => {
    //             if (element.logo) {
    //                 element.logo = await decompressImage(element.logo, 1500, 1500);
    //             }
    //             return element;
    //         }));

    //         return updatedData;
    //     }
    // };

    return (
        <SponsorsPageContext.Provider 
            value={{
                isEmpty, 
                sponsorDataCount, 
                sponsors, 
                setRefresh, 
                deleteSponsorsFromTable, 
                refresh, 
                pageSize, 
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                currentPage, 
                isChartReady, 
                sponsorsIcpData, 
                sponsorFundingData, 
                sponsorRevenueData, 
                trendData, 
                editSponsorFromTable,
                rows,
                setRows,
                showDeletePopup,
                selectedSponsor,
                setSelectedSponsor,
                setShowDeletePopup,
                cardView,
                isTableView,
                sponsorGroupData,
                orgLink: orgDetails?.link as string,
                setCurrentUrl 
            }}
        >
            {children}
        </SponsorsPageContext.Provider>
    );
};

export default SponsorsPageProvider;