import React, { useContext, useState } from "react";
import { CouponsContext } from "../../../../contexts/EventCoupons/EventCouponsContext";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddCoupon from "../../../../components/Events/Tickets/AddCoupon";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { EventCoupon } from "../../interfaces/event-coupon_interface";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import { ActionsDropdown } from "../../EventBudget/EventBudgetDataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyCouponComponent from "../../../../components/Events/Tickets/CopyCouponComponent";
import { Grid, Tooltip } from "@mui/material";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../components/Events/interfaces/create-events_interface";
import { EventStatus } from "../../enum";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";
import toast from "react-hot-toast";
import HeaderBar from "../../../../common/Headerbar";
import DeletePopup from "../../../../common/DeletePopup";
import EventsCardHeaderComponent from "../../../../components/Events/EventComponents/EventsCardHeaderComponent";
import TanstackToolbarWithServerFilter from "../../../../common/TanstackTable/TanstackToolbarWithServerFilter";
import { UserContext } from "../../../../contexts/UserContext";
import { UserViewType } from "../../../Settings/enum/users.enum";
import { MinimalViewComponent } from "../../../../common/MinimalView/MinimalViewComponent";
import ColumnVisibilityDropdown from "../../../../common/TanstackTable/ColumnVisibilityDropdown";

import './styles.scss';

const CouponsPage: React.FC = (): React.JSX.Element =>
{
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const {
        userDetails
    } = useContext(UserContext);

    const { 
        eventId, 
        couponCount, 
        coupons,
        rows,
        isEmpty, 
        setRefresh, 
        showSpinner, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        deleteCouponFn, 
        orgData, 
        eventLink, 
        isTableView,
        currentPage,
        selectedCoupon,
        setSelectedCoupon,
        setShowDeletePopup,
        showDeletePopup,
        setCurrentUrl 
    } = useContext(CouponsContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const columnHelper = createColumnHelper<EventCoupon>();
    const couponColumns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('couponsCount', {
            cell: (row) => {
                return (<p className="cellContent">{`${row.row.original?.couponsCount}/${row.row.original?.totalCouponsAvailable}`}</p>)
            },
            header: 'Coupon Uses',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'couponsCount'
        }),
        columnHelper.accessor('discount' as any, {
            cell: (row) => {
                const discount = row.row.original?.couponValue && row.row.original?.couponValue !== 0 ? `₹${row.row.original?.couponValue}` : `${row.row.original?.couponPercentage}%`; 
                return (<p className="cellContent">{discount}</p>)
            },
            header: 'Discount',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'discount'
        }),
        columnHelper.accessor('tickets', {
            cell: (row) => {
                const ticketNames = row.row.original?.tickets && row.row.original?.tickets?.length > 0 ? row.row.original?.tickets?.map(ticket => ticket?.name).join(', ') : '';
                return (
                    <Tooltip arrow title={ticketNames} placement="top" disableInteractive>
                        <p className="bolded-cellContent">{ticketNames}</p>
                    </Tooltip>
                )
            },
            header: 'Ticket Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'tickets'
        }),
        columnHelper.accessor('couponStatus' as any, {
            cell: (row) => {
                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                let couponStatus, chipColor = '';
                if(Number(row.row.original?.couponCodeCloseDateTime) > currentTimestamp)
                {
                    couponStatus = 'Live';
                    chipColor = 'green';
                }
                else if(Number(row.row.original?.couponCodeCloseDateTime) < currentTimestamp)
                {
                    couponStatus = 'Expired';
                    chipColor = 'red';
                }
                else if(Number(row.row.original?.couponsCount) >= Number(row.row.original?.totalCouponsAvailable))
                {
                    couponStatus = 'Sold Out';
                    chipColor = 'yellow';
                }

                return (
                    <MuiChip circleIcon chipColor={chipColor as 'green' | 'red' | 'yellow'} label={couponStatus || ''} />
                )
            },
            header: 'Coupon Status',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'couponStatus'
        }),
        columnHelper.accessor('copyLink' as any, {
            cell: (row) => {
                return (
                    <>
                    {
                        eventReduxData?.status === EventStatus.PUBLISHED &&
                        <Tooltip title="Copy Coupon Code" disableInteractive>
                            <FontAwesomeIcon onClick={() => {
                                navigator.clipboard.writeText(`${row.row.original?.name}`);  
                                toast.success('Coupon code copied');
                            }} cursor={'pointer'} style={{ height: '16px', width: '16px' }} icon={['fal', 'copy']} />
                        </Tooltip>
                    }
                    </>
                );
            },
            header: '',
            size: 40,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'copyLink'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return actions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];
    
    const [allColumns, setAllColumns] = useState(couponColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Coupon Start Date',
            id: 'couponCodeStartDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Coupon End Date',
            id: 'couponCodeCloseDateTime',
            meta: {
                type: 'date'
            }
        },
    ];

    const customFilterFunctions = {
        discount: (obj: EventCoupon, inputValue: string | number): boolean => {
            const discount = obj?.couponValue && obj?.couponValue !== 0 ? `₹${obj?.couponValue}` : `${obj?.couponPercentage}%`;
            return discount.toLowerCase().includes(inputValue.toString().toLowerCase());
        },
        tickets: (obj: EventCoupon, inputValue: string | number): boolean => {
            const ticketNames = obj?.tickets && obj?.tickets?.length > 0 ? obj?.tickets?.map(ticket => ticket?.name).join(', ') : '';
            return ticketNames.toLowerCase().includes(inputValue.toString().toLowerCase());
        },
        couponStatus: (obj: EventCoupon, inputValue: string | number): boolean => { 
            const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
            let couponStatus, chipColor = '';
            if(Number(obj?.couponCodeCloseDateTime) > currentTimestamp)
            {
                couponStatus = 'Live';
                chipColor = 'green';
            }
            else if(Number(obj?.couponCodeCloseDateTime) < currentTimestamp)
            {
                couponStatus = 'Expired';
                chipColor = 'red';
            }
            else if(Number(obj?.couponsCount) >= Number(obj?.totalCouponsAvailable))
            {
                couponStatus = 'Sold Out';
                chipColor = 'yellow';
            }
            return couponStatus ? couponStatus?.toLowerCase().includes(inputValue.toString().toLowerCase()) : false;
        }
    };

    const actions = (row: EventCoupon): React.JSX.Element =>
    {

        const editClick = () =>
        {
            openDrawer(row);
        };

        const deleteClick = () =>
        {
            setSelectedCoupon(row);
            setShowDeletePopup(true);
        };

        const shareCouponUrl = () => 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                iconHeading: 'link',
                component: <CopyCouponComponent orgData={orgData} couponCode={row?.name || ''} eventLink={eventLink} />,
                componentWidth: '512px',
                componentHeight: '275px'
            });

            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                open: true
            });
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} extraActions={eventReduxData?.status === EventStatus.PUBLISHED && [{
            name: 'Share Coupon URL',
            onClick: shareCouponUrl
        }]} />;
    };

    const openDrawer = (existingCouponData?: EventCoupon) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: existingCouponData ? 'Update Coupon' : 'Create Coupon',
            hideCloseButton: true,
            component: <AddCoupon deleteCouponFn={deleteCouponFn} isTableView={isTableView} existingCouponData={existingCouponData} eventId={eventId || ''} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };
    
    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const renderNormalViewForCouponsPage = (): React.JSX.Element => {
        return (
            <div id="couponsPage">
                <HeaderBar 
                    title={<p><FontAwesomeIcon icon={['fal', 'arrow-left']} cursor="pointer" style={{ marginRight: '8px' }} onClick={() => navigate('/events/' + eventId + '/registrations')} /> Coupons</p>}
                    buttons={[
                        <CustomButton 
                            btnType="secondary"
                            name="Add Coupon" 
                            onClick={() => openDrawer()} 
                        />
                    ]}
                />

                <div style={{ 
                    flex: 1,
                    height: 'calc(100% - 60px)', 
                }}>
                    {isEmpty && coupons?.length === 0 ? (<TableEmptyComponent emptyImg={''} infoText={'No Existing Coupons'} buttonName={'Add Coupon'} />) : 
                    (
                        <div className="h-100">
                            {/* <TanstackToolbar 
                                columns={couponColumns}
                                setColumns={setAllColumns}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: couponCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                                rows={coupons}
                                setRows={setRows}
                                customFilterFunctions={customFilterFunctions}
                            /> */}
                            <TanstackToolbarWithServerFilter 
                                columns={couponColumns}
                                setColumns={setAllColumns}
                                setCurrentUrl={setCurrentUrl}
                                tabs={[{
                                    tabName: 'All',
                                    count: couponCount as number,
                                    navigation: () => {},
                                    selectedTab: true
                                }]}
                                filterColumns={filterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={coupons}
                                rowCount={couponCount}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                height='calc(100% - 124px)'
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => openDrawer(row)}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    };

    const renderCardViewForCouponsPage = (): React.JSX.Element => { 
        return (
            <div id="couponsPage">
                <div className="coupons-container">

                    <EventsCardHeaderComponent heading="Coupons" count={couponCount} buttonAction={() => navigate('/events/' + eventId + '/coupons')} />

                    <Grid container spacing={2}>
                        {
                            coupons?.map((coupon: EventCoupon, index: number) => {

                                const header = coupon?.name;
                                const contentHeading = coupon?.tickets?.map((ticket) => ticket.name).join(', ');

                                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                                let couponStatus, chipColor = '';
                                if(Number(coupon?.couponCodeCloseDateTime) > currentTimestamp)
                                {
                                    couponStatus = 'Live';
                                    chipColor = 'green';
                                }
                                else if(Number(coupon?.couponCodeCloseDateTime) < currentTimestamp)
                                {
                                    couponStatus = 'Expired';
                                    chipColor = 'red';
                                }
                                else if(Number(coupon?.couponsCount) >= Number(coupon?.totalCouponsAvailable))
                                {
                                    couponStatus = 'Sold Out';
                                    chipColor = 'yellow';
                                }
                                const chip = (<MuiChip circleIcon chipColor={chipColor as 'red'|'yellow'|'green'} label={couponStatus || ''}  />);

                                const couponDiscount = coupon?.couponValue && coupon?.couponValue !== 0 ? `₹${coupon?.couponValue}` : `${coupon?.couponPercentage}% OFF`;
                                const couponUses = `${coupon?.couponsCount}/${coupon?.totalCouponsAvailable}`;

                                return (
                                    <Grid key={index} item xl={3} lg={4} md={4} sm={12} xs={12}>
                                        <CardComponent 
                                            header={header}
                                            headerBold
                                            contentHeading={contentHeading}
                                            chip={chip}
                                            footerLeft={couponDiscount}
                                            footerRight={couponUses}
                                            key={index}
                                            onClick={() => openDrawer(coupon)}
                                        />
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                            <CardComponent 
                                emptyText='+ Add Coupon'
                                onClick={() => { openDrawer() }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    };

    const renderMinimalViewForCouponsPage = (): React.JSX.Element => { 
        return (
            <div id="couponsPageMinimalView">
                <MinimalViewComponent
                    headerActions={[
                        eventId && <CustomButton key={1} name="Back" startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} btnType='tertiary' onClick={() => navigate('/events/' + eventId + '/registrations')} />,
                    ]}
                    title="Coupons"
                    description="Manage your Coupons and their account permissions here."
                    tabData={[
                        {
                            tabName: 'All',
                            count: couponCount as number,
                            navigation: () => { },
                            selectedTab: true
                        }
                    ]}
                    actions={[
                        !isEmpty && <TanstackToolbarWithServerFilter
                            key={1}
                            columns={couponColumns}
                            filterColumns={filterColumns}
                            handleApplyFilters={handleApplyFilters}
                            setCurrentUrl={setCurrentUrl}
                            minimalView={true}
                        />,
                        !isEmpty && <ColumnVisibilityDropdown 
                            key={2}
                            columns={couponColumns}
                            setColumns={setAllColumns}
                            showIconButton={true}
                        />,
                        <CustomButton
                            key={2}
                            name={<FontAwesomeIcon icon={['fal', 'plus']} />}
                            style={{ height: '38px', maxHeight: '38px', width: '38px' }}
                            btnType='primary'
                            onClick={() => openDrawer()}
                        />
                    ]}
                    component={
                        <div className="minimalView-table-container">
                            {
                                isEmpty ?
                                    <TableEmptyComponent
                                        emptyImg={''}
                                        openDrawer={openDrawer}
                                        infoText={'No Coupons'}
                                        subInfoText={'Create your first Coupon'}
                                    />
                                    :
                                    <TanstackTable
                                        data={coupons}
                                        initialColumns={allColumns}
                                        rowCount={couponCount as number}
                                        pageSize={pageSize}
                                        currentPage={currentPage - 1}
                                        updatePageSize={updatePageSize}
                                        updateCurrentPage={updateCurrentPage as any}
                                        height='calc(100vh - 380px)'
                                        showSpinner={showSpinner}
                                        rightPinnedColumns={['actions']}
                                        onRowClick={(row) => openDrawer(row)}
                                    />
                            }
                        </div>
                    }
                    componentType='table'
                />
            </div>
        )
    };

    return (
        <>
            {
                isTableView && userDetails?.viewType === UserViewType.NORMAL && renderNormalViewForCouponsPage()
            }

            {
                isTableView && userDetails?.viewType === UserViewType.MINIMAL && renderMinimalViewForCouponsPage()
            }

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteCouponFn(selectedCoupon as EventCoupon)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedCoupon(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedCoupon?.name}?`}
                        modalTitle='Delete Coupon'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }

            {!isTableView && renderCardViewForCouponsPage()}
        </>
    )
};

export default CouponsPage;