import { configureStore } from '@reduxjs/toolkit';

import audience from './audience/Audience';
import events from './events/Events';
import speakers from './speakers/Speakers';
import sponsors from './sponsors/Sponsors';
import general from './general/General';
import organization from './organization/Organization';
import registrationDetails from './events/eventRegistrations/RegistrationDetails';
import tickets from './events/eventRegistrations/Tickets';
import vendors from './vendors/Vendors';
import expo from './events/eventVenue/EventExpo';
import buyerPersona from './settings/BuyerPersona';
import teams from './settings/Teams';
import users from './settings/Users';
import icp from './settings/ICP';
import stage from './events/eventVenue/EventStage';
import sessions from './events/eventVenue/EventSessions';
import schedule from './events/eventVenue/EventSchedule';
import registrationForms from './registrationsForms/RegistrationForms';
import formComponents from './registrationsForms/FormComponents';
import formComponentDisplayField from './registrationsForms/FormComponentDisplayField';
import refreshBudgetDetails from './events/eventBudget/EventBudget';
import EventsData from './events/EventsData';
import csrfTokenValue from './authToken';
import marketing from './events/eventMarketing/marketing';
import marketingSub from './events/eventMarketing/marketingSub';
import paginationData from './paginationData';
import billingDetails from './billing/billingInfo';
import eventTargetList from './events/eventTargetList/targetList';


export const store = configureStore({
    reducer: {
        audience: audience,
        events: events,
        eventsData: EventsData,
        eventBudget: refreshBudgetDetails,
        eventTargetList: eventTargetList,
        speakers: speakers,
        sponsors: sponsors,
        icp: icp,
        buyerPersona: buyerPersona,
        teams: teams,
        users: users,
        general: general,
        organization: organization,
        registrationDetails: registrationDetails,
        tickets: tickets,
        vendors: vendors,
        expo: expo,
        stage: stage,
        sessions: sessions,
        schedule: schedule,
        registrationForms: registrationForms,
        formComponents: formComponents,
        formComponentDisplayField: formComponentDisplayField,
        csrfTokenValue: csrfTokenValue,
        marketing: marketing,
        marketingSub: marketingSub,
        paginationData: paginationData,
        billingDetails: billingDetails
    }
});
