import { Box } from '@mui/material';
import React, { } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import eventBus from '../../scripts/event-bus';
import Overview from './EventDetails/Overview/Overview';
//import Analytics from './EventDetails/Analytics/Main/Analytics';
import EventDetailsBar from './EventDetails/EventDetailsBar';
import './styles.scss';
//import { EventsInterface } from './interfaces';
import { useSelector } from 'react-redux';
import EventBudget from './EventBudget/EventBudgetMain';
import EventMarketingProvider from '../../contexts/EventMarketing/EventMarketingContext';
import MarketingPage from './EventDetails/Marketing/MarketingPage';
import EventRegistrationDetails from './EventDetails/Registrations/EventRegDetails';
import UtmComponent from './EventDetails/Registrations/EventShareLink/UTMTable';
import { IEventReduxValues, IEventsDispatch } from '../../components/Events/interfaces/create-events_interface';
import TicketsPageProvider from '../../contexts/EventDetailsRegistration/TicketsPageContext';
import Ticket from './EventDetails/Registrations/Tickets/Ticket';
import SideBarTabsNavigation from '../../common/SideBarTabsNavigation/SideBarTabsNavigation';
import EventAccessControlProvider from '../../contexts/EventAccessControl/EventAccessControlContext';
import EventAccessControlPage from './EventDetails/Accesscontrol/EventAccessControlPage';
import EventStageProvider from '../../contexts/EventStage/EventStageContext';
import StagePage from './EventDetails/Stage/StagePage';
import PhotosPage from './EventDetails/PhotoSharing/PhotosPage';
import EventPhotoSharingProvider from '../../contexts/EventPhotoSharing/EventPhotoSharingContext';
import CouponsPage from './EventDetails/EventTicketCoupons/CouponsPage';
import CouponsProvider from '../../contexts/EventCoupons/EventCouponsContext';
import BasicRegistrationsPage from './EventDetails/Registrations/Basic/BasicRegistrationsPage';
import EventAnalyticsPage from './EventDetails/EventAnalytics/pages/EventAnalyticsPage';
import { IBillingInfo } from '../../redux/billing/billing-redux_interface';
import SpeakersProvider from '../../contexts/SpeakersPageContext';
import SpeakersPage from '../Speakers/SpeakersPage';
import SponsorsPageProvider from '../../contexts/SponsorsPageContext';
import SponsorsPage from '../Sponsors/SponsorsPage';
import VendorsProvider from '../../contexts/VendorsPageContext';
import VendorsPage from '../Vendors/VendorsPage';
import EventRegistrantsPage from './EventDetails/People/EventRegistrants/EventRegistrantsPage';
import EventTargetListPage from './EventDetails/TargetList/EventTargetListPage';
import EventTargetListProvider from '../../contexts/EventTargetList/EventTargetListContext';
import CreateEventTargetListPage from './EventDetails/TargetList/CreateEventTargetListPage';
import EventTargetListAudiencePage from './EventDetails/TargetList/EventTargetListAudiencePage';

const EventsInfo = (props): React.JSX.Element =>         
{  

    const secondaryNavBar = props['secondary-navbar'];
    const navBarAndTopBar = props['navabar-topbar'];

    const currentPath = useLocation().pathname;

    const navigate = useNavigate();

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const { eventId, listId } = useParams();
    const pageData = props.data;
    
    const isEventOverviewComponent = pageData === 'overview';
    const isMarketingComponent = pageData === 'marketing';
    const isEventRecordingsComponent = pageData === 'recording';
    const isEventAnalyticsComponent = pageData === 'analytics';
    const isEventBudgetComponent = pageData === 'budget';
    const isEventRegistrationComponent = pageData === 'details';
    const isCouponsComponent = pageData === 'coupons';
    const isUtmComponent = pageData === 'utm';
    const isTicketsComponent = pageData === 'tickets';
    const isRegistrantsComponent = pageData === 'registrants';
    const isEventSponsorComponent = pageData === 'sponsors';
    const isEventSpeakerComponent = pageData === 'speakers';
    const isEventVendorComponent = pageData === 'vendors';
    const isAccessControlComponent = pageData === 'accessControl';
    const isEventAgendaComponent = pageData === 'agenda';
    // const isLeadCaptureComponent = pageData === 'leadCapture';
    const isEventTargetListComponent = pageData === 'targetList';  
    const isCreateEventTargetListComponent = pageData === 'createTargetList';
    const isEventTargetListAudienceComponent = pageData === 'targetListAudience'
    const isPhotoSharingComponent = pageData === 'photoSharing';

    const isRegistrationsComponent = pageData === 'registrations';


    eventBus.dispatch('event-details-bar', {
        heading: eventReduxData.title,
        eventStatus: eventReduxData.status,
        backBtn: true,
    });

    return (
        <Box id="eventsInfo">
            {!navBarAndTopBar && <EventDetailsBar eventId={Number(eventId)} secondaryNavbar={secondaryNavBar} tab={props.tab} subTab={props.subTab} />}
            {/* <Box> */}
            
            <Box className="w-100 content-box" sx={{
                display: 'flex', flexDirection: 'row' 
            }}>
                {!secondaryNavBar && !navBarAndTopBar &&
                 <Box className="side-nav-bar">
                     <SideBarTabsNavigation
                      navigationBlocks={[
                        {
                            title: 'Overview',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/overview'); 
                            },
                            selected: ['overview', 'speakers', 'sponsors', 'vendors'].some(path => currentPath?.includes(path)),
                            //   icon: 'rectangle-history'
                        },
                        // {
                        //     title: 'Forms',
                        //     navigation: ():void => 
                        //     {
                        //         return navigate('/events/' + eventId + '/registrations/details'); 
                        //     },
                        //     selected: currentPath === '/events/' + eventId + '/registrations/details',
                        //     disabled: eventReduxData.type !== 1,
                        //     //   icon: 'file-user'
                        // },
                        // {
                        //     title: 'Tickets',
                        //     navigation: ():void => 
                        //     {
                        //         return  navigate('/events/' + eventId + '/tickets'); 
                        //     },
                        //     selected: currentPath?.includes('tickets') || currentPath?.includes('coupons') || currentPath?.includes('share-links'),
                        //     disabled: eventReduxData.type !== 1,
                        //     //   icon: 'ticket-perforated'
                        // },
                        {
                            title: 'Registrations',
                            navigation: ():void => 
                            {
                                return  navigate('/events/' + eventId + '/registrations'); 
                            },
                            selected: (['registrations', 'tickets', 'coupons', 'share-links'].some(path => currentPath?.includes(path)) && !currentPath?.includes('analytics')),
                            disabled: eventReduxData.type !== 1,
                        },
                        {
                            title: 'Agenda',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/agenda'); 
                            },
                            selected: currentPath === '/events/' + eventId + '/agenda',
                        },
                        {
                            title: 'Registrants',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/people/registrants'); 
                            },
                            selected: currentPath.startsWith('/events/' + eventId + '/people/registrants'),
                            //   icon: 'address-card'
                        },
                        // {
                        //     title: 'Sponsors',
                        //     navigation: ():void => 
                        //     {
                        //         return navigate('/events/' + eventId + '/people/sponsors'); 
                        //     },
                        //     selected: currentPath === '/events/' + eventId + '/people/sponsors',
                        //     disabled: eventReduxData.type !== 1,
                        //     //   icon: 'sack-dollar'
                        // },
                        // {
                        //     title: 'Speakers',
                        //     navigation: ():void => 
                        //     {
                        //         return navigate('/events/' + eventId + '/people/speakers'); 
                        //     },
                        //     selected: currentPath === '/events/' + eventId + '/people/speakers',
                        //     disabled: eventReduxData.type !== 1,
                        //     //   icon: 'microphone'
                        // },
                        // {
                        //     title: 'Vendors',
                        //     navigation: ():void => 
                        //     {
                        //         return navigate('/events/' + eventId + '/people/vendors'); 
                        //     },
                        //     selected: currentPath === '/events/' + eventId + '/people/vendors',
                        //     //   icon: 'users'
                        // },
                        {
                            title: 'Budget',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/budget'); 
                            },
                            selected: currentPath === '/events/' + eventId + '/budget',
                            //   icon: 'wallet'
                        },
                        {
                            title: 'Marketing',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/marketing'); 
                            },
                            selected: currentPath === '/events/' + eventId + '/marketing',
                            //   icon: 'megaphone'
                        },
                        {
                            title: 'Analytics',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/analytics/revenue'); 
                            },
                            selected: currentPath?.includes('analytics'),
                            //   icon: 'chart-simple'
                        },
                        ...(orgBillingInfo?.billingModules?.checkInApp ? [{
                            title: 'Check In App',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/access-control'); 
                            },
                            selected: currentPath === '/events/' + eventId + '/access-control',
                            //   icon: 'chart-simple'
                        }] : []),
                        // {
                        //     title: 'Connect',
                        //     navigation: ():void => 
                        //     {
                        //         return navigate('/events/' + eventId + '/connect'); 
                        //     },
                        //     selected: currentPath === '/events/' + eventId + '/connect',
                        //     //   icon: 'chart-simple'
                        // },
                        {
                            title: 'Target List',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/target-list'); 
                            },
                            selected: currentPath?.includes('target-list'),
                        },
                        {
                            title: 'Photo Sharing',
                            navigation: ():void => 
                            {
                                return navigate('/events/' + eventId + '/photo-sharing'); 
                            },
                            selected: currentPath?.includes('photo-sharing'),
                            //   icon: 'chart-simple'
                        },
                      ]}
                  />
                 </Box>
                }
                {isEventOverviewComponent && <Overview eventId={Number(eventId)} />}
                {isEventRegistrationComponent && eventReduxData.type === 1 ? <EventRegistrationDetails eventReduxData={eventReduxData} eventId={Number(eventId)} /> : null}
                {isMarketingComponent && <EventMarketingProvider eventId={Number(eventId)} ><MarketingPage /></EventMarketingProvider>}
                {isEventAnalyticsComponent && <EventAnalyticsPage eventId={eventId} />}
                {isEventBudgetComponent && <EventBudget data-id={eventId} />}
                {isUtmComponent && <UtmComponent eventId={eventId} eventLink={eventReduxData['link'] ? eventReduxData['link'] : eventReduxData.link ? eventReduxData.link : null} />}
                {isCouponsComponent && <CouponsProvider eventId={eventId}><CouponsPage /></CouponsProvider>}
                {isTicketsComponent && <TicketsPageProvider eventId={Number(eventId)} ><Ticket eventReduxData={eventReduxData} eventId={Number(eventId)} /></TicketsPageProvider>}
                {/* {isRegistrantsComponent && <Registrants reduxData={eventReduxData} data-id={eventId} />} */}
                {isRegistrantsComponent && <EventRegistrantsPage eventId={eventId as string} />}
                {(isEventSponsorComponent && orgBillingInfo?.billingModules?.sponsor) && <SponsorsPageProvider cardView={false} eventId={eventId}><SponsorsPage eventId={eventId as string} /> </SponsorsPageProvider>}
                {/* {isEventSpeakerComponent && <EventSpeakersProvider eventId={eventId} ><Speakers reduxData={eventReduxData} eventId={eventId} /></EventSpeakersProvider>} */}
                {isEventSpeakerComponent && <SpeakersProvider eventId={eventId} cardView={false} ><SpeakersPage  eventId={eventId} /></SpeakersProvider>}
                {(isEventVendorComponent && orgBillingInfo?.billingModules?.vendor) && <VendorsProvider cardView={false} eventId={eventId}><VendorsPage eventId={eventId} /></VendorsProvider>}
                {(isAccessControlComponent && orgBillingInfo?.billingModules?.checkInApp) && <EventAccessControlProvider eventId={eventId}><EventAccessControlPage /></EventAccessControlProvider>}
                {isEventAgendaComponent && <EventStageProvider eventId={eventId} eventReduxData={eventReduxData}><StagePage /></EventStageProvider>}
                {isEventTargetListComponent && <EventTargetListProvider eventId={eventId || ''} ><EventTargetListPage /> </EventTargetListProvider>}
                {isCreateEventTargetListComponent && <EventTargetListProvider eventId={eventId || ''} ><CreateEventTargetListPage /> </EventTargetListProvider>}
                {isEventTargetListAudienceComponent && <EventTargetListAudiencePage eventId={eventId || ''} listId={listId || ''} /> }
                {isPhotoSharingComponent && <EventPhotoSharingProvider eventId={eventId || ''} ><PhotosPage /></EventPhotoSharingProvider>}
                {/* {(isUtmComponent || isTicketsComponent || isCouponsComponent) ? <TicketsMain eventId={eventId || ''} /> : null} */}

                {/* Basic plan registrations section */}
                {isRegistrationsComponent && <BasicRegistrationsPage eventId={eventId || 0} />}
            </Box>
            {/* </Box> */}
        </Box>
    );
};
export default EventsInfo;