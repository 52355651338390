import { useEffect, useMemo, useRef, useState } from "react";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { CheckoutStep } from "./Pages/TargetListByPastEventsPage";

interface StepperProps {
    props: CheckoutStep[];
}

const Stepper :React.FC<StepperProps> = ({ props = [] }) =>
{
    const [currentStep, setCurrentStep] = useState(1);
    const [isComplete, setIsComplete] = useState(false);
    const [margins, setMargins] = useState({
        marginLeft: 0,
        marginRight: 0,
    });
    const stepRef = useRef([]);

    useEffect(() =>
    {
        setMargins({
            marginLeft: stepRef.current[0].offsetWidth / 2,
            marginRight: stepRef.current[props.length - 1].
                offsetWidth / 2,
        });
    }, [stepRef, props.length]);

    if (!props.length) {
        return null; 
    }

    const handleBack = () => {
        setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
        setIsComplete(false);
    };

    const handleNext = () =>
    {
        setCurrentStep((prevStep) =>
        {
            if (prevStep === props.length)
            {
                if (props[prevStep - 1]?.onComplete) {
                    props[prevStep - 1].onComplete(); 
                }  
                setIsComplete(true);
        
                return prevStep;
            } else
            {
                return prevStep + 1;
            }
        });
    };

    const calculateProgressBarWidth = () =>
    {
        return ((currentStep - 1) / (props.length - 1)) * 100;
    };

    const ActiveComponent = useMemo(() => {
        return props[currentStep - 1]?.Component;
    }, [currentStep, props]);

    return (
        <div id="switchStepper">
            <>
                <div className="stepper">
                    {props.map((prop, index) => {
                        return (
                            <div
                                key={prop.name}
                                ref={(el) => (stepRef.current[index] = el)}
                                className={`step ${
                                    currentStep > index + 1 || isComplete ? "complete" : ""
                                } ${currentStep === index + 1 ? "active" : ""} `}
                            >
                                <div className="step-number">
                                    {currentStep > index + 1 || isComplete ? (
                                        <span>&#10003;</span>
                                    ) : (
                                        <div className="inner-circle"></div>
                                    )}
                                </div>
                                <div className="step-name">{prop.name}</div>
                            </div>
                        );
                    })}

                    <div
                        className="progress-bar"
                        style={{
                            width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
                            marginLeft: margins.marginLeft,
                            marginRight: margins.marginRight,
                        }}
                    >
                        <div
                            className="progress"
                            style={{ width: `${calculateProgressBarWidth()}%` }}
                        ></div>
                    </div>
                </div>

                <ActiveComponent />
            </>

            {!isComplete && (
                <div className="switch-footer">
                    <CustomButton
                        name="Back"
                        btnType={"secondary"}
                        onClick={handleBack}
                        disabled={currentStep === 1}
                        style={{ marginRight: "auto" }}
                    />
                    <CustomButton
                        name={currentStep === props.length ? "Finish" : "Next"}
                        btnType={"primary"}
                        onClick={handleNext}
                    />
                </div>
            )}
        </div>
    );
};

export default Stepper;
