import { createSlice } from '@reduxjs/toolkit';

export const eventTargetListSlice = createSlice({
    name: 'eventTargetList',
    initialState: {
        value: {
            filter: '',
            name: '',
            description: '',
            type: '',
            eventId: '',
            audienceIds: '',
            source: '',
        }
    },
    reducers: {
        addTargetList: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetTargetList: (state):void => 
        {
            state.value = {
                filter: '',
                name: '',
                description: '',
                type: '',
                eventId: '',
                audienceIds: '',
                source: '',
            };
        }
    },
});

export const { addTargetList, resetTargetList } = eventTargetListSlice.actions;
export default eventTargetListSlice.reducer;