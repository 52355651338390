import { library } from '@fortawesome/fontawesome-svg-core';
import { faClapperboard, faCalendar, faUserGroup, faMicrophone, faBell, faMessage, faHandHoldingDollar, faChartPieSimple, faFile, faHexagon, faDollarSign, faMagnifyingGlass, faChevronDown, faUserPlus, faGearComplex, faArrowRightFromBracket, faXmark, faFileImport, faPlus, faUpload, faEnvelope, faEye, faEyeSlash, faEllipsisVertical, faChevronRight, faTrash, faGripDotsVertical, faVideo, faBullhorn, faInputText, faInputNumeric, faPenToSquare, faUserShield, faUserCheck, faUserCrown, faUserXmark, faArrowRight, faArrowRotateLeft, faPen, faSquareCheck, faSquareCaretDown, faAlignJustify, faCircleDot, faHeading, faParagraphLeft, faCircleCheck, faCompress, faChevronUp, faCopy, faExpand, faArrowUpRightFromSquare, faChevronLeft, faChartSimpleHorizontal, faClock, faLocationDot, faCircleXmark, faGlobe, faCircleMinus, faArrowUpFromBracket, faRectangleHistory, faFileUser, faTicketPerforated, faLink, faTable, faAddressCard, faSackDollar, faUsers, faWallet, faMegaphone, faChartSimple, faArrowUpRight, faArrowLeft, faCircleInfo, faClockThree, faCheck, faCloudArrowUp, faCircleDollar, faLockKeyhole, faCircle0, faInfoCircle, faFileExport, faMinus, faBars, faCircleChevronDown, faParagraph, faShield, faArrowsMaximize, faArrowsMinimize, faPaperPlane, faSync, faLock, faRotate, faTriangleExclamation, faFileInvoiceDollar, faCopyright, faBadgeCheck, faChartLine, faMoneyCheckDollar, faImagesUser, faFolder, faFolderOpen, faCheckDouble, faArrowDownToBracket, faUserClock, faMagnet, faTableList, faLayerGroup, faImage, faTrashCan, faBullseyeArrow, faCoins, faDownload, faEdit, faPipe, faArrowUp, faArrowDown, faQrcode, faFilter, faObjectsColumn, faCode, faAddressBook } from '@fortawesome/pro-light-svg-icons';

library.add(
    faClapperboard,
    faCalendar,
    faUserGroup,
    faMicrophone,
    faBell,
    faMessage,
    faHandHoldingDollar,
    faChartPieSimple,
    faFile,
    faHexagon,
    faDollarSign,
    faMagnifyingGlass,
    faChevronDown,
    faUserPlus,
    faGearComplex,
    faArrowRightFromBracket,
    faXmark,
    faFileImport,
    faPlus,
    faUpload,
    faEnvelope,
    faEye,
    faEyeSlash,
    faEllipsisVertical,
    faChevronRight,
    faTrash,
    faGripDotsVertical,
    faVideo,
    faBullhorn,
    faInputText,
    faInputNumeric,
    faPenToSquare,
    faUserShield,
    faUserCheck,
    faUserCrown,
    faUserXmark,
    faArrowRight,
    faArrowRotateLeft,
    faPen,
    faSquareCheck,
    faSquareCaretDown,
    faAlignJustify,
    faCircleDot,
    faHeading,
    faParagraphLeft,
    faCircleCheck,
    faCompress,
    faChevronUp,
    faCopy,
    faExpand,
    faClock,
    faChartSimpleHorizontal,
    faArrowUpRightFromSquare,
    faChevronLeft,
    faCircleXmark,
    faGlobe,
    faLocationDot,
    faCircleMinus,
    faArrowUpFromBracket,
    faRectangleHistory,
    faFileUser,
    faTicketPerforated,
    faLink,
    faTable,
    faAddressCard,
    faSackDollar,
    faMicrophone,
    faUsers,
    faWallet,
    faMegaphone,
    faChartSimple,
    faArrowUpRight,
    faArrowLeft,
    faCircleInfo,
    faClockThree,
    faCheck,
    faCloudArrowUp,
    faCircleDollar,
    faLockKeyhole,
    faCircle0,
    faInfoCircle,
    faRectangleHistory,
    faFileUser,
    faTicketPerforated,
    faTable,
    faAddressCard,
    faSackDollar,
    faUsers,
    faChartSimple,
    faFileExport,
    faMinus,
    faBars,
    faCircleChevronDown,
    faParagraph,
    faShield,
    faChevronDown,
    faChevronRight,
    faCopyright,
    faBadgeCheck,
    faPaperPlane,
    faArrowsMaximize,
    faArrowsMinimize,
    faLock,
    faRotate,
    faTriangleExclamation,
    faSync,
    faFileInvoiceDollar,
    faChartLine,
    faMoneyCheckDollar,
    faImagesUser,
    faFolder,
    faFolderOpen,
    faCheckDouble,
    faArrowDownToBracket,
    faUserClock,
    faMagnet,
    faTableList,
    faLayerGroup,
    faImage,
    faTrashCan,
    faBullseyeArrow,
    faCoins,
    faPipe,
    faArrowUp,
    faArrowDown,
    faDownload,
    faEdit,
    faQrcode,
    faFilter,
    faObjectsColumn,
    faAddressBook,
    faCode
);
