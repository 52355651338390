import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { EventTargetListContext } from '../../../../../../contexts/EventTargetList/EventTargetListContext';
import { Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem } from '@mui/material';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutocompletewithTags, FormLabelComponent, RadioGroupComponent } from '../../../../../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../../../../../common/FormComponents/Buttons';
import { getEventRegistrantCount } from '../../../../../../scripts/apis/eventRegistrants';
import { eventLocationOptions } from '../../../../../../contexts/EventsPageContext';
import { BpCheckbox } from '../../../../../../common/CheckboxWrapper';
import { EventTicket } from '../../../../interfaces';
import { getAlleventTickets, getEventTicketById, ticketsCount } from '../../../../../../scripts/apis/eventTickets';
import { useDispatch, useSelector } from 'react-redux';
import { addTargetList } from '../../../../../../redux/events/eventTargetList/targetList';
import { EventRegistrantFilterData, IEventTargetListDispatch, IEventTargetListReduxData } from '../../../../interfaces/event-target-list-interface';
import TableEmptyComponent from '../../../../../../common/TableEmptyComponent';

export const getLocationName = (locationType: number): string =>
{
  const locationOption = eventLocationOptions.find(option => option.value === locationType);
  return locationOption ? locationOption.name : 'Unknown';
};

const FilterEventTargetListComponent: React.FC = (): JSX.Element => 
{
  const { selectedEvents, setSelectedEvents } = useContext(EventTargetListContext);

  const [showApprovalFilters, setShowApprovalFilters] = useState<boolean>(false);
  const [showAttendeeFilters, setShowAttendeeFilters] = useState<boolean>(false);
  const [selectedTicketOption, setSelectedTicketOption] = useState<number>(1);

  const [openEventId, setOpenEventId] = useState<string | null>(null);
  const [eventRegistrantfilter, setEventRegistrantFilter] = useState<EventRegistrantFilterData[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
  const [selectedApprovalStatusIds, setSelectedApprovalStatusIds] = useState<number[]>([]);
  const [selectedAttendedStatusIds, setSelectedAttendedStatusIds] = useState<number[]>([]);

  const [eventTicketFromDb, setEventTicketFromDb] = useState<EventTicket[]>([]);

  const dispatch = useDispatch();
  const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
  {
    return state.eventTargetList.value;
  });
  const eventRegistrantFilterData = eventTargetListReduxData?.filter;

  const eventTicketOptions = [
    {
       value: 1, name: 'All'
    },
    {
       value: 2, name: 'Custom' 
    },
  ]

  const ApprovalFilterOptions = [
    {
        id: 2, label: 'Approved'
    },
    {
        id: 3, label: 'Rejected'
    },
    {
        id: 1, label: 'In Review'
    },
    {
        id: 4, label: 'Waitlist'
    },
    {
        id: 6, label: 'Confirmed'
    }
];

const AttendeeStatusFilterOptions = [
    {
        id: 1, label: 'Attended'
    },
    {
        id: 2, label: 'Not Attended'
    },
];

  const formatEpochToDate = (epochTime: number): string =>
  {
    const date = new Date(epochTime * 1000);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleAccordionChange = (index: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
  {
    setOpenEventId(isExpanded ? index : null);
    setShowApprovalFilters(false);
    setShowAttendeeFilters(false);
    setSelectedTicketOption(1);
    setSelectedTickets([]);
  };

  const toggleApprovalStatusFilter = () =>
  {
    setShowApprovalFilters(prev => !prev);
  };

  const toggleAttendeeStatusFilter = () =>
  {
    setShowAttendeeFilters(prev => !prev);
  };

  const handleTicketOption = async (event: ChangeEvent<HTMLInputElement | HTMLLIElement>): Promise<void> =>
  {
    const value = Number(event.target.value);
    if (value == eventTicketOptions[1].value && openEventId)
    {
      const count = await ticketsCount(openEventId);
      const eventTicket = await getAlleventTickets(count, 0, openEventId);
      setEventTicketFromDb(eventTicket);
    }
    setSelectedTicketOption(value);
  };

  const handleTicketSelect = (event: ChangeEvent<HTMLInputElement>) =>
  {
    const ticketValue = event.target.textContent;
    if (ticketValue && !selectedTickets.includes(ticketValue))
    {
      setSelectedTickets((prevSelected) => [...prevSelected, ticketValue]);
    }
  };

  const handleTicketDelete = (index: number) =>
  {
    const newSelectedTickets = selectedTickets.filter((_, i) => i !== index);
    setSelectedTickets(newSelectedTickets);
  };

  const handleEllipsisClick = (event: React.MouseEvent<HTMLElement>) =>
  {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) =>
  {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) =>
  {
    event.stopPropagation();
    const updatedEvents = selectedEvents.filter(e => e.id !== openEventId);
    setSelectedEvents(updatedEvents);
    handleClose(event);
  };

  const handleApprovalCheckboxChange = (id: number) =>
  {
    setSelectedApprovalStatusIds((prev) =>
    {
      if (prev.includes(id))
      {
        return prev.filter((statusId) => statusId !== id);
      } else
      {
        return [...prev, id];
      }
    });
  };

  const handleAttendedCheckboxChange = (id: number) =>
  {
    setSelectedAttendedStatusIds((prev) =>
    {
      if (prev.includes(id))
      {
        return prev.filter((statusId) => statusId !== id);
      } else
      {
        return [...prev, id];
      }
    });
  };

  const getSelectedTicketIds = (): number[] =>
  {
    if (selectedTicketOption === 1)
    {
      return [];
    }
    return selectedTickets
      .map(ticketName =>
      {
        const ticket = eventTicketFromDb.find(ticket => ticket.name === ticketName);
        return ticket?.id ? Number(ticket.id) : null;
      })
      .filter(Boolean) as number[];
  };

  const saveEventRegistrantFilter = () =>
  {
    const selectedTicketIds = getSelectedTicketIds();

    if (openEventId)
    {
      const updatedFilters = eventRegistrantfilter.map(event =>
      {
        if (event.id === openEventId)
        {
          return {
            ...event,
            approvalStatus: selectedApprovalStatusIds,
            attendedStatus: selectedAttendedStatusIds,
            ticketId: selectedTicketIds,
          };
        }
        return event;
      });
      setEventRegistrantFilter(updatedFilters);
      dispatch(addTargetList({
        filter: updatedFilters
      }));
      setOpenEventId(null);
    }
  };

  const fetchEventData = async () =>
  {
    try
    {
      const responses = await Promise.all(selectedEvents.map(event =>
        event.id ? getEventRegistrantCount(Number(event.id)) : Promise.resolve({ data: 0 })
      ));
      const data: EventRegistrantFilterData[] = await responses.map((response, index) => ({
        id: selectedEvents[index].id,
        totalRegistrantCount: response
      }));
      setEventRegistrantFilter(data);
      dispatch(addTargetList({
        filter: data
      }));
    }
    catch (error)
    {
      console.error("Error fetching event data:", error);
    }
  };

  useEffect(() =>
  {
    fetchEventData();
  }, [])

  useEffect(() => {
    const fetchTicketsAndSetNames = async () => {
      if (openEventId) {
        const currentEvent = eventRegistrantfilter.find(event => event.id === openEventId);
        if (currentEvent) {
          setSelectedApprovalStatusIds(currentEvent.approvalStatus || []);
          setSelectedAttendedStatusIds(currentEvent.attendedStatus || []);
          const ticketNames = await Promise.all(
            currentEvent.ticketId.map(async (ticketId) => {
              const ticket = await getEventTicketById(Number(openEventId), ticketId);
              return ticket.name;
            })
          );
          setSelectedTickets(ticketNames);
          setSelectedTicketOption(currentEvent.ticketId?.length ? 2 : 1);
        }
      }
    }
    fetchTicketsAndSetNames();
    
  }, [openEventId]);

  return (
      <div id="filterTargetList">
          {selectedEvents.length === 0 ? (
              <TableEmptyComponent infoText={ "No Events Selected" } />
          ) : (
            <>
              <div className="total-records">{selectedEvents.length} records</div>
              {selectedEvents.map((event) => (
                  <Accordion
                      key={event.id}
                      expanded={openEventId === event.id}
                      onChange={handleAccordionChange(event.id)}
                      onClick={(event) => event.stopPropagation()}
                  >
                      <AccordionSummary
                          aria-controls={`event-content-${event.id}`}
                          id={`event-header-${event.id}`}
                      >
                          <div className="accordion-header">
                              <div className="accordion-row">
                                  <div style={{ display: "flex" }}>
                                      <FontAwesomeIcon
                                          icon={["fal", "chevron-right"]}
                                          className={`chevron-icon ${
                                              openEventId === event.id ? "rotated" : ""
                                          }`}
                                      />

                                      <div className="header-text">{event.title}</div>
                                  </div>
                                  {openEventId === event.id && (
                                      <>
                                          <FontAwesomeIcon
                                              className="ellipsis-menu"
                                              icon={["fal", "ellipsis-vertical"]}
                                              onClick={handleEllipsisClick}
                                          />
                                          <Menu
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={handleClose}
                                          >
                                              <MenuItem onClick={handleDeleteClick}>
                                                  Delete
                                              </MenuItem>
                                          </Menu>
                                      </>
                                  )}
                              </div>
                              {openEventId === event.id && (
                                  <div className="accordion-row sub-text">
                                      {formatEpochToDate(event.eventStartDateTime)} •{" "}
                                      {getLocationName(event.locationType)} •{" "}
                                      {eventRegistrantfilter.find((data) => data.id == event.id)
                                          ?.totalRegistrantCount || 0}
                                  </div>
                              )}
                          </div>
                      </AccordionSummary>
                      <AccordionDetails>
                          <FormLabelComponent label={"Tickets"} />
                          <RadioGroupComponent
                              row
                              options={eventTicketOptions}
                              value={selectedTicketOption}
                              onChange={handleTicketOption}
                          />
                          {selectedTicketOption === 2 && (
                              <AutocompletewithTags
                                  defaultValue={selectedTickets}
                                  value={selectedTickets}
                                  onChange={handleTicketSelect}
                                  onRemoveClick={handleTicketDelete}
                                  options={eventTicketFromDb}
                                  keyToShow="name"
                                  placeholder="Select a ticket"
                              />
                          )}
                          {!showApprovalFilters && (
                              <CustomButton
                                  name="+ Add Filter"
                                  btnType="tertiary"
                                  onClick={toggleApprovalStatusFilter}
                              />
                          )}
                          {showApprovalFilters && <FormLabelComponent label={"Approval Status"} />}
                          {showApprovalFilters && (
                              <div className="checkbox-options">
                                  {ApprovalFilterOptions.map((option) => (
                                      <label key={option.id}>
                                          <BpCheckbox
                                              value={option.label}
                                              onChange={() =>
                                                  handleApprovalCheckboxChange(option.id)
                                              }
                                              checked={selectedApprovalStatusIds.includes(option.id)}
                                          />
                                          {option.label}
                                      </label>
                                  ))}
                              </div>
                          )}
                          {showApprovalFilters && !showAttendeeFilters && (
                              <CustomButton
                                  name="+ Add Filter"
                                  btnType="tertiary"
                                  onClick={toggleAttendeeStatusFilter}
                              />
                          )}
                          {showAttendeeFilters && <FormLabelComponent label={"Attended Status"} />}
                          {showAttendeeFilters && (
                              <div className="checkbox-options">
                                  {AttendeeStatusFilterOptions.map((option) => (
                                      <label key={option.id}>
                                          <BpCheckbox
                                              value={option.label}
                                              onChange={() =>
                                                  handleAttendedCheckboxChange(option.id)
                                              }
                                              checked={selectedAttendedStatusIds.includes(option.id)}
                                          />
                                          {option.label}
                                      </label>
                                  ))}
                              </div>
                          )}
                          <div className="accordion-save">
                              <CustomButton
                                  name={"save"}
                                  btnType={"primary"}
                                  onClick={saveEventRegistrantFilter}
                              />
                          </div>
                      </AccordionDetails>
                  </Accordion>
              ))}
            </>
          )}
      </div>
  );
};

export default FilterEventTargetListComponent;