export enum EventTargetListType
{
  STATIC = 1,
  DYNAMIC = 2,
}

export enum EventTargetListStatus
{
  ACTIVE = 1,
  DELETED = 2,
}

export enum EventTargetListSourceType
{
  MANUAL = 1,
  IMPORT_CSV = 2,
  PAST_EVENT = 3,
  APOLLO = 4,
  HUBSPOT = 5,
  AUDIENCE = 6,
}

export enum EventTargetListAudienceMapStatus
{
  ACTIVE = 1,
  DELETED = 2,
}