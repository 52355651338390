import { EventAnalyticsType, EventRegistrationRange } from '../../pages/Events/enum';
import { Event, EventAnalyticsGraph, EventCategoryTrend, EventGraph, EventGraphObject, EventMapObj, EventOverviewData, EventPreview, EventProperties, EventRoiOrder } from '../../pages/Events/interfaces';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET Methods
export const eventsCount = async (): Promise<number> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/count';
    return triggerApi(API_URL, 'GET', '');
};

export const eventStatusCount = async (): Promise<EventGraphObject> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/status-count';
    return triggerApi(API_URL, 'GET', '');
};

export const getEvents = async (pageSize: number, offset: number, ...queryParams: [string, any][]): Promise<Event[]> => {
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/events?pageSize=${pageSize}&offset=${offset}&${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventById = async (eventId: string | number): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventProperties = async (eventId: string): Promise<EventProperties> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/properties/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventsByCategory = async (): Promise<EventCategoryTrend[]> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/trend';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventsCategoriesCount = async (type: number): Promise<number> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/category/count?type=' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const getTopAndLeastRoiEvents = async (): Promise<EventRoiOrder> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/roi';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventPreview = async (eventLink: string): Promise<EventPreview> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventLink + '/preview';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventsGraph = async (): Promise<EventGraph> => {
    const query = convertJSONToGETParams({
        type: true,
        status: true,
        location: true,
        trend: true,
        quarter: true,
        mode: true,
        audienceType: true,
        ticketType: true,
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventAnalytics = async (eventId: string | number): Promise<EventAnalyticsGraph> => {
    const query = convertJSONToGETParams({
        audienceFunnel: true,
        trend: true,
        icp: true,
        buyerPersona: true,
        arr: true
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/analytics/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventOverviewAnalytics = async (eventId: string, from?: string, to?: string): Promise<EventOverviewData> => {
    const query = convertJSONToGETParams({
        from: from,
        to: to
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/overview?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getMapEvents = async (type: number): Promise<EventMapObj> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/map-events?type=' + type;
    return triggerApi(API_URL, 'GET', '');
};

export const syncLumaEvents = async (): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/sync/luma';
    return triggerApi(API_URL, 'GET', '');
};

export const getRegistrationsGraph = async (eventId: string | number, range: EventRegistrationRange, timezone?: string): Promise<EventGraph> => {
    const query = convertJSONToGETParams({
        timezone,
        range: range,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/registrations/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const searchEvents = async (title: string): Promise<Event[]> => 
{

    const query = convertJSONToGETParams({ 
        title, 
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/search?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventAnalyticsGraph = async (eventId: string | number): Promise<any> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/v1/analytics/graph';
    return triggerApi(API_URL, 'GET', '');
};

export const getEventAnalyticsTable = async (eventId: string | number, type: EventAnalyticsType, pageSize: number, offset: number) => 
{
    const query = convertJSONToGETParams({
        type,
        pageSize,
        offset
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/v1/analytics/table?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getFormEmbedCodeSnippet = async (eventId: number): Promise<string> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/embed-form-snippet';
    return triggerApi(API_URL, 'GET', '');
};

// POST Methods
export const createEvent = async (eventData: Event, csrfToken: string): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events';
    return triggerApi(API_URL, 'POST', eventData, csrfToken);
};

export const uploadImage = async (eventId: string, imageName: string, imageData: FormData, csrfToken: string): Promise<string> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/upload-image/' + imageName;
    return triggerApi(API_URL, 'POST', imageData, csrfToken, false);
};

export const createEventRegistrationToolMap = async (eventMapData, csrfToken: string): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/map-events';
    return triggerApi(API_URL, 'POST', eventMapData, csrfToken);
};

// PUT Methods
export const updateEvent = async (eventId: string, eventData): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'PUT', eventData);
};

export const updateEventRegistrationDetails = async (eventId: number, eventData): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'PUT', eventData);
};

export const publishEvent = async (eventId: number): Promise<Event> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId + '/publish';
    return triggerApi(API_URL, 'PUT', '');
};

// DELETE Methods
export const deleteEvent = async (eventId: number | string): Promise<boolean> => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/events/' + eventId;
    return triggerApi(API_URL, 'DELETE', '');
};