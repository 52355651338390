import { createContext, useContext, useEffect, useState } from "react";
import { useTablePagination } from "../TablePaginationContext";
import { deleteCoupon, getCouponCount, getCoupons } from "../../scripts/apis/eventTicketCoupons";
import { EventCoupon } from "../../pages/Events/interfaces/event-coupon_interface";
import { Organization } from "../../interfaces/settings/organization_interface";
import { LocalStorage } from "../../scripts/LocalStorage";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../components/Events/interfaces/create-events_interface";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { UserContext } from "../UserContext";

interface CouponsContext {
    eventId?: number;
    couponCount: number;
    coupons: EventCoupon[];
    rows: EventCoupon[];
    setRows: React.Dispatch<React.SetStateAction<EventCoupon[]>>;
    showSpinner: boolean;
    isEmpty: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: string | number) => void;
    updatePageSize: (value: string | number) => void;
    deleteCouponFn: (coupon: EventCoupon) => Promise<void>;
    orgData: Organization | undefined;
    eventLink: string;
    isTableView: boolean;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCoupon: EventCoupon | null;
    setSelectedCoupon: React.Dispatch<React.SetStateAction<EventCoupon | null>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const CouponsContext = createContext<CouponsContext>({
    eventId: undefined,
    couponCount: 0,
    coupons: [],
    rows: [],
    setRows: () => {},
    showSpinner: false,
    isEmpty: false,
    setRefresh: () => {},
    pageSize: 10,
    currentPage: 1,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    deleteCouponFn: async () => {},
    orgData: undefined,
    eventLink: '',
    isTableView: false,
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    selectedCoupon: null,
    setSelectedCoupon: () => {},
    setCurrentUrl: () => {}
});

const CouponsProvider: React.FC<{children: React.ReactNode, eventId: number}> = ({ children, eventId }): React.JSX.Element =>  
{
    const currentpath = useLocation().pathname;
    const isTableView = currentpath.includes('coupons');

    const {
        orgDetails
    } = useContext(UserContext);

    const [couponCount, setCouponCount] = useState<number>(0);
    const [rows, setRows] = useState<EventCoupon[]>([]);
    const [coupons, setCoupons] = useState<EventCoupon[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    // const [orgData, setOrgData] = useState<Organization | undefined>(LocalStorage.get('@Org'));
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedCoupon, setSelectedCoupon] = useState<EventCoupon | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    }); 

    const eventLink = eventReduxData?.link;
    
    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchCouponsData = async (): Promise<void> =>
    {
        try 
        {
            const count = await getCouponCount(eventId);
            if(count)
            {
                setCouponCount(count);
                setIsEmpty(count == 0);

                if(isTableView)
                {
                    const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                    const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                    const couponsData = await getCoupons(eventId, pageSize, currentPage - 1, encodedParams);
                    if(couponsData)
                    {
                        setCoupons(couponsData);
                        setShowSpinner(false);
                    }
                }
                else
                {
                    const couponsData = await getCoupons(eventId, 6, 0);
                    if(couponsData)
                    {
                        setCoupons(couponsData);
                        setShowSpinner(false);
                    }
                }
            }   
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    // const fetchOrgData = async (): Promise<void> =>
    // {   
    //     try 
    //     {
    //         const org = await orgMe();
    //         if (org) 
    //         {
    //             setOrgData(org);
    //         }
    //     } 
    //     catch (error) 
    //     {
    //         console.log(error);
    //     }
    // };

    const deleteCouponFn = async (coupon: EventCoupon): Promise<void> =>
    {    
        try 
        {
            const couponDeleted = await deleteCoupon(eventId, coupon.id as string);
            if (couponDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedCoupon(null);
                toast.success('Coupon Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }   
    };

    useEffect((): void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            fetchCouponsData();
        }
    }, [currentPage, pageSize, currentUrl]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchCouponsData();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => 
    {
        if(!LocalStorage.get('@Org'))
        {
            fetchOrgData();
        }

        if(!isTableView)
        {
            fetchCouponsData();
        }
    }, []);

    return (
        <CouponsContext.Provider 
            value={{
                eventId, 
                couponCount, 
                coupons, 
                showSpinner, 
                isEmpty, 
                setRefresh, 
                pageSize, 
                currentPage, 
                updateCurrentPage, 
                updatePageSize, 
                deleteCouponFn, 
                orgData: orgDetails as Organization, 
                eventLink, 
                isTableView,
                showDeletePopup,
                setShowDeletePopup,
                selectedCoupon,
                setSelectedCoupon,
                rows,
                setRows,
                setCurrentUrl
            }}
        >
            {children}
        </CouponsContext.Provider>
    );
};

export default CouponsProvider;