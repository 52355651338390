import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { CONTENT } from '../scripts/i18n';
import SettingsPage from '../pages/Settings/SettingsPage';
import AppLayout from '../layouts/AppLayout';
import ProtectedRoute from '../layouts/ProtectedRoute';
import AuthenticatorRoute from '../layouts/AuthenticatorRoute';
import EventsInfo from '../pages/Events/EventsInfo';
import AudiencePage from '../pages/Audience/AudiencePage';
import SpeakersPage from '../pages/Speakers/SpeakersPage';
import SponsorsPage from '../pages/Sponsors/SponsorsPage';
import EventsPage from '../pages/Events/EventsPage';
import VendorsPage from '../pages/Vendors/VendorsPage';
import { AnimatePresence } from 'framer-motion';
import RegistrationsPage from '../pages/Registration/RegistrationsPage/RegistrationsPage';
import Registrations from '../pages/Registration/Registrations';
import BudgetsPage from '../pages/Budget/BudgetsPage';
import UserLoginPage from '../pages/Authentication/UserLoginPage';
import UserFlowPage from '../pages/Authentication/UserFlowPage';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import RegistrationPageProvider from '../contexts/RegistrationsContext';
import EventsPageProvider from '../contexts/EventsPageContext';
import SponsorsPageProvider from '../contexts/SponsorsPageContext';
import SpeakersProvider from '../contexts/SpeakersPageContext';
import VendorsProvider from '../contexts/VendorsPageContext';
import FormComponentsProvider from '../contexts/FormComponentsContext';
import AnalyticsPage from '../pages/Analytics/AnalyticsPage';
import SponsorPortalProtectedRoute from '../layouts/SponsorPortalProtectedRoute';
import SponsorPortalProvider from '../contexts/SponsorPortal/SponsorPortalContext';
import CreateEventPage from '../pages/Events/EventDetails/CreateEvent/CreateEventPage';
import { orgBillingDetails } from '../scripts/apis/billing';
import { useDispatch } from 'react-redux';
import { billingDetails } from '../redux/billing/billingInfo';
import { LocalStorage } from '../scripts/LocalStorage';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import OpenPreview from '../pages/Registration/Preview/CheckPreviewLogic';
import UserProvider from '../contexts/UserContext';

const AppRouter = (): React.JSX.Element => 
{

    const [searchParams] = useSearchParams();
    
    const dispatch = useDispatch();

    const [loading, isLoading] = useState<boolean>(true);
    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const fetchOrgBillingDetails = async (): Promise<void> =>
    {
        try 
        {
            const user = LocalStorage.get('@UserMe');
            if (user)
            {
                const billingInfo = await orgBillingDetails();
                if (billingInfo)
                {
                    dispatch(billingDetails({
                        billingDetails: billingInfo
                    }));
                }
                else
                {
                    dispatch(billingDetails({
                        billingDetails: {
                            users: true,
                            billingModules: {
                                sponsor: true,
                                vendor: true,
                                photoSharing: {
                                    userUploadPhotoSharing: true,
                                    photoSharingWatermark: true
                                },
                                checkInApp: true,
                                connectApp: true,
                                integrations: true,
                            }
                        }
                    }));
                }
            }
        } 
        catch (error) 
        {
            console.log('Error fetching billing details', error);
            dispatch(billingDetails({
                billingDetails: {
                    users: true,
                    billingModules: {
                        sponsor: true,
                        vendor: true,
                        photoSharing: {
                            userUploadPhotoSharing: true,
                            photoSharingWatermark: true
                        },
                        checkInApp: true,
                        connectApp: true,
                        integrations: true,
                    }
                }
            }));
        }
        finally
        {
            isLoading(false);
        }
    };

    useEffect(() => {
        fetchOrgBillingDetails();
    }, []);

    return (
        <Suspense fallback={<div />}>
            <AnimatePresence exitBeforeEnter>
                {
                    loading ? <></> : 
                    <Routes>
                        <Route path="/" element={<AppLayout />}>

                            {/* initial path */}
                            <Route element={<ProtectedRoute />}>
                                <Route path={'/'} element={<Navigate to={CONTENT.PATHS.ROOT.TO} replace />} />
                            </Route>

                            {/* signup */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/signup" element={<UserLoginPage />} />
                            </Route>

                            {/* login */}
                            <Route element={<AuthenticatorRoute />}>
                                <Route path="/login" element={<UserLoginPage token={searchParams.get('token')} />} />
                            </Route>

                            {/* register as new user */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/register" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='register' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* forgot password */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/forgot-password" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='forgot-password' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* onboard / accept invite */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/onboard" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='onboard' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* dashboard */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/dashboard'} element={<DashboardPage />} />
                            </Route>

                            {/* events */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:status?/:eventId?'} element={<EventsPageProvider><EventsPage/> </EventsPageProvider>} />
                            </Route>

                            {/* create event */}
                            <Route element={<UserProvider><ProtectedRoute top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/create-event'} element={<CreateEventPage/> } />
                            </Route>

                            {/* event budget */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/budget'} element={<EventsInfo data={'budget'} />} />
                            </Route>

                            {/* event overview */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/overview'} element={<EventsInfo data={'overview'} />} />
                            </Route>

                            {/* event registration details - ideally contains registration form, tickets, coupons, utm's */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/registrations'} element={<EventsInfo data={'registrations'} secondary-navbar={false} />} />
                            </Route>

                            {/* coupons */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/coupons'} element={<EventsInfo data={'coupons'} />} />
                            </Route>

                            {/* utm's */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/share-links'} element={<EventsInfo data={'utm'} />} />
                            </Route>

                            {/* event tickets */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/tickets'} element={<EventsInfo data={'tickets'} />} />
                            </Route>
                            
                            {/* event registrants */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/people/registrants/:eventRegistrantId?'} element={<EventsInfo tab={'registrants'} data={'registrants'} secondary-navbar={false} />} />
                            </Route>
                            
                            {/* event speakers */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/people/speakers/:eventSpeakerId?'} element={<EventsInfo tab={'speakers'} data={'speakers'} secondary-navbar={false} />} />
                            </Route>
                            
                            {/* event vendors */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/people/vendors/:eventVendorId?'} element={<EventsInfo tab={'vendors'} data={'vendors'} secondary-navbar={false} />} />
                            </Route>

                            {/* event sponsors */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/people/sponsors/:eventSponsorId?'} element={<EventsInfo data={'sponsors'} tab={'sponsors'} subTab={'sponsors'} secondary-navbar={false} />} />
                            </Route>

                            {/* event marketing */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/marketing'} element={<EventsInfo data={'marketing'} />} />
                            </Route>

                            {/* event agenda - ideally has event stage / sessions */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/agenda'} element={<EventsInfo data={'agenda'} />} />
                            </Route>

                            {/* global analytics */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/analytics/revenue'} element={<EventsInfo data={'analytics'} />} />
                            </Route>

                            {/* event access control */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/access-control'} element={<EventsInfo data={'accessControl'} />} />
                            </Route>

                            {/* event lead capture */}
                            {/* <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} />}>
                                <Route path={'/events/:eventId/connect'} element={<EventsInfo data={'leadCapture'} />} />
                            </Route> */}
    
                            {/* event target list */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/target-list'} element={<EventsInfo data={'targetList'} />} />
                            </Route>

                            {/* event target list Audience */ }
                            <Route element={ <UserProvider><ProtectedRoute collapsed-side-bar={ true } top-app-bar={ false } /></UserProvider> }>
                                <Route path={ '/events/:eventId/target-list/:listId' } element={ <EventsInfo data={ 'targetListAudience' } /> } />
                            </Route>

                            {/* create event target list */}
                            <Route element={ <UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/target-list/create'} element={<EventsPageProvider><EventsInfo navabar-topbar={true} data={'createTargetList'} /></EventsPageProvider>} />
                            </Route>

                            {/* event photo sharing */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/photo-sharing/:folder?/:hidden?/:default-photos?/:user-uploads?/:approved?'} element={<EventsInfo data={'photoSharing'} />} />
                            </Route>

                            {/* global - audience */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/audience/:audienceId?'} element={<AudiencePage />} />
                            </Route>

                            {/* global - speakers */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/speakers/:speakerId?'} element={<SpeakersProvider><SpeakersPage /></SpeakersProvider>} />
                            </Route>

                            {/* global - sponsors */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                {/* <Route path={'/sponsors/:sponsorId?'} element={orgBillingInfo?.billingModules?.sponsor ? <SponsorsPageProvider><SponsorsPage /></SponsorsPageProvider> : <Navigate to={'/not-found'} replace />} /> */}
                                <Route path={'/sponsors/:sponsorId?'} element={orgBillingInfo && orgBillingInfo?.billingModules && orgBillingInfo?.billingModules?.sponsor ? <SponsorsPageProvider><SponsorsPage /></SponsorsPageProvider> : <Navigate to={'/not-found'} replace />} />
                            </Route>

                            {/* global - vendors */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/vendors/:vendorId?'} element={orgBillingInfo && orgBillingInfo?.billingModules && orgBillingInfo?.billingModules?.vendor ? <VendorsProvider><VendorsPage /></VendorsProvider> : <Navigate to={'/not-found'} replace />} />
                            </Route>

                            {/* global - registrations */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/registrations/:formId?'} element={<RegistrationPageProvider><RegistrationsPage /></RegistrationPageProvider>} />
                            </Route>

                            {/* registrations -> form fields page */}
                            <Route element={<UserProvider><ProtectedRoute top-app-bar={false} /></UserProvider>}>
                                <Route path={'/registrations/:formId/form'} element={<FormComponentsProvider><Registrations tab={'form'} /></FormComponentsProvider>} />
                            </Route>

                            {/* registrations -> form fields preview page */}
                            <Route element={<UserProvider><ProtectedRoute top-app-bar={false} /></UserProvider>}>
                                <Route path={'/registrations/:formId/preview'} element={<FormComponentsProvider><Registrations tab={'preview'} /></FormComponentsProvider>} />
                            </Route>

                            {/* global - budgets */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/budget'} element={<BudgetsPage />} />
                            </Route>
            
                            {/* global - audience analytics page */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} /></UserProvider>}>
                                <Route path={'/analytics'} element={<Navigate to={'/analytics/audience'} replace />} />
                            </Route>

                            {/* global - audience analytics page */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/analytics/audience'} element={<AnalyticsPage page='Audience' />} />
                            </Route>
                            
                            {/* global - budget analytics page */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/analytics/budget'} element={<AnalyticsPage page='Budget' />} />
                            </Route>

                            {/* global - events analytics page */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/analytics/events'} element={<AnalyticsPage page='Events' />} />
                            </Route>

                            {/* global - roi analytics page */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/analytics/roi'} element={<AnalyticsPage page='roi' />} />
                            </Route>

                            {/* //////////// */}
                            {/* Settings Routes */}

                            {/* settings page -> redirects to profile settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings'} element={<Navigate to={'/settings/general'} replace />} />
                            </Route>

                            {/* profile settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/general'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.GENERAL.TAB} />} />
                            </Route>

                            {/* organisation settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/organization'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ORGANIZATION.TAB} />} />
                            </Route>
                            
                            {/* integration related settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* all integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/all'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ALL} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* crm integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/crm'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CRM} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* marketing tools integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/marketing-tools'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.MARKETING_TOOLS} /> : <Navigate to='/not-found' replace />} />
                            </Route>
                            
                            {/* registration tools integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/registration-tools'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.EVENT_REGISTRATION_TOOLS} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* csm tools integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/csm-tools'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.CSM_TOOLS} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* enrichment integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/enrichment'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.ENRICHMENT} /> : <Navigate to='/not-found' replace />} />
                            </Route>
                            
                            {/* payments integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/payments'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.PAYMENTS} /> : <Navigate to='/not-found' replace />} />
                            </Route>

                            {/* other integrations page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/integrations/others'} element={orgBillingInfo?.billingModules?.integrations ? <SettingsPage tab={CONTENT.PATHS.SETTINGS.INTEGRATIONS.TAB} subTab={CONTENT.PATHS.SETTINGS.INTEGRATIONS_SUB_TAB.OTHERS} /> : <Navigate to='/not-found' replace />} />
                            </Route>
                            
                            {/* icp page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/icp'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ICP.TAB} />} />
                            </Route>

                            {/* buyer persona page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/buyer-persona'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.BUYER_PERSONA.TAB} />} />
                            </Route>

                            {/* users page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/users'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.USERS.TAB} />} />
                            </Route>

                            {/* custom fields */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/custom-field'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.CUSTOM_FIELD.TAB} />} />
                            </Route>

                            {/* <Route element={<ProtectedRoute/>}>
                                <Route path={'/settings/billing'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.BILLING.TAB} />} />
                            </Route> */}

                        </Route>

                        {/* Sponsor portal pages */}
                        <Route path="/:orgLink/:eventLink/sponsor-portal" element={<SponsorPortalProvider><SponsorPortalProtectedRoute side-bar={true} /></SponsorPortalProvider>} />

                        <Route path={'*'} element={<OpenPreview />} />
                        {/* dashboard */}
                    </Routes>
                }
            </AnimatePresence>
        </Suspense>
    );
};
export default AppRouter;