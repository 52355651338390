import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Audience } from '../../../../Audience/interfaces';
import { LocalStorage } from '../../../../../scripts/LocalStorage';
import { User } from '../../../../../interfaces/settings/user_interface';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
import { generateAudienceColumnsForTanstackTable } from '../../../../Audience/audienceColumn.helper';
import APP_CONSTANTS from '../../../../../scripts/constants';
import eventBus from '../../../../../scripts/event-bus';
import ViewAudienceInfo from '../../../../Audience/ViewAudienceInfo';
import { userMe } from '../../../../../scripts/apis/users';
import { count, deleteAudience, getAllAudience, getAudienceById, getAudienceColumnProperties, searchAudience } from '../../../../../scripts/apis/audience';
import { CONTENT } from '../../../../../scripts/i18n';
import { Box } from '@mui/material';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import './styles.scss';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { resetTargetList } from '../../../../../redux/events/eventTargetList/targetList';
import { useDispatch, useSelector } from 'react-redux';
import { creatTargetListByEventId } from '../../../../../scripts/apis/eventTargetList';
import { EventTargetList, IEventTargetListDispatch, IEventTargetListReduxData } from '../../../interfaces/event-target-list-interface';
import { EventTargetListSourceType } from '../../../enum/event-target-list.enum';
import _ from 'lodash';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';

const TargetListByAudiencePage: React.FC = (): JSX.Element => 
{

    const navigate = useNavigate();

    const [rows, setRows] = useState<Audience[]>([]);
    const [audienceInfo, setAudienceInfo] = useState<Audience[]>([]);
    const [audCount, setAudCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [tableSearch, setTableSearch] = useState<string>('');
    const [searchBy, setSearchBy] = useState<number>(1);
    const [userData, setUserData] = useState<User | undefined>(LocalStorage.get('@UserMe') || undefined);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { audienceId } = useParams();
    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();
    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;

    const filterColumns = [
        {
            header: 'First Name',
            id: 'firstName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
    ];

    const eventTargetListReduxData = useSelector((state: IEventTargetListDispatch): IEventTargetListReduxData => 
    {
        return state.eventTargetList.value;
    });
    const csrfTokenData = useSelector((state): string => 
    {
        return state['csrfTokenValue'].value.csrfToken;
    });


    if (userData?.role === 3)
    {
        audienceColumns = [...audienceColumns];
    }
    const [allColumns, setAllColumns] = useState(audienceColumns);

    const handleAddTargetListClick = async () =>
    {
        try
        {
            const eventId = eventTargetListReduxData.eventId;
            const eventTargetList: EventTargetList = {
                name: eventTargetListReduxData.name,
                description: eventTargetListReduxData.description,
                type: eventTargetListReduxData.type,
                source: [EventTargetListSourceType.AUDIENCE],
                audienceIds: selectedRowIds,
            };

            const response = await creatTargetListByEventId(eventId, eventTargetList, csrfTokenData);
            dispatch(resetTargetList());
            navigate(`/events/${ eventId }/target-list`);

            return response;

        }
        catch (error) 
        {
            console.error('Error submitting target list:', error);
            throw error;
        }
    };

    const handleCancelClick = () =>
    {
        setSelectedRowIds([]);
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const openDrawer = (data, routeFromId?: string): void => 
    {
        const clearbitFName = data?.clearbitData?.givenName;
        const clearbitLName = data?.clearbitData?.familyName;

        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : data?.firstName;

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: name,
            // event: 'edit-event',
            hideCloseButton: true,
            component: <ViewAudienceInfo audienceData={ data } routeFromId={ routeFromId } name={ name } />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.REQUIRED_SHOW, {
            hideRequiredField: true,
        });
    };

    const fetchTotalAudienceCount = async (): Promise<void> => 
    {
        try 
        {
            const audienceCount = await count();
            if (audienceCount) 
            {
                setAudCount(audienceCount);
            }
        }
        catch (error) 
        {
            console.error(error);
        }
    };

    const fetchUserData = async (): Promise<void> =>
    {
        try 
        {
            const user = await userMe();
            if (user)
            {
                setUserData(user);
            }
        }
        catch (error) 
        {
            console.error('Error in fetching user data');
        }
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (audienceInfo && audienceInfo?.length && audienceId) 
        {
            try 
            {
                const audienceDataFromId = await getAudienceById(Number(audienceId));
                if (audienceDataFromId) 
                {
                    openDrawer(audienceDataFromId, 'true');
                }
                else if (!audienceDataFromId) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
                        open: true,
                        message: 'The audience you\'re trying to access doesn\'t exist',
                        severity: 'error',
                        positionVertical: 'top',
                        positionHorizontal: 'center',
                    });
                    navigate('/audiences');
                }
            }
            catch (error) 
            {
                console.error(error);
            }
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            if (audCount) 
            {
                try 
                {
                    const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                    const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                    const audienceData = await getAllAudience(pageSize, currentPage - 1, encodedParams);

                    if (audienceData) 
                    {
                        setAudienceInfo([...audienceData]);
                        setIsEmpty(audienceData?.length === 0);
                        setRows([...audienceData]);
                        setShowSpinner(false);

                        const initialColumnKeys = ['firstName', 'email', 'lastName'];
                        const initialColumns = initialColumnKeys.map((field) => ({
                            field,
                            headerName: _.startCase(field),
                            // hide: !fieldsToShow.includes(field),
                            disableColumnMenu: true,
                        }));

                        try 
                        {
                            const properties = await getAudienceColumnProperties();
                            if (properties) 
                            {
                                const updatedProperties = properties?.map(property => ({
                                    ...property,
                                    clearbit: true
                                }));

                                const filteredBaseColumnProperties = updatedProperties?.filter((property): boolean => 
                                {
                                    return property.field !== 'email' && property.field !== 'firstName' && property?.field !== 'lastName';
                                })
                                setBaseColumnProperties([...initialColumns, ...filteredBaseColumnProperties]);
                            }
                        }
                        catch (error) 
                        {
                            console.error(error);
                        }

                        eventBus.dispatch(APP_CONSTANTS.EVENTS.FILTERS.FILTER_ROW, {
                            filterData: 'audienceRowsData',
                            rows: rows,
                            placeHolder: CONTENT.AUDIENCE_PAGE.FILTER_PLACEHOLDER,
                            filterObj: {
                                id: 'firstName',
                                name: 'firstName',
                            },
                            options: rows,
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.DATA_GRID.AUDIENCE_ROW_CLICK, (data): void => 
                        {
                            openDrawer(data.rowData);
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.FILTERS.FILTERED_ROW, (filteredObj): void => 
                        {
                            const defaultRows = rows;
                            const filteredRow = _.filter(rows, function (item) 
                            {
                                if (filteredObj.filteredData?.length > 0) 
                                {
                                    return filteredObj.filteredData.includes(item.firstName);
                                }
                                else return defaultRows;
                            });
                            setRows(filteredRow);
                        });

                        eventBus.on(APP_CONSTANTS.EVENTS.AUDIENCE.DELETE, async (object): Promise<void> => 
                        {
                            const acceptObj = object.acceptObj;
                            const audienceRows = audienceData;
                            if (audienceRows && audienceRows?.length > 0) 
                            {
                                acceptObj.id ? await deleteAudience(acceptObj.id) : null;
                                _.remove(audienceRows, {
                                    id: acceptObj.id
                                });
                                setRows([...audienceRows]);
                            }
                        });
                    }

                }
                catch (error) 
                {
                    console.error(error);
                }
            }
        }
        catch (error) 
        {
            console.error(error);
        }

    };



    useEffect((): void => 
    {
        fetchData();
        fetchTotalAudienceCount();

        if (!LocalStorage.get('@UserMe'))
        {
            fetchUserData();
        }
    }, []);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [audienceInfo]);

    useEffect((): void => 
    {

        if (currentPage > 0 && pageSize) 
        {
            fetchData();
        }

        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: CONTENT.AUDIENCE_PAGE.HEADING,
            listCount: audCount,
            closedChartsButton: true,
            closedChartClickEventName: 'close-audience-page-charts',
        });
    }, [pageSize, currentPage, audCount, currentUrl]);

    useEffect(() => 
    {
        const delayDebounceFn = setTimeout(async (): Promise<void> => 
        {
            if ((tableSearch && tableSearch.length >= 2 && (searchBy === 1 || searchBy === 2))) 
            {
                try 
                {
                    const audienceData = await searchAudience(
                        tableSearch?.includes('@') ? tableSearch : undefined,
                        !tableSearch?.includes('@') ? tableSearch : undefined,
                    );

                    if (audienceData) 
                    {
                        setRows(audienceData);
                    }
                }
                catch (error) 
                {
                    console.error(error);
                }
            }
            else if (tableSearch === '') 
            {
                fetchData();
                setShowSpinner(false);
            }
        }, 300);
        return (): void => 
        {
            return clearTimeout(delayDebounceFn);
        };
    }, [tableSearch, searchBy]);

    useMemo(() =>
    {
        if (refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <Box id="targetListAudiencePage" style={{ width: "98%%" }}>
            <Box
                key={"audience-page-table"}
                sx={{
                    height: "calc(100vh - 60px)",
                    maxHeight: "calc(100vh - 60px)",
                }}
            >
                {selectedRowIds.length > 0 ? (
                    <div className="button-row">
                        <CustomButton
                            btnType="primary"
                            name="Add To List"
                            onClick={handleAddTargetListClick}
                        />
                        <CustomButton
                            btnType="secondary"
                            name="Cancel"
                            onClick={handleCancelClick}
                        />
                        <div className="table-header-text">
                            {" "}
                            {selectedRowIds.length} rows selected{" "}
                        </div>
                    </div>
                ) : (
                        <TanstackToolbarWithServerFilter
                            columns={ audienceColumns }
                            setColumns={ setAllColumns }
                            setCurrentUrl={ setCurrentUrl }
                            tabs={ [{
                                tabName: 'All',
                                count: audCount,
                                navigation: () => {},
                                selectedTab: true
                            }] }
                            handleApplyFilters={ (filters) =>
                            {
                                handleApplyFilters(filters);
                            } }
                            filterColumns={ filterColumns }
                        />
                )}

                <TanstackTable
                    checkboxSelection={true}
                    data={rows}
                    initialColumns={allColumns}
                    rowCount={audCount}
                    pageSize={pageSize}
                    currentPage={currentPage - 1}
                    updateCurrentPage={updateCurrentPage as any}
                    updatePageSize={updatePageSize}
                    showSpinner={showSpinner}
                    hidePagination={tableSearch?.length >= 2}
                    height={`calc(100% - 109px)`}
                    rightPinnedColumns={["actions"]}
                    onRowClick={(row) => openDrawer(row)}
                    selectedRows={selectedRowIds}
                    setSelectedRows={setSelectedRowIds}
                />
            </Box>
        </Box>
    );
};

export default TargetListByAudiencePage