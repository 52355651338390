import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Form } from 'react-bootstrap';
import { getAllVendors, vendorsCount } from '../../../../../scripts/apis/vendors';
import _ from 'lodash';
import Vendors from '../../../../../components/Vendors/Vendors';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { addVendors, resetVendors } from '../../../../../redux/vendors/Vendors';
import { createEventVendor, eventVendorCount, getAllEventVendors } from '../../../../../scripts/apis/eventVendor';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { AutocompleteComponent, CloseIconComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import ButtonGroup from '../../../../../common/ButtonGroup';
import toast from 'react-hot-toast';

import './styles.scss';

const AddVendors = (props):React.JSX.Element => 
{

    const dispatch = useDispatch();
    const currentpath = useLocation().pathname;
    const navigate = useNavigate();
    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const defaultVendorObj = {
        status: 0,
        id: 0,
        eventId: 0,
    };

    const [isSelect, setIsSelect] = useState<boolean>(currentpath?.includes('vendors') || false);

    const key = isSelect ? 'select' : 'create';

    const eventId = props['data-id'];
    const [spinner, setSpinner] = useState(false);
    const [vendorsData, setVendorsData] = useState([]);
    const [vendor, setVendor] = useState(defaultVendorObj);
    const [vendorSelectValue, setVendorSelectValue] = useState('');
    const [vendorInputValue, setVendorInputValue] = useState('');
    const [eventVendorsData, setEventVendorsData] = useState([]);

    const formSubmit = async (event):Promise<void> => 
    {
        event.preventDefault();
        if (!vendor || vendorSelectValue === '') 
        {
            toast
            return;
        }

        setSpinner(true);
        // let eventVendorCreated;
        const eventVendorData = {
            status: vendor.status,
            vendorId: vendor.id,
            eventId: eventId,
        };
        try 
        {
            const eventVendorCreated = await createEventVendor(eventVendorData, eventId, csrfTokenData);
            if (eventVendorCreated) 
            {
                props.setRefresh(true);
                toast.success('Vendor Added');
                handleDrawerClose();
            }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const handleVendorSelectChange = (event, newValue):void => 
    {

        setVendorInputValue(newValue);
        setVendorSelectValue(newValue);

        if (newValue === '') 
        {
            dispatch(resetVendors());
        }
        const selectedVendor = _.filter(vendorsData, function (vendor):boolean 
        {
            return newValue === vendor.name;
        })[0];
        setVendor(selectedVendor);
        if (selectedVendor) 
        {
            dispatch(addVendors({
                name: selectedVendor.name,
                email: selectedVendor.email,
                vendorName: selectedVendor.vendorName,
                description: selectedVendor.description,
                social: selectedVendor?.social,
            }));
        }
    };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch('close-side-drawer', {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        dispatch(resetVendors());
    };

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        const fetchData = async ():Promise<void> => 
        {
            try 
            {
                const count = await vendorsCount();
                if (count) 
                {
                    const vendors = await getAllVendors(count, 0);
                    setVendorsData([...vendors]);
                    const vendorCount = await eventVendorCount(eventId);
                    if (vendorCount) 
                    {
                        const eventVendors = await getAllEventVendors(vendorCount, 0, eventId);
                        if (eventVendors) 
                        {
                            setEventVendorsData([...(_.map(eventVendors, 'id'))]);
                        }
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }

        };
        fetchData();
    }, []);

    return (
        <Box id="addEventVendor">

            {isSelect && <CloseIconComponent onClick={handleDrawerClose} />}

            {!props?.isTableView && <ButtonGroup tabs={[
                {
                tabName: 'Create Vendor',
                selectedTab: !isSelect,
                navigation: (): void => 
                    {
                        setIsSelect(false);
                        dispatch(resetVendors());
                    }    
                },
                {
                    tabName: 'Select Vendor',
                    selectedTab: isSelect,
                    navigation: (): void => 
                    {
                        setIsSelect(true);
                        dispatch(resetVendors());
                        setVendorSelectValue('');
                    }
                },
            ]} />}

            <Form noValidate onSubmit={formSubmit}>

                {isSelect && <Box padding={'24px 0 0'}>
                    <AutocompleteComponent 
                        value={vendorSelectValue}
                        onChange={handleVendorSelectChange}
                        optionsArr={_.compact(vendorsData && vendorsData?.length > 0 ? vendorsData?.map((option) => 
                        {
                            if (!(eventVendorsData?.includes(option?.id))) 
                            {
                                return option?.name;
                            }
                        }) : [])}
                        placeholder='Choose a Vendor'
                    />
                </Box>}

                {(isSelect && vendorSelectValue) ? <Vendors key={key} disabled={true} setRefresh={props?.setRefresh} bottomBtn={false} addEventVendor={true} routeFromEventVendor={props?.routeFromEventVendor} data-eventId={eventId} />
                :
                    (!isSelect) && <Vendors createEventVendor={true} key={key} eventVendor={true} setRefresh={props?.setRefresh} addEventVendor={true} routeFromEventVendor={props?.routeFromEventVendor} data-eventId={eventId} />
                }

                {isSelect && <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                        <CustomButton btnType='secondary' name='Cancel' onClick={handleDrawerClose} />
                        <CustomButton btnType='primary' name='Add' type='submit' onClick={formSubmit} loading={spinner} />
                    </Stack>
                </Box>}
            </Form>
        </Box>
    );
};

export default AddVendors;